import IMetricsService from './i-metrics-service';
import MetricCounter from './metric-counter';
import IMetricsProvider from './i-metrics-provider';
import { injectable, inject } from 'inversify';
import { MetricsSymbols } from './symbols';

@injectable()
export default class MetricsService implements IMetricsService {
    protected _counters: Array<MetricCounter> = [];
    protected _countersIsLoaded: boolean = false;

    protected _metricsProvider: IMetricsProvider;

    constructor(@inject(MetricsSymbols.MetricsProvider) metricsProvider: IMetricsProvider) {
        this._metricsProvider = metricsProvider;
    }

    async RegistrateEvent(eventId: symbol, arg?: any): Promise<void> {
        this._counters.forEach(async x => {
            try {
                ym(x.CounterId, 'reachGoal', Symbol.keyFor(eventId));
                if (arg != null) 
                    ym(x.CounterId, 'params', { 'id': arg });
            } catch (e) {
                console.error(e);
            }
        })
    }


    async LoadCountersAsync(): Promise<void> {
        if (this._countersIsLoaded)
            return;

        let result = await this._metricsProvider.GetSitesCountersAsync();
        if (result.Success) {
            this._countersIsLoaded = true;

            if (result.Data !== undefined) {
                result.Data.forEach(x => {
                    try {
                        ym(x.CounterId, 'init',
                            {
                                id: x.CounterId,
                                clickmap: true,
                                trackLinks: true,
                                accurateTrackBounce: true,
                                ecommerce: x.Ecommerce
                            });
                        ym(x.CounterId, 'hit', location.origin);
                        this._counters.push(x);
                    } catch (e) {
                        console.error(e);
                    }
                });
            }
        }
    }
}

interface yandexCounter {
    (id: string, target?: string, arg?: any): void;
}

declare var ym: yandexCounter;


