import Site from './site';
import { MarketClient } from '../Market';
import { Localization } from '../Localization';
import { WidgetType } from './widget-type';
import { SaleTermsType } from './sale-terms-type';
import { createGuid } from '../Utility';
import { ArenaCalendarType } from './arena-calendar-type';
import { TariffsCalendarType } from './tariffs-calendar-type';

export class AppSettings {
    protected _hashParams: any;
    private _cartmaxitems = 6;

    SibId: string = "";
    GUID: string = "";

    PlaceId?: number;
    SiteId: number = 0;
    SessionId: string = "";
    Skills: Array<number> = [];
    Categories: Array<string> = [];
    NoSkills: boolean = false;
    NoCategories: boolean = false;
    NoLocations: boolean = false;
    NoTariffs: boolean = false;
    NoPromo: boolean = false;
    NoCoach: boolean = false;
    Nocalendar: boolean = false;
    ResetCart: boolean = false;
    ForceMedia: string = '';
    Mindate?: string;
    Maxdate?: string;
    Forcedate?: string;
    Disableddates: Array<string> = [];
    OnlyTab: Array<string> = [];
    ForceTab: string = '';
    OrderComment: string = '';
    GroupId?: number;
    ShowOverSeances: boolean = false;
    ShowNoTicketsSeances: boolean = false;
    TariffsSeancesFilter: boolean = false;
    TariffsId: Array<number> = [];
    BundlesId: Array<number> = [];
    RewriteTariffsId: Array<number> = [];
    LessonsTariffsId: Array<number> = [];
    // ������ �������� ��� ������������ (�������� id:206 = ���� �����). ������������ � WpInstructors
    // ��� ������ ������� - ��������� ��������. ��������� ��������� � ������ http://redmine.isd.su/issues/8410
    GroupLessonsTariffsId: Array<number> = [];

    CoachId?: number;
    LessonsLocation?: number;
    BundlesLessonsTariffsId: Array<number> = [];
    ForceSeanceId?: number;
    ForceSectorId?: number;
    GroupLessonsSkillsTariffs: any = {};
    MultiDaysLessonsSkillsTariffs: any = {};
    PersonalLessonsSkillsTariffs: any = {};
    CurrentSite?: Site;
    LoyaltyId: number = 0;
    CurrentMarketClient?: MarketClient;
    MarketEmployeeId?: number;
    MarketBillId?: number;
    Locale: string = '';
    DisableMultipleSeances: boolean = false;
    IsMobile: boolean = false;
    Minimap: boolean = true;
    RequiredLoyalty: boolean = false;
    VKClientId: string = '';
    ClubSiteId: number = 0;

    //MobileApp settings
    public PushToken: string = '';
    public DeviceId: string = '';
    public Platform: string = '';
    public BuildVersion: number = 0;

    public ServerUrl = '';

    public HandleBaseParams(paramsHash: string, ignoreWindowName: boolean = false): boolean {
        try {
            this._hashParams = JSON.parse(paramsHash);

            this.SiteId = this._hashParams.id;
            this.GUID = this._hashParams.GUID;
            this.SessionId = this._hashParams.SessionId;
            if ('ver' in this._hashParams) this.BuildVersion = this._hashParams.ver;

            if ((this.SessionId == null || this.SessionId.trim() == "" ||
                !window.name.includes(this.GUID)) && !ignoreWindowName)
                this.SessionId = createGuid();

            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }
    public SetJsonParameters(jsonParameters: any) {
        try {
            if ("placeid" in jsonParameters) this.PlaceId = jsonParameters.placeid;
            if ("skills" in jsonParameters) this.Skills = jsonParameters.skills;
            if ("categories" in jsonParameters) this.Categories = jsonParameters.categories;
            if ("noskills" in jsonParameters) this.NoSkills = jsonParameters.noskills;
            if ("nocategories" in jsonParameters) this.NoCategories = jsonParameters.nocategories;
            if ("notariffs" in jsonParameters) this.NoTariffs = jsonParameters.notariffs;
            if ("nolocation" in jsonParameters) this.NoLocations = jsonParameters.nolocation;
            if ("nopromo" in jsonParameters) this.NoPromo = jsonParameters.nopromo;
            if ("nocoach" in jsonParameters) this.NoCoach = jsonParameters.nocoach;
            if ("nocalendar" in jsonParameters) this.Nocalendar = jsonParameters.nocalendar;
            if ("resetcart" in jsonParameters) this.ResetCart = jsonParameters.resetcart;
            if ("mindate" in jsonParameters) this.Mindate = jsonParameters.mindate;
            if ("maxdate" in jsonParameters) this.Maxdate = jsonParameters.maxdate;
            if ("forcedate" in jsonParameters) this.Forcedate = jsonParameters.forcedate;
            if ("disableddates" in jsonParameters) this.Disableddates = jsonParameters.disableddates;
            if ("forcemedia" in jsonParameters) this.ForceMedia = jsonParameters.forcemedia;
            if ("showoverseances" in jsonParameters) this.ShowOverSeances = jsonParameters.showoverseances;
            if ("shownoticketsseances" in jsonParameters) this.ShowNoTicketsSeances = jsonParameters.shownoticketsseances;
            if ("tariffsseancesfilter" in jsonParameters) this.TariffsSeancesFilter = jsonParameters.tariffsseancesfilter;
            if ("groupid" in jsonParameters && jsonParameters.groupid != null) this.GroupId = jsonParameters.groupid;
            if ("rtariffsid" in jsonParameters) this.RewriteTariffsId = jsonParameters.rtariffsid;
            if ("rewritetariffsid" in jsonParameters) this.RewriteTariffsId = jsonParameters.rewritetariffsid;
            if ("lessonstariffsid" in jsonParameters) this.LessonsTariffsId = jsonParameters.lessonstariffsid;

            // ������� ������� ��� ������������
            if ("grouplessonstariffsid" in jsonParameters) this.GroupLessonsTariffsId = jsonParameters.grouplessonstariffsid;

            if ("bundleslessonstariffsid" in jsonParameters) this.BundlesLessonsTariffsId = jsonParameters.bundleslessonstariffsid;
            if ("grouplessonsskillstariffs" in jsonParameters) this.GroupLessonsSkillsTariffs = jsonParameters.grouplessonsskillstariffs;
            if ("multidayslessonsskillstariffs" in jsonParameters) this.MultiDaysLessonsSkillsTariffs = jsonParameters.multidayslessonsskillstariffs;
            if ("personallessonsskillstariffs" in jsonParameters) this.PersonalLessonsSkillsTariffs = jsonParameters.personallessonsskillstariffs;
            if ("coachid" in jsonParameters && jsonParameters.coachid != null && jsonParameters.coachid != "") this.CoachId = jsonParameters.coachid;
            if ("forcetab" in jsonParameters) this.ForceTab = jsonParameters.forcetab;
            if ("ordercomment" in jsonParameters) this.OrderComment = jsonParameters.ordercomment;
            if ("marketemployeeid" in jsonParameters) this.MarketEmployeeId = jsonParameters.marketemployeeid;
            if ("marketbillid" in jsonParameters) this.MarketBillId = jsonParameters.marketbillid;
            if ("locale" in jsonParameters) this.Locale = jsonParameters.locale;
            if ("disablemultipleseances" in jsonParameters) this.DisableMultipleSeances = jsonParameters.disablemultipleseances;
            if ("ismobile" in jsonParameters) this.IsMobile = jsonParameters.ismobile;
            if ("minimap" in jsonParameters) this.Minimap = jsonParameters.minimap;
            if ("requiredloyalty" in jsonParameters) this.RequiredLoyalty = jsonParameters.requiredloyalty;


            // http://redmine.isd.su/issues/12394

            if ('tariffsid' in jsonParameters) {
                if (jsonParameters.tariffsid === false || jsonParameters.tariffsid === 'false')
                    this.TariffsId = [-1];
                else
                    this.TariffsId = jsonParameters.tariffsid;
            };

            if ('bundlesid' in jsonParameters) {
                if (jsonParameters.bundlesid === false || jsonParameters.bundlesid === 'false')
                    this.BundlesId = [-1];
                else
                    this.BundlesId = jsonParameters.bundlesid;
            };

            if ('lessonslocations' in jsonParameters && jsonParameters.lessonslocations != null && jsonParameters.lessonslocations != '')
                this.LessonsLocation = jsonParameters.lessonslocations;

            if ('onlytab' in jsonParameters) {
                let tabsParam = typeof jsonParameters.onlytab == 'string' ? [jsonParameters.onlytab] : jsonParameters.onlytab;
                this.OnlyTab = tabsParam;
            };

            // http://redmine.isd.su/issues/7107
            if ('forceseanceid' in jsonParameters) this.ForceSeanceId = jsonParameters.forceseanceid;
            if ('forcesectorid' in jsonParameters) this.ForceSectorId = jsonParameters.forcesectorid;

            if ('clubsiteid' in jsonParameters) this.ClubSiteId = jsonParameters.clubsiteid;

            //MobileApp settings 
            if ('platform' in jsonParameters) this.Platform = jsonParameters.platform;
            if ('deviceid' in jsonParameters) this.DeviceId = jsonParameters.deviceid;
            if ('pushtoken' in jsonParameters) this.PushToken = jsonParameters.pushtoken;

            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        };
    }
    public ParseHashParams(ignoreWindowName: boolean = false): boolean {
        let paramsHash = decodeURI(location.hash.replace('#', ''));
        return this.HandleBaseParams(paramsHash, ignoreWindowName);
    };
    public HandleJson(administrationParameters: string | null): boolean {
        if (administrationParameters != null && administrationParameters != '') {
            const adminJsonParams = JSON.parse(administrationParameters);
            return this.SetJsonParameters(adminJsonParams);
        }
        else if (this._hashParams)
            return this.SetJsonParameters(this._hashParams);
        else
            return true;
    };

    // #region Getters

    get CartMaxItems(): number {
        if (this._hashParams && 'cartmaxitems' in this._hashParams)
            this._cartmaxitems = Math.abs(parseInt(this._hashParams.cartmaxitems))

        if (this.CartMinItems > this._cartmaxitems) {
            console.error("������������ ���-�� ������� � ������� ���� ������������. ��������� ��������� �������")
            this._cartmaxitems = this.CartMinItems
        }

        return this._cartmaxitems;
    }

    set CartMaxItems(n: number) {
        this._cartmaxitems = n;
    }

    get CartMinItems(): number {
        let cartminitems = 1
        if (this._hashParams && 'cartminitems' in this._hashParams)
            cartminitems = Math.abs(parseInt(this._hashParams.cartminitems)) || 1
        return cartminitems
    }

    get MinimapAspect(): number {
        let minimapaspect = 0.2
        if (this._hashParams && "minimapaspect" in this._hashParams)
            minimapaspect = Math.abs(parseInt(this._hashParams.minimapaspect)) || 0.2
        return minimapaspect
    }

    get SaleTermsType(): SaleTermsType {
        if (this._hashParams != null && 'saletermstype' in this._hashParams) {
            let type = (this._hashParams.saletermstype as string).toLocaleLowerCase();
            try {
                return <SaleTermsType>type;
            } catch {
                return SaleTermsType.Default;
            };
        } else {
            return SaleTermsType.Default;
        };
    };

    get WidgetType(): WidgetType {
        if (this._hashParams != null && 'type' in this._hashParams) {
            let type = (this._hashParams.type as string).toLocaleLowerCase();
            try {
                return <WidgetType>type;
            } catch {
                return WidgetType.Instant;
            };
        } else {
            return WidgetType.Instant;
        };
    };

    get Localization(): Localization {
        if (this._hashParams != null && 'locale' in this._hashParams) {
            let locale = (this._hashParams.locale as string).toLocaleLowerCase();
            try {
                return <Localization>locale;
            } catch {
                return Localization.RU;
            };
        } else {
            return Localization.RU;
        };
    };

    get ArenaCalendar(): ArenaCalendarType {
        if (this._hashParams != null && 'arenacalendar' in this._hashParams) {
            let type = (this._hashParams.arenacalendar as string).toLocaleLowerCase();
            try {
                return <ArenaCalendarType>type;
            } catch {
                return ArenaCalendarType.None;
            };
        } else {
            return ArenaCalendarType.None;
        };
    }

    get TariffsCalendar(): TariffsCalendarType {
        if (this._hashParams != null && 'servicecalendar' in this._hashParams) {
            let type = (this._hashParams.servicecalendar as string).toLocaleLowerCase();
            try {
                return <TariffsCalendarType>type;
            } catch {
                return TariffsCalendarType.Field;
            };
        } else {
            return TariffsCalendarType.Field;
        };
    }

    get IdentityServerUrl(): string {
        if (window.location.host.toLowerCase().indexOf('test') == -1 &&
            window.location.host.toLowerCase().indexOf('localhost') == -1) {
            return 'https://idsrv.moipass.ru/';
        } else {
            return 'https://idsrv-test.moipass.ru/';
        };
    };

    get IsDevVersion() {
        return window.location.host.toLowerCase().indexOf('test') != -1 || window.location.host.toLowerCase().indexOf('localhost') != -1;
    };

    public CheckSettings(): boolean {
        let result = true;
        let error = "";

        if (!Number.isInteger(this.CartMinItems)) {
            error += "Invalid cartminitems; \n";
            result = false;
        }
        if (this.CartMinItems < 0 || this.CartMinItems > 59) {
            error += "cartminitems must be from 0 between 59; \n";
            result = false;
        }
        if (!Number.isInteger(this.CartMaxItems)) {
            error += "Invalid cartmaxitems; \n";
            result = false;
        }
        if (this.CartMaxItems < 0 || this.CartMaxItems > 60) {
            error += "cartmaxitems must be from 1 between 60; \n";
            result = false;
        }
        if (this.CartMinItems > this.CartMaxItems) {
            error += "cartminitems can't be larger cartmaxitems; \n";
            result = false;
        }

        if (error != "") console.error("Error in input parameters: \n" + error)

        if (this._hashParams && 'arenacalendar' in this._hashParams) {
            let type = (this._hashParams.arenacalendar as string);
            if (type != 'none' && type != 'page' && type != 'field') {
                console.error("Unknown value for parameter 'arenacalendar', arenacalendar can be 'none', 'page' or 'field'");
                result = false;
            }
        }

        if (this._hashParams && 'servicecalendar' in this._hashParams) {
            let type = (this._hashParams.servicecalendar as string);
            if (type != 'page' && type != 'field') {
                console.error("Unknown value for parameter 'servicecalendar', servicecalendar can be 'page' or 'field'");
                result = false;
            }
        }

        if (this._hashParams && 'showoverseances' in this._hashParams) {
            let value = (this._hashParams.showoverseances as string);
            if (value != 'true' && value != 'false') {
                console.error("Unknown value for parameter 'showoverseances', showoverseances can be 'true' or 'false'");
                result = false;
            }
        }

        if (this._hashParams && 'shownoticketsseances' in this._hashParams) {
            let value = (this._hashParams.shownoticketsseances as string);
            if (value != 'true' && value != 'false') {
                console.error("Unknown value for parameter 'shownoticketsseances', shownoticketsseances can be 'true' or 'false'");
                result = false;
            }
        }

        return result;
    }

    // #endregion

}