//import WpProfile from './WpProfile/wp-profile';
//import WpChangePassword from './WpProfile/wp-change-password'

//import WpNews from './WpNews/wp-news';
//import WpNewsItem, { wpNewsItemProps } from './WpNews/wp-news-item';
//import WpNewsArticle, { wpNewsArticleProps } from './WpNews/wp-news-article';

//import WpOrders from './WpOrder/wp-orders';
//import WpOrder from './WpOrder/wp-order';

//import WpIsdLoyalty from './WpLoyalty/WpIsdLoyalty/wp-isd-loyalty';
//import WpIsdLoyaltyRegister from './WpLoyalty/WpIsdLoyalty/WpIsdLoyaltyRegister/wp-isd-loyalty-register';
//import WpIsdLoyaltyEdit from './WpLoyalty/WpIsdLoyalty/WpIsdLoyaltyEdit/wp-isd-loyalty-edit';

//import WpPhoneConfirm from './WpPhoneConfirm/wp-phone-confirm';

//import WpMain from './WpMain/wp-main';
//import WpMainNewsItem from './WpMain/WpMainNews/main-news-item';

//import WpFeedback from './WpFeedback/wp-feedback';

/* -= WpLoyalty*/
import { WpIsdLoyaltyRegisterDictionary } from './../../WpLoyalty/WpIsdLoyalty/WpIsdLoyaltyRegister/wp-isd-loyalty-register';
import { WpIsdLoyaltyChildrenDictionary } from './../../WpLoyalty/WpIsdLoyalty/WpIsdLoyaltyChildren/wp-isd-loyalty-children';

/* -= WpSale =- */
import { WpSaleDictionary } from './../../WpSale/wp-sale';

/* -= WpArena =- */
import { WpArenaDictionary } from './../../WpSale/WpArena/wp-arena';
import { WpArenaSeancesDictionary } from './../../WpSale/WpArena/WpArenaSeances/wp-arena-seances';
import { WpArenaDatepickerDictionary } from './../../WpSale/WpArena/WpArenaSeances/WpArenaSeancesDatepicker/wp-arena-seances-datepicker';
import { WpArenaSeanceItemDictionary } from './../../WpSale/WpArena/WpArenaSeances/wp-arena-seance-item';
import { WpArenaSeatsDictionary } from './../../WpSale/WpArena/WpArenaSeats/wp-arena-seats';
import { WpArenaSeatItemDictionary } from './../../WpSale/WpArena/WpArenaSeats/wp-arena-seat-item';
import { WpArenaSectorsDictionary } from './../../WpSale/WpArena/WpArenaSectors/wp-arena-sectors';
import { WpArenaSectorItemDictionary } from './../../WpSale/WpArena/WpArenaSectors/wp-arena-sector-item';
import { WpMapDictionary } from './../../WpSale/WpArena/WpMap/wp-map';
import { WpMinimapDictionary } from './../../WpSale/WpArena/WpMinimap/wp-minimap';

/* -= WpCart =- */
import { WpCartDictionary } from './../../WpSale/WpCart/wp-cart';
import { WpCartItemDictionary } from './../../WpSale/WpCart/WpCartItem/wp-cart-item';
import { WpCartBundleDictionary } from './../../WpSale/WpCart/WpCartBundle/wp-cart-bundle';

/* -= WpComplete =- */
import { WpCompleteDictionary } from './../../WpSale/WpComplete/wp-complete';

/* -= WpInstructors =- */
import { WpInstructorsDictionary } from './../../WpSale/WpInstructors/wp-instructors';
import { WpInstructorsBundlesDictionary } from './../../WpSale/WpInstructors/wp-instructors-bundles';
import { WpInstructorsGroupDictionary } from './../../WpSale/WpInstructors/wp-instructors-group';
import { WpInstructorsDatepickerDictionary } from './../../WpSale/WpInstructors/WpInstructorsDatepicker/wp-instructors-datepicker';
import { WpInstructorsBundlesLessonsDictionary } from './../../WpSale/WpInstructors/WpInstructorsLessons/wp-instructors-bundles-lessons';
import { WpInstructorsGroupLessonsDictionary } from './../../WpSale/WpInstructors/WpInstructorsLessons/wp-instructors-group-lessons';
import { WpInstructorsLessonsDictionary } from './../../WpSale/WpInstructors/WpInstructorsLessons/wp-instructors-lessons';

/* -= WpMarket =- */
import { WpMarketRulesDictionary } from './../../WpSale/WpMarket/wp-market-rules';
import { WpMarketRuleItemDictionary } from './../../WpSale/WpMarket/wp-market-rule-item';

/* -= WpOrderTimer =- */
import { WpOrderTimerDictionary } from './../../WpSale/WpOrderTimer/wp-order-timer';

/* -= WpPayConfirm =- */
import { WpPayConfirmDictionary } from './../../WpSale/WpPayConfirm/wp-payconfirm';
import { WpPayConfirmItemDictionary } from './../../WpSale/WpPayConfirm/WpPayConfirmItem/wp-payconfirm-item';
import { WpPayConfirmBundleDictionary } from './../../WpSale/WpPayConfirm/WpPayConfirmItem/wp-payconfirm-bundle';
import { WpPayConfirmPromoDictionary } from './../../WpSale/WpPayConfirm/WpPayConfirmPromo/wp-payconfirm-promo';
import { WpRulesDictionary } from './../../WpSale/WpPayConfirm/WpRules/wp-rules';

/* -= WpPersonification =- */
import { WpPersonificationDictionary } from './../../WpSale/WpPersonification/wp-personification';
import { WpPersonificationBundleDictionary } from './../../WpSale/WpPersonification/WpPersonificationItem/wp-personification-bundle';
import { WpPersonificationBundleFormDictionary } from './../../WpSale/WpPersonification/WpPersonificationItem/wp-personification-bundle-form';
import { WpPersonificationItemDictionary } from './../../WpSale/WpPersonification/WpPersonificationItem/wp-personification-item';

/* -= WpTariffs =- */
import { WpTariffsDictionary } from './../../WpSale/WpTariffs/wp-tariffs';
import { WpTariffsRewriteDictionary } from './../../WpSale/WpTariffs/wp-tariffs-rewrite';
import { WpTariffBundleDictionary } from './../../WpSale/WpTariffs/WpTariffItem/wp-tariff-bundle';
import { WpTariffItemDictionary } from './../../WpSale/WpTariffs/WpTariffItem/wp-tariff-item';
import { WpTariffRewriteItemDictionary } from './../../WpSale/WpTariffs/WpTariffItem/wp-tariff-rewrite-item';
import { WpTariffsDatepickerDictionary } from './../../WpSale/WpTariffs/WpTariffsDatepicker/wp-tariffs-datepicker';

//import WpLoginPage from './WpLogin/wp-login-page';
import { WpLoginFormDictionary } from './../../WpLogin/WpLoginForm/wp-login-form';
import { WpPasswordResetDictionary } from './../../WpLogin/WpPasswordReset/wp-password-reset';
import { WpRegistrationFormDictionary } from './../../WpLogin/WpRegistrationForm/wp-registration-form';

import { WpPhoneConfirmDictionary } from './../../WpPhoneConfirm/wp-phone-confirm';

//import WpTopBar from './WpTopBar/wp-top-bar';
import { LocalizationStaticDictionary } from 'web-platform-core-ui';
import { WpInstructorsListDictionary } from '../../WpSale/WpInstructors/WpInstructorsList/wp-instructors-list';

import { WpUserAccountWireframeDictionary } from '../../WpUserAccountWireframe/wp-user-account-wireframe';

export class BaseDictionaryEn {
    static InternalError = 'Unexpected error has occurred';
    static AccessDenied = 'Access Denied';

    static GenericYes = 'Yes';
    static GenericNo = 'No';
    static GenericBack = 'Back';
    static GenericNext = 'Next';
    static GenericChange = 'Change';
    static GenericClose = "Close";
    static GenericError = 'Error';
    static GenericPleaseWait = 'Please wait';
    static GenericClear = 'Clear';
    static GenericCancel = 'Cancel';
    static GenericOK = 'OK';
    static GenericLogIn = 'Login';
    static GenericAdd = 'Add';
    static GenericEdit = 'Edit';
    static GenericUpdate = 'Update';
    static GenericApply = 'Apply';
    static GenericSelect = 'Select';

    static GenericPrice = 'Price';
    static GenericBuy = 'Purchase';
    static GenericFrom = 'from';
    static GenericTo = 'to';

    static FormFieldRequired = 'Field is required';
}

/* -= WpLoyalty =- */
const wpIsdLoyaltyRegisterDictionary: WpIsdLoyaltyRegisterDictionary = new WpIsdLoyaltyRegisterDictionary();
wpIsdLoyaltyRegisterDictionary.LastName = 'Surname';
wpIsdLoyaltyRegisterDictionary.FirstName = 'Forename';
wpIsdLoyaltyRegisterDictionary.SecondName = 'Middle name';
wpIsdLoyaltyRegisterDictionary.Birthdate = 'Date of birth';
wpIsdLoyaltyRegisterDictionary.ChooseGender = 'Gender';
wpIsdLoyaltyRegisterDictionary.GenderMale = 'Male';
wpIsdLoyaltyRegisterDictionary.GenderFemale = 'Female';
wpIsdLoyaltyRegisterDictionary.City = 'City';
wpIsdLoyaltyRegisterDictionary.AcceptRequired = 'Acceptance required';
wpIsdLoyaltyRegisterDictionary.GenericSave = 'Save';
wpIsdLoyaltyRegisterDictionary.AcceptPartOne = 'I agree to the';
wpIsdLoyaltyRegisterDictionary.AcceptPartTwo = 'Terms';

wpIsdLoyaltyRegisterDictionary.GenericFieldRequired = 'Field cannot be empty';
wpIsdLoyaltyRegisterDictionary.GenericFormatIncorrect = 'Incorrect format';
wpIsdLoyaltyRegisterDictionary.GenericLettersOnly = 'Only letters are allowed';
wpIsdLoyaltyRegisterDictionary.GenericPickOneOption = 'Pick one';

const wpIsdLoyaltyChildrenDictionary: WpIsdLoyaltyChildrenDictionary = new WpIsdLoyaltyChildrenDictionary();
wpIsdLoyaltyChildrenDictionary.GenericChildren = 'Children';
wpIsdLoyaltyChildrenDictionary.GenericChildData = 'Child information';
wpIsdLoyaltyChildrenDictionary.GenericConfirm = 'Are you sure?';
wpIsdLoyaltyChildrenDictionary.GenderMale = '♂ Male';
wpIsdLoyaltyChildrenDictionary.GenderFemale = '♀ Female';
wpIsdLoyaltyChildrenDictionary.LastName = 'Surname';
wpIsdLoyaltyChildrenDictionary.FirstName = 'Forename';
wpIsdLoyaltyChildrenDictionary.SecondName = 'Middle name';

/* -= WpSale =- */
const wpSaleDictionary: WpSaleDictionary = new WpSaleDictionary();
wpSaleDictionary.TabService = 'Purchase services';
wpSaleDictionary.TabRewrite = 'Extend services';
wpSaleDictionary.TabPersonalLessons = 'Individual lessons';
wpSaleDictionary.TabGroupLessons = 'Group lessons';
wpSaleDictionary.TabBundlesLessons = 'Multidate lessons';

wpSaleDictionary.GenericPlaceOrder = 'Place an order';
wpSaleDictionary.GenericPay = 'Checkout';
wpSaleDictionary.GenericProcessPayment = 'Checkout';
wpSaleDictionary.GenericShorthandMinutes = 'min';

wpSaleDictionary.CartItemNotAvailableError = 'One of the items in the cart is no longer available';
wpSaleDictionary.CartItemCountError = 'Out of tickets for one of the items in the cart';

wpSaleDictionary.CardHaveTariffWithSoType = 'Ticket already with rental order';
wpSaleDictionary.CardHaveNotUsedTariff = 'There is an unspent service on the card';
wpSaleDictionary.LessonNotAvailable = 'Lesson not available';

wpSaleDictionary.InvalidEmail = 'Invalid email';

wpSaleDictionary.DataRequired = 'This data is required';

wpSaleDictionary.SbpTitle = 'Scan the QR-code with your phone and follow the link to pay for the order';
wpSaleDictionary.SbpMobileTitle = 'If the bank application does not open, follow the ';
wpSaleDictionary.SbpMobileTitleLink = 'link';

wpSaleDictionary.FreeTicketLimitError = 'You have already purchased a free ticket';


/* -= WpArena =- */
const wpArenaSeancesDictionary: WpArenaSeancesDictionary = new WpArenaSeancesDictionary();
wpArenaSeancesDictionary.NoAvailableSeances = 'There aren\'t any activities to purchase';

const wpArenaDatepickerDictionary: WpArenaDatepickerDictionary = new WpArenaDatepickerDictionary();
wpArenaDatepickerDictionary.TextBoxLabel = 'Choose a date';
wpArenaDatepickerDictionary.DialogBtnClean = 'Clear';
wpArenaDatepickerDictionary.DialogBtnCancel = 'Cancel';
wpArenaDatepickerDictionary.DialogBtnOk = 'OK';
wpArenaDatepickerDictionary.TitleText = 'Events on date';

const wpArenaSeanceItemDictionary: WpArenaSeanceItemDictionary = new WpArenaSeanceItemDictionary();
wpArenaSeanceItemDictionary.TicketPrice = 'Ticket prices';
wpArenaSeanceItemDictionary.PricesLoading = 'Loading prices…';
wpArenaSeanceItemDictionary.CurrencyIcon = '$';

const wpArenaSeatsDictionary: WpArenaSeatsDictionary = new WpArenaSeatsDictionary();
wpArenaSeatsDictionary.GenericOccupied = 'Unavailable';
wpArenaSeatsDictionary.GenericPicked = 'Selected';
wpArenaSeatsDictionary.ChooseSeatsOption = 'Chose a seat option';
wpArenaSeatsDictionary.GenericRow = 'Row';
wpArenaSeatsDictionary.GenericSeat = 'Seat';

const wpArenaSeatItemDictionary: WpArenaSeatItemDictionary = new WpArenaSeatItemDictionary();
wpArenaSeatItemDictionary.ErrorCartIsFull = 'Cart has reached max capacity';
wpArenaSeatItemDictionary.ErrorSeatIsOccupied = 'This seat is already taken';

const wpArenaSectorsDictionary: WpArenaSectorsDictionary = new WpArenaSectorsDictionary();

const wpArenaSectorItemDictionary: WpArenaSectorItemDictionary = new WpArenaSectorItemDictionary();
wpArenaSectorItemDictionary.TicketPrice = 'Ticket prices';
wpArenaSectorItemDictionary.PricesLoading = 'Loading prices…';
wpArenaSectorItemDictionary.SeatsLoading = 'Loading seats…';
wpArenaSectorItemDictionary.SeatsSingular = 'seat';
wpArenaSectorItemDictionary.SeatsPlural = 'seats';
wpArenaSectorItemDictionary.GenericRemaining = 'Remaining';
wpArenaSectorItemDictionary.CurrencyIcon = '$';

const wpArenaDictionary: WpArenaDictionary = new WpArenaDictionary();
wpArenaDictionary.ArenaText = 'Arena';

const wpMapDictionary = new WpMapDictionary();
wpMapDictionary.row = "Row";
wpMapDictionary.seat = "Seat";
wpMapDictionary.GenericOccupied = "Occupied";
wpMapDictionary.GenericPicked = "Picked";

const wpMinimapDictionary = new WpMinimapDictionary();
wpMinimapDictionary.Minimap = "Minimap";

/* -= WpCart =- */
const wpCartDictionary: WpCartDictionary = new WpCartDictionary();
wpCartDictionary.GenericCart = 'Cart';
wpCartDictionary.GenericClearCart = 'Clear cart';
wpCartDictionary.GenericTotal = 'Total';
wpCartDictionary.GenericCheckout = 'Checkout';

const wpCartItemDictionary: WpCartItemDictionary = new WpCartItemDictionary();
const wpCartBundleDictionary: WpCartBundleDictionary = new WpCartBundleDictionary();

/* -= WpComplete =- */
const wpCompleteDictionary: WpCompleteDictionary = new WpCompleteDictionary();
wpCompleteDictionary.AwaitingResult = 'Awaiting transaction results';
wpCompleteDictionary.BackToServiceList = 'Back to list of services';
wpCompleteDictionary.ThankYouForPurchase = 'Thank you for your purchase!';
wpCompleteDictionary.ConfirmRefusalToWait = 'Are you sure you want not to wait for the result of the transaction?';
wpCompleteDictionary.FatalError = 'Unfortunately there is a technical issue at this moment. Please contact technical support.';
wpCompleteDictionary.StartOver = 'Start over';
wpCompleteDictionary.YourOrderNo = 'Your order №';
wpCompleteDictionary.YourOrderStatusOK = 'has been processed! Information about your order has been sent to your e-mail.';
wpCompleteDictionary.YourOrderStatusCanceled = 'canceled.';
wpCompleteDictionary.CardDiscountConditionError = 'In case of cancellation due to entering card info that does not meet the requirements for a discount, funds will be returned within three working days';
wpCompleteDictionary.OrderInfoLinkTitle = 'Get information about order and download tickets you can';
wpCompleteDictionary.OrderInfoLinkText = 'here';

wpCompleteDictionary.CardPayment = 'Payment by card';
wpCompleteDictionary.SbpPayment = 'Payment via SFP';
wpCompleteDictionary.GenericPay = 'Pay';
wpCompleteDictionary.PayOrderTitle = 'Pay order №';

wpCompleteDictionary.Payu = 'Payu';
wpCompleteDictionary.Yandex = 'Yandex';
wpCompleteDictionary.Sberbank = 'Sberbank';
wpCompleteDictionary.RfiBank = 'RFI bank';
wpCompleteDictionary.RosEuroBank = 'RosEuroBank';
wpCompleteDictionary.Gazprombank = 'Gazprombank';
wpCompleteDictionary.SovkomBank = 'Sovkombank';
wpCompleteDictionary.Kazkombank = "Kazcomercbank";
wpCompleteDictionary.Test = "Test";
wpCompleteDictionary.AlfaBank = 'Alfa-bank';
wpCompleteDictionary.PayOnline = 'Pay online';

/* -= WpInstructors =- */
const wpInstructorsDictionary: WpInstructorsDictionary = new WpInstructorsDictionary();
wpInstructorsDictionary.Discipline = 'Discipline';
wpInstructorsDictionary.DisciplinesLoading = 'Loading disciplines…';
wpInstructorsDictionary.Category = "Category";
wpInstructorsDictionary.CategoriesLoading = 'Loading categories…';
wpInstructorsDictionary.Program = 'Program';
wpInstructorsDictionary.ProgramsLoading = 'Loading programs…';
wpInstructorsDictionary.DialogBtnShow = 'Show';
wpInstructorsDictionary.Location = 'Place';
wpInstructorsDictionary.LocationsLoading = 'Loading places…';
wpInstructorsDictionary.Instructor = 'Instructor';
wpInstructorsDictionary.InstructorsLoading = 'Loading instructors…';

const wpInstructorsListDictionary: WpInstructorsListDictionary = new WpInstructorsListDictionary();
wpInstructorsListDictionary.Instructor = 'Instructor';
wpInstructorsListDictionary.InstructorsLoading = 'Loading instructors…';

const wpInstructorsBundlesDictionary: WpInstructorsBundlesDictionary = new WpInstructorsBundlesDictionary();
wpInstructorsBundlesDictionary.Discipline = 'Discipline';
wpInstructorsBundlesDictionary.DisciplinesLoading = 'Loading disciplines…';
wpInstructorsBundlesDictionary.Category = 'Category';
wpInstructorsBundlesDictionary.CategoriesLoading = 'Loading categories…';
wpInstructorsBundlesDictionary.Program = 'Training program';
wpInstructorsBundlesDictionary.ProgramsLoading = 'Loading training programs…';
wpInstructorsBundlesDictionary.DialogBtnShow = 'Show';
wpInstructorsBundlesDictionary.NoLessonsAvailable = 'No lessons available';
wpInstructorsBundlesDictionary.Location = 'Place';
wpInstructorsBundlesDictionary.LocationsLoading = 'Loading places…';

const wpInstructorsGroupDictionary: WpInstructorsGroupDictionary = new WpInstructorsGroupDictionary();
wpInstructorsGroupDictionary.Discipline = 'Discipline';
wpInstructorsGroupDictionary.DisciplinesLoading = 'Loading disciplines…';
wpInstructorsGroupDictionary.Category = 'Category';
wpInstructorsGroupDictionary.CategoriesLoading = 'Loading categories…';
wpInstructorsGroupDictionary.Program = 'Training program';
wpInstructorsGroupDictionary.ProgramsLoading = 'Loading training programs…';
wpInstructorsGroupDictionary.DialogBtnShow = 'Show';
wpInstructorsGroupDictionary.Location = 'Place';
wpInstructorsGroupDictionary.LocationsLoading = 'Loading places…';

const wpInstructorsDatepickerDictionary: WpInstructorsDatepickerDictionary = new WpInstructorsDatepickerDictionary();
wpInstructorsDatepickerDictionary.PeriodOfStudy = 'Period of training';

const wpInstructorsBundlesLessonsDictionary: WpInstructorsBundlesLessonsDictionary = new WpInstructorsBundlesLessonsDictionary();
wpInstructorsBundlesLessonsDictionary.Vacant = 'Available positions';
wpInstructorsBundlesLessonsDictionary.GuestsAmmount = 'Ammount of guests';

const wpInstructorsGroupLessonsDictionary: WpInstructorsGroupLessonsDictionary = new WpInstructorsGroupLessonsDictionary();
wpInstructorsGroupLessonsDictionary.Vacant = 'Available positions';
wpInstructorsGroupLessonsDictionary.GuestsAmmount = 'Ammount of guests';
wpInstructorsGroupLessonsDictionary.NoAvailableLessons = 'No lessons available';

const wpInstructorsLessonsDictionary: WpInstructorsLessonsDictionary = new WpInstructorsLessonsDictionary();
wpInstructorsLessonsDictionary.GuestsAmmount = 'Ammount of guests';
wpInstructorsLessonsDictionary.ShorthandPeople = 'ppl.';
wpInstructorsLessonsDictionary.NoAvailableLessons = 'No lessons available';

/* -= WpMarket =- */
const wpMarketRulesDictionary: WpMarketRulesDictionary = new WpMarketRulesDictionary();
wpMarketRulesDictionary.NoServicesAvailableOnThisDate = 'There aren\'t any services available on this date';
wpMarketRulesDictionary.DataRequired = 'This data is required';

const wpMarketRuleItemDictionary: WpMarketRuleItemDictionary = new WpMarketRuleItemDictionary();
wpMarketRuleItemDictionary.CartIsFull = 'Cart has reached it\'s full capacity';
wpMarketRuleItemDictionary.ToCart = 'To cart';

/* -= WpOrderTimer =- */
const wpOrderTimerDictionary: WpOrderTimerDictionary = new WpOrderTimerDictionary();

/* -= WpPayConfirm =- */
const wpPayConfirmDictionary: WpPayConfirmDictionary = new WpPayConfirmDictionary();
wpPayConfirmDictionary.UsedPromoCode = 'Promocode has been used';
wpPayConfirmDictionary.DataRequired = 'This data is required';
wpPayConfirmDictionary.PlacingOrder = 'Order placement';
wpPayConfirmDictionary.ToGetDeals = 'In order to accumulate bonus points and receive discounts you need to';
wpPayConfirmDictionary.YouAreMemberOfLoyaltyProgram = 'You are the member of a loyalty program';
wpPayConfirmDictionary.LabelEmail = 'Email';
wpPayConfirmDictionary.LabelPhone = 'Phone';
wpPayConfirmDictionary.LabelPromocode = 'Promocode';
wpPayConfirmDictionary.EnterEmail = 'Enter your e-mail';
wpPayConfirmDictionary.EnterPhone = 'Enter your phone number';
wpPayConfirmDictionary.ChooseDiscount = 'Choose a discount';
wpPayConfirmDictionary.CodeIncorrect = 'Incorrect code';
wpPayConfirmDictionary.GenericDiscount = 'Discount';
wpPayConfirmDictionary.GenericTotal = 'Total';
wpPayConfirmDictionary.GenericPay = 'Pay';
wpPayConfirmDictionary.GenericByPressingPay = 'By pressing "Pay", you agree with';
wpPayConfirmDictionary.GenericToRules = 'the terms';
wpPayConfirmDictionary.HowManyPointsToSpend = 'How many points would you like to spend?';
wpPayConfirmDictionary.NoDiscount = 'Without discount';
wpPayConfirmDictionary.ChoosePayment = 'Choose a Payment Method';
wpPayConfirmDictionary.CardPayment = 'Payment by card';
wpPayConfirmDictionary.SbpPayment = 'Payment via SFP';
wpPayConfirmDictionary.GenericPurchaseComment = 'Comment to the order';
wpPayConfirmDictionary.NoPointsToSpend = "No bonus points left to spend";

wpPayConfirmDictionary.Payu = 'Payu';
wpPayConfirmDictionary.Yandex = 'Yandex';
wpPayConfirmDictionary.Sberbank = 'Sberbank';
wpPayConfirmDictionary.RfiBank = 'RFI bank';
wpPayConfirmDictionary.RosEuroBank = 'RosEuroBank';
wpPayConfirmDictionary.Gazprombank = 'Gazprombank';
wpPayConfirmDictionary.SovkomBank = 'Sovkombank';
wpPayConfirmDictionary.Kazkombank = "Kazcomercbank";
wpPayConfirmDictionary.Test = "Test";
wpPayConfirmDictionary.AlfaBank = 'Alfa-bank';
wpPayConfirmDictionary.PayOnline = 'Pay online';

const wpPayConfirmItemDictionary: WpPayConfirmItemDictionary = new WpPayConfirmItemDictionary();
wpPayConfirmItemDictionary.GenericDecline = 'Decline';
wpPayConfirmItemDictionary.GenericTotalSum = 'Total sum';

const wpPayConfirmBundleDictionary: WpPayConfirmBundleDictionary = new WpPayConfirmBundleDictionary();
wpPayConfirmBundleDictionary.GenericDecline = 'Decline';
wpPayConfirmBundleDictionary.GenericTotalSum = 'Total sum';
wpPayConfirmBundleDictionary.Personalization = 'Personalization';

const wpPayConfirmPromoDictionary: WpPayConfirmPromoDictionary = new WpPayConfirmPromoDictionary();
wpPayConfirmPromoDictionary.CallToAction = 'Use the promocode!';
wpPayConfirmPromoDictionary.GenericSuccess = 'You have used the «ABC123» promocode!';

const wpRulesDictionary: WpRulesDictionary = new WpRulesDictionary();
wpRulesDictionary.GenericTerms = 'Terms';
wpRulesDictionary.GenericTermsISDS = 'ISDS Terms';
wpRulesDictionary.GenericIAgree = 'Accept and ';
wpRulesDictionary.GenericPay = 'purchase';

/* -= WpPersonification =- */
const wpPersonificationDictionary: WpPersonificationDictionary = new WpPersonificationDictionary();
wpPersonificationDictionary.PersonSaleFirstName = 'client';
wpPersonificationDictionary.PersonSaleLastName = 'New';
wpPersonificationDictionary.GenericTotalSum = 'Total sum';
wpPersonificationDictionary.GenericTotalSumDiscounted = 'Total sum (with discount)';

const wpPersonificationBundleDictionary: WpPersonificationBundleDictionary = new WpPersonificationBundleDictionary();
wpPersonificationBundleDictionary.GenericDecline = 'Decline';
wpPersonificationBundleDictionary.GenericTotalSum = 'Total sum';

const wpPersonificationBundleFormDictionary: WpPersonificationBundleFormDictionary = new WpPersonificationBundleFormDictionary();
wpPersonificationBundleFormDictionary.LabelFirstName = 'First name';
wpPersonificationBundleFormDictionary.LabelSecondName = 'Middle name';
wpPersonificationBundleFormDictionary.LabelLastName = 'Surname';
wpPersonificationBundleFormDictionary.FieldRequired = 'This field is required';
wpPersonificationBundleFormDictionary.UnacceptableCharacters = 'Unacceptable characters';
wpPersonificationBundleFormDictionary.PersonifyOn = 'Personify on:';

const wpPersonificationItemDictionary: WpPersonificationItemDictionary = new WpPersonificationItemDictionary();
wpPersonificationItemDictionary.LabelFirstName = 'First name';
wpPersonificationItemDictionary.LabelSecondName = 'Middle name';
wpPersonificationItemDictionary.LabelLastName = 'Surname';
wpPersonificationItemDictionary.FieldRequired = 'This field is required';
wpPersonificationItemDictionary.UnacceptableCharacters = 'Unacceptable characters';
wpPersonificationItemDictionary.PersonifyOn = 'Personify on:';
wpPersonificationItemDictionary.GenericDecline = 'Decline';
wpPersonificationItemDictionary.GenericTotalSum = 'Total sum';

/* -= WpTariffs =- */
const wpTariffsDictionary: WpTariffsDictionary = new WpTariffsDictionary();
wpTariffsDictionary.NoAvailableLessonsForThisDate = 'No available lessons on this date';

const wpTariffsRewriteDictionary: WpTariffsRewriteDictionary = new WpTariffsRewriteDictionary();
wpTariffsRewriteDictionary.CardRegisteredOn = 'Card registered on';
wpTariffsRewriteDictionary.NoAvailableServicesForThisCard = 'No services available for this card';
wpTariffsRewriteDictionary.UnacceptableCharacters = 'Card number must have letters A to F and digits';
wpTariffsRewriteDictionary.GenericCheck = 'Check';
wpTariffsRewriteDictionary.LabelCardNo = 'Card number';
wpTariffsRewriteDictionary.InstructionEnterCardNumber = 'To refill your services please enter the card number:';
wpTariffsRewriteDictionary.InstructionCharacters = 'Card number consists of 16 letter-digit combination and is situated on the card itself.';
wpTariffsRewriteDictionary.CardNotFound = 'Card not found';
wpTariffsRewriteDictionary.CardExpired = 'Card has expired';
wpTariffsRewriteDictionary.CardInProcess = 'This card is already use in another order';
wpTariffsRewriteDictionary.CardIsAlredyInCart = 'You can add only one service to one card at a time';
wpTariffsRewriteDictionary.CardMinimum8 = "Card number must be at least 8 symbols long";

const wpTariffBundleDictionary: WpTariffBundleDictionary = new WpTariffBundleDictionary();
wpTariffBundleDictionary.GenericItemsLeft = 'Remaining';
wpTariffBundleDictionary.GenericItemsOf = 'out of';
wpTariffBundleDictionary.GenericToCart = 'Add to cart';
wpTariffBundleDictionary.CartIsFull = 'Cart has reached maximum capacity';
wpTariffBundleDictionary.NoMoreTickets = 'Out of tickets';
wpTariffBundleDictionary.CustomService = '1 day subscribtion for using the lift "Olympia" (single use) and "Wolves Mountain" (multiple uses) with a bicycle';
wpTariffBundleDictionary.CarNumberDialogTitle = 'Enter number(s) of the car';
wpTariffBundleDictionary.CarTicketTitle = 'Ticket';
wpTariffBundleDictionary.CarNumberTextLabel = 'Enter number';
wpTariffBundleDictionary.CarNumberRequiredError = 'This field is required';
wpTariffBundleDictionary.CarNumberNotValid = 'Incorrect format';

const wpTariffItemDictionary: WpTariffItemDictionary = new WpTariffItemDictionary();
wpTariffItemDictionary.GenericItemsLeft = 'Remaining';
wpTariffItemDictionary.GenericItemsOf = 'out of';
wpTariffItemDictionary.GenericToCart = 'Add to cart';
wpTariffItemDictionary.CartIsFull = 'Cart has reached maximum capacity';
wpTariffItemDictionary.NoMoreTickets = 'Out of tickets';
wpTariffItemDictionary.TariffUnavailableForThisDate = 'Tariff is not available on this date';
wpTariffItemDictionary.CarNumberDialogTitle = 'Enter number(s) of the car';
wpTariffItemDictionary.CarTicketTitle = 'Ticket';
wpTariffItemDictionary.CarNumberTextLabel = 'Enter number';
wpTariffItemDictionary.CarNumberRequiredError = 'This field is required';
wpTariffItemDictionary.CarNumberNotValid = 'Incorrect format';

const wpTariffRewriteItemDictionary: WpTariffRewriteItemDictionary = new WpTariffRewriteItemDictionary();
wpTariffRewriteItemDictionary.GenericToCart = 'To cart';
wpTariffRewriteItemDictionary.TariffUnavailableForThisDate = 'Tariff is not available on this date';

const wpTariffsDatepickerDictionary: WpTariffsDatepickerDictionary = new WpTariffsDatepickerDictionary();
wpTariffsDatepickerDictionary.TextBoxLabel = 'Choose a date';
wpTariffsDatepickerDictionary.DialogBtnClean = 'Clear';
wpTariffsDatepickerDictionary.DialogBtnCancel = 'Cancel';
wpTariffsDatepickerDictionary.DialogBtnOk = 'OK';
wpArenaDatepickerDictionary.TitleText = 'Services on date';

/* -= WpLogin =- */
const wpLoginFormDictionary: WpLoginFormDictionary = new WpLoginFormDictionary();
wpLoginFormDictionary.FieldRequiredMessage = 'This field is required';
wpLoginFormDictionary.UserNameLblText = 'Email or Phone';
wpLoginFormDictionary.PasswordLblText = 'Password';
wpLoginFormDictionary.RememberMeLblText = 'Remember me';
wpLoginFormDictionary.SignBtnText = 'Login';
wpLoginFormDictionary.ForgotPasswordLink = 'Forgot your password';
wpLoginFormDictionary.RegistrationLink = 'Registration';
wpLoginFormDictionary.InvalidUserNameOrPassword = 'Wrong login or password';

const wpRegistrationFormDictionary: WpRegistrationFormDictionary = new WpRegistrationFormDictionary();
wpRegistrationFormDictionary.SuccessRegistrationMessage = 'Registration successful';
wpRegistrationFormDictionary.EmailRequiredMessage = 'Please enter your e-mail';
wpRegistrationFormDictionary.FieldRequiredMessage = 'This field is required';
wpRegistrationFormDictionary.PasswordLengthError = 'Must be longer than 6 characters';
wpRegistrationFormDictionary.PasswordNotAvailableCharacters = 'Available characters for the password: numbers, Cyrillic, Latin letters, (space) ! " № # $ % & \'() * + , - . / : ; + = ?@[\] ^ _` { | } ~ <>';
wpRegistrationFormDictionary.ComparePasswordError = 'Passwords do not match';
wpRegistrationFormDictionary.RegistrationTitle = 'Registration';
wpRegistrationFormDictionary.EmailLblText = 'Enter email';
wpRegistrationFormDictionary.PasswordLblText = 'Enter password';
wpRegistrationFormDictionary.RepeatPasswordLblText = 'Repeat password';
wpRegistrationFormDictionary.AgreementError = 'You must agree';
wpRegistrationFormDictionary.BeforeAgreementLinkText = 'I agree with';
wpRegistrationFormDictionary.AgreementLink = 'conditions';
wpRegistrationFormDictionary.AfterAgreementLinkText = 'processing personal data';
wpRegistrationFormDictionary.RegistrationBtnText = 'register';
wpRegistrationFormDictionary.EmailExistError = 'This email is already registered';

const wpPasswordResetDictionary: WpPasswordResetDictionary = new WpPasswordResetDictionary();
wpPasswordResetDictionary.SuccessPasswordChangedMessage = 'Password changed';
wpPasswordResetDictionary.NewPasswordSendedMessage = 'New password has been sent by email'
wpPasswordResetDictionary.EmailRequiredMessage = 'Enter email';
wpPasswordResetDictionary.FieldRequiredMessage = 'This field is required';
wpPasswordResetDictionary.PasswordLengthError = 'Must be longer than 6 characters';
wpPasswordResetDictionary.PasswordNotAvailableCharacters = 'Available characters for the password: numbers, Cyrillic, Latin letters, (space) ! " № # $ % & \'() * + , - . / : ; + = ?@[\] ^ _` { | } ~ <>';
wpPasswordResetDictionary.ComparePasswordError = 'Passwords do not match';
wpPasswordResetDictionary.ResetPasswordTitle = 'Password recovery';
wpPasswordResetDictionary.EmailLblText = 'Enter email';
wpPasswordResetDictionary.NewPasswordLblText = 'Enter new password';
wpPasswordResetDictionary.RepeatPasswordLblText = 'Repeat Password';
wpPasswordResetDictionary.ResetBtnText = 'Restore';
wpPasswordResetDictionary.ChangeBtnText = 'change';

const wpPhoneConfirmDictionary: WpPhoneConfirmDictionary = new WpPhoneConfirmDictionary();
wpPhoneConfirmDictionary.TryCountError = 'Too many attempts. Try after';
wpPhoneConfirmDictionary.CodeRequiredError = 'Please enter code';
wpPhoneConfirmDictionary.PhoneRequiredError = 'Please fill in the number until the end';
wpPhoneConfirmDictionary.PhoneLblText = 'Enter phone number';
wpPhoneConfirmDictionary.SendSMSBtnText = 'Send';
wpPhoneConfirmDictionary.CodeSendedMessage = 'Code sent to number';
wpPhoneConfirmDictionary.AnotherPhoneLink = 'another number';
wpPhoneConfirmDictionary.CodeLblText = 'Enter code';
wpPhoneConfirmDictionary.SendAgainLink = 'Resend';
wpPhoneConfirmDictionary.TimeoutResendMessage = 'You will be able to re-request the code via';
wpPhoneConfirmDictionary.TimeoutSecondsMessage = 'sec.';
wpPhoneConfirmDictionary.ConfirmCodeBtnText = 'confirm';
wpPhoneConfirmDictionary.SomeOneElsePhoneError = 'This phone is registered to another user';

wpPhoneConfirmDictionary.NewTelTitle = 'Phone number verification';
wpPhoneConfirmDictionary.NewTelDescription = 'Call to confirm';
wpPhoneConfirmDictionary.NewTelResendMessage = 'You will be able to re-request the verification via';

const wpUserAccountWireframeDictionary: WpUserAccountWireframeDictionary = new WpUserAccountWireframeDictionary(); 
wpUserAccountWireframeDictionary.NewsMenu = 'News';
wpUserAccountWireframeDictionary.ProfileMenu = 'Profile';
wpUserAccountWireframeDictionary.OrdersMenu = 'My orders';
wpUserAccountWireframeDictionary.CardsMenu = 'My cards';
wpUserAccountWireframeDictionary.FeedbackMenu = 'Feedback';
wpUserAccountWireframeDictionary.ChangePasswordMenu = 'Change password';
wpUserAccountWireframeDictionary.LogOutMenu = 'Log out';
wpUserAccountWireframeDictionary.LogOutDialogTitle = 'Sign out';
wpUserAccountWireframeDictionary.LogOutDialogMessage = 'Do you really want to log out?'

const wpEnLocalizationComponentsDictionary: Array<any> = [
    /* -= WpLoyalty =- */
    new LocalizationStaticDictionary<WpIsdLoyaltyRegisterDictionary>(Symbol.for('wp-isd-loyalty-register'), wpIsdLoyaltyRegisterDictionary),
    new LocalizationStaticDictionary<WpIsdLoyaltyChildrenDictionary>(Symbol.for('wp-isd-loyalty-children'), wpIsdLoyaltyChildrenDictionary),

    /* -= WpSale =- */
    new LocalizationStaticDictionary<WpSaleDictionary>(Symbol.for('wp-sale'), wpSaleDictionary),

    /* -= WpArena =- */
    new LocalizationStaticDictionary<WpArenaDictionary>(Symbol.for('wp-arena'), wpArenaDictionary),
    new LocalizationStaticDictionary<WpArenaSeancesDictionary>(Symbol.for('wp-arena-seances'), wpArenaSeancesDictionary),
    new LocalizationStaticDictionary<WpArenaDatepickerDictionary>(Symbol.for('wp-arena-seances-datepicker'), wpArenaDatepickerDictionary),
    new LocalizationStaticDictionary<WpArenaSeanceItemDictionary>(Symbol.for('wp-arena-seance-item'), wpArenaSeanceItemDictionary),
    new LocalizationStaticDictionary<WpArenaSeatsDictionary>(Symbol.for('wp-arena-seats'), wpArenaSeatsDictionary),
    new LocalizationStaticDictionary<WpArenaSeatItemDictionary>(Symbol.for('wp-arena-seat-item'), wpArenaSeatItemDictionary),
    new LocalizationStaticDictionary<WpArenaSectorsDictionary>(Symbol.for('wp-arena-sectors'), wpArenaSectorsDictionary),
    new LocalizationStaticDictionary<WpArenaSectorItemDictionary>(Symbol.for('wp-arena-sector-item'), wpArenaSectorItemDictionary),
    new LocalizationStaticDictionary<WpMapDictionary>(Symbol.for('wp-map'), wpMapDictionary),
    new LocalizationStaticDictionary<WpMinimapDictionary>(Symbol.for('wp-minimap'), wpMinimapDictionary),

    /* -= WpCart =- */
    new LocalizationStaticDictionary<WpCartDictionary>(Symbol.for('wp-cart'), wpCartDictionary),
    new LocalizationStaticDictionary<WpCartItemDictionary>(Symbol.for('wp-cart-item'), wpCartItemDictionary),
    new LocalizationStaticDictionary<WpCartBundleDictionary>(Symbol.for('wp-cart-bundle'), wpCartBundleDictionary),

    /* -= WpComplete =- */
    new LocalizationStaticDictionary<WpCompleteDictionary>(Symbol.for('wp-complete'), wpCompleteDictionary),

    /* -= WpInstructors =- */
    new LocalizationStaticDictionary<WpInstructorsDictionary>(Symbol.for('wp-instructors'), wpInstructorsDictionary),
    new LocalizationStaticDictionary<WpInstructorsBundlesDictionary>(Symbol.for('wp-instructors-bundles'), wpInstructorsBundlesDictionary),
    new LocalizationStaticDictionary<WpInstructorsGroupDictionary>(Symbol.for('wp-instructors-group'), wpInstructorsGroupDictionary),
    new LocalizationStaticDictionary<WpInstructorsDatepickerDictionary>(Symbol.for('wp-instructors-datepicker'), wpInstructorsDatepickerDictionary),
    new LocalizationStaticDictionary<WpInstructorsBundlesLessonsDictionary>(Symbol.for('wp-instructors-bundles-lessons'), wpInstructorsBundlesLessonsDictionary),
    new LocalizationStaticDictionary<WpInstructorsGroupLessonsDictionary>(Symbol.for('wp-instructors-group-lessons'), wpInstructorsGroupLessonsDictionary),
    new LocalizationStaticDictionary<WpInstructorsLessonsDictionary>(Symbol.for('wp-instructors-lessons'), wpInstructorsLessonsDictionary),
    new LocalizationStaticDictionary<WpInstructorsListDictionary>(Symbol.for('wp-instructors-list'), wpInstructorsListDictionary),

    /* -= WpMarket =- */
    new LocalizationStaticDictionary<WpMarketRulesDictionary>(Symbol.for('wp-market-rules'), wpMarketRulesDictionary),
    new LocalizationStaticDictionary<WpMarketRuleItemDictionary>(Symbol.for('wp-market-rule-item'), wpMarketRuleItemDictionary),

    /* -= WpOrderTimer =- */
    new LocalizationStaticDictionary<WpOrderTimerDictionary>(Symbol.for('wp-order-timer'), wpOrderTimerDictionary),

    /* -= WpPayConfirm =- */
    new LocalizationStaticDictionary<WpPayConfirmDictionary>(Symbol.for('wp-payconfirm'), wpPayConfirmDictionary),
    new LocalizationStaticDictionary<WpPayConfirmItemDictionary>(Symbol.for('wp-payconfirm-item'), wpPayConfirmItemDictionary),
    new LocalizationStaticDictionary<WpPayConfirmBundleDictionary>(Symbol.for('wp-payconfirm-bundle'), wpPayConfirmBundleDictionary),
    new LocalizationStaticDictionary<WpPayConfirmPromoDictionary>(Symbol.for('wp-payconfirm-promo'), wpPayConfirmPromoDictionary),
    new LocalizationStaticDictionary<WpRulesDictionary>(Symbol.for('wp-rules'), wpRulesDictionary),

    /* -= WpPersonification =- */
    new LocalizationStaticDictionary<WpPersonificationDictionary>(Symbol.for('wp-personification'), wpPersonificationDictionary),
    new LocalizationStaticDictionary<WpPersonificationBundleDictionary>(Symbol.for('wp-personification-bundle'), wpPersonificationBundleDictionary),
    new LocalizationStaticDictionary<WpPersonificationBundleFormDictionary>(Symbol.for('wp-personification-bundle-form'), wpPersonificationBundleFormDictionary),
    new LocalizationStaticDictionary<WpPersonificationItemDictionary>(Symbol.for('wp-personification-item'), wpPersonificationItemDictionary),

    /* -= WpTariffs =- */
    new LocalizationStaticDictionary<WpTariffsDictionary>(Symbol.for('wp-tariffs'), wpTariffsDictionary),
    new LocalizationStaticDictionary<WpTariffsRewriteDictionary>(Symbol.for('wp-tariffs-rewrite'), wpTariffsRewriteDictionary),
    new LocalizationStaticDictionary<WpTariffBundleDictionary>(Symbol.for('wp-tariff-bundle'), wpTariffBundleDictionary),
    new LocalizationStaticDictionary<WpTariffItemDictionary>(Symbol.for('wp-tariff-item'), wpTariffItemDictionary),
    new LocalizationStaticDictionary<WpTariffRewriteItemDictionary>(Symbol.for('wp-tariff-rewrite-item'), wpTariffRewriteItemDictionary),
    new LocalizationStaticDictionary<WpTariffsDatepickerDictionary>(Symbol.for('wp-tariffs-datepicker'), wpTariffsDatepickerDictionary),

    /* -= WpLogin =- */
    new LocalizationStaticDictionary<WpLoginFormDictionary>(Symbol.for('wp-login-form'), wpLoginFormDictionary),
    new LocalizationStaticDictionary<WpRegistrationFormDictionary>(Symbol.for('wp-registration-form'), wpRegistrationFormDictionary),
    new LocalizationStaticDictionary<WpPasswordResetDictionary>(Symbol.for('wp-password-reset'), wpPasswordResetDictionary),

    new LocalizationStaticDictionary<WpPhoneConfirmDictionary>(Symbol.for('wp-phone-confirm'), wpPhoneConfirmDictionary),

    new LocalizationStaticDictionary<WpUserAccountWireframeDictionary>(Symbol.for('wp-user-account-wireframe'), wpUserAccountWireframeDictionary),
];

export default wpEnLocalizationComponentsDictionary;