import TariffSeance from './tariff-seance';
import { TariffType } from './tariff-type';

export default class Tariff {
    Id: number = 0;
    TariffCode: number = 0;
    Name: string = '';
    OptionId: string = '';
    OptionName: string = '';
    GroupId?: number;
    GroupName: string = '';
    Category: string = '';
    WorkingDays: string = '';
    Description: string = '';
    Quota?: number;
    RentalOptions: Array<RentalOption> = [];
    CalendarEnabled: boolean = true;
    StartSale: string = '';
    EndSale: string = '';
    ZIndex: number = 0;
    RequiredPersonification: boolean = false;
    IsRewritable: boolean = true;
    TariffType: TariffType = TariffType.Default;
    IsParking: boolean = false;

    Seances: Array<TariffSeance> = [];
    ShowLeftTicketCount: boolean = false;
    HaveSeance: boolean = false;
    HideSeance: number = 0;
    TitleColor: string = '';
    SeanceColor: string = '';
    DescriptionColor: string = '';
    WorkingDaysColor: string = '';
}

export interface RentalOption {
    BindedTariffId: number;
    Id: string;
    Name: string;
}