import Vue from 'vue';

import GlobalService, { UserAccountWireframeType,
     ICmsService,
     CmsSymbols,
     WpCorePrefixes,
     BaseFilter,
     AppSettings,
     UserAccountParameters,
     IContext,
     IdentitySymbols
} from 'web-platform-core-ui';

import Component, { mixins } from 'vue-class-component';
import { VueConstructor } from 'vue/types/umd';
import BaseUILocalizationDictionary from '../Localization/base-ui-localization-terms';
import WpBaseComponent from '../wp-base-component';
import WpNews from '../WpNews/wp-news';
import WpNewsArticle from '../WpNews/wp-news-article';
import WpIsdLoyalty from '../WpLoyalty/WpIsdLoyalty/wp-isd-loyalty';
import WpOrders from '../WpOrder/wp-orders';
import WpOrder from '../WpOrder/wp-order';
import WpChangePassword from '../WpProfile/wp-change-password';
import WpClientsCards  from '../WpClientsCards/wp-clients-cards';
import { WpFormFeedback } from '..';


@Component({
    template: require("./wp-user-account-wireframe.html")
})
export default class WpUserAccountWireframe extends mixins<WpBaseComponent<WpUserAccountWireframeDictionary>, VueConstructor>(WpBaseComponent, Vue) {
    protected _cmsService!: ICmsService;     
    protected _context!: IContext;
    protected _settings!: AppSettings;
    protected _userAccountParameters?: UserAccountParameters;

    DialogLogOut: boolean = false;
    Drawer: boolean = false;

    WireframeType: UserAccountWireframeType = UserAccountWireframeType.Base;
    LogoUrl: string = ''; 
    HeaderColor: string = 'primary';
    HaveFooter: boolean = false;
    FooterColor: string = 'white'; 
    FooterContent: string = '';
    MenuItems: Array<any> = [];


    async mounted(){
        this._cmsService = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);
        this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
        this._settings = GlobalService.GetSettings();

        var filter = new BaseFilter();
        filter.PageCount = 1;
        filter.PlatformId = this._settings.SiteId;
        var parameters = await this._cmsService.GetEntitiesAsync<UserAccountParameters>(WpCorePrefixes.UserAccountParameters, filter, '');

        if(!parameters.Success || parameters.Data == null){
            console.error("Не найдены настройки личного кабинета");
            return;
        }

        if(parameters.Data.Entities.length != 0){
            this._userAccountParameters = parameters.Data.Entities[0];

            this.WireframeType = this._userAccountParameters.WireframeType;
            this.LogoUrl = this._userAccountParameters.LogoUrl ?? '';
            this.HeaderColor = this._userAccountParameters.HeaderColor ? `#${this._userAccountParameters.HeaderColor}` : 'primary';
            this.HaveFooter = this._userAccountParameters.HaveFooter;
            this.FooterColor = this._userAccountParameters.FooterColor ? `#${this._userAccountParameters.FooterColor}` : 'white';
            this.FooterContent = this._userAccountParameters.FooterContent ?? '';

            await this.HandlingMenuItems();
        } 
    }

    protected async HandlingMenuItems(){
        if(this._userAccountParameters == null) return;

        let routes = [];
        let menu = [];

        if(this._userAccountParameters.NewsEnabled){
            routes.push({
                path: '/news',
                name: 'wp-news',
                component: WpNews,
                meta: {
                    requiresAuth: true,
                    title: this.Terms.NewsMenu
                }
            });

            routes.push({
                path: '/article/:ArticleId',
                name: 'wp-news-article',
                component: WpNewsArticle,
                props: true,
                meta: {
                    requiresAuth: true,
                    title: this.Terms.NewsMenu,
                    routeback: "wp-news"
                }
            })

            menu.push({ to: 'wp-news', title: this.Terms.NewsMenu, icon: 'mdi-newspaper' });
        }

        if(this._userAccountParameters.ProfileEnabled){
            routes.push({
                path: '/profile',
                name: 'wp-profile',
                component: WpIsdLoyalty,
                props: {
                    IsApp: false
                },
                meta: {
                    requiresAuth: true,
                    title: this.Terms.ProfileMenu
                }
            });

            menu.push({ to: 'wp-profile', title: this.Terms.ProfileMenu, icon: 'mdi-account' });
        }

        if(this._userAccountParameters.OrdersEnabled){
            routes.push({
                path: '/orders',
                name: 'wp-orders',
                component: WpOrders,
                meta: {
                    requiresAuth: true,
                    title: this.Terms.OrdersMenu
                }
            });

            routes.push({
                path: '/order/:OrderId',
                name: 'wp-order',
                component: WpOrder,
                props: true,
                meta: {
                    requiresAuth: true,
                    title: this.Terms.OrdersMenu,
                    routeback: "wp-orders"
                }
            })

            menu.push({ to: 'wp-orders', title: this.Terms.OrdersMenu, icon: 'mdi-package-variant' });
        }

        if(this._userAccountParameters.CardsEnabled){
            routes.push({
                path: '/cards',
                name: 'wp-card',
                component: WpClientsCards,
                props: {
                    IsApp: false
                },
                meta: {
                    requiresAuth: true,
                    title: this.Terms.CardsMenu
                }
            });

            menu.push({ to: 'wp-card', title: this.Terms.CardsMenu, icon: 'mdi-credit-card' });
        }

        routes.push({
            path: '/changepassword/',
            name: 'wp-change-password',
            component: WpChangePassword,
            props: true,
            meta: {
                requiresAuth: true,
                title: this.Terms.ChangePasswordMenu
            }
        });

        routes.push({
            path: '/feedback/',
            name: 'wp-form-feedback',
            component: WpFormFeedback,
            props: true,
            meta: {
                requiresAuth: true,
                title: this.Terms.ChangePasswordMenu
            }
        });

        if(routes.length > 0){
            routes.push({
                path: '/',
                name: 'home',
                component: routes[0].component,
                props: routes[0].props,
                meta: routes[0].meta
            });
        }

        this.MenuItems = menu;
        this.$router.addRoutes(routes);
    }
    
    protected SignOut(){
        this._context.SignOut();
    }
}

export class WpUserAccountWireframeDictionary extends BaseUILocalizationDictionary{
    NewsMenu: string = 'Новости';
    ProfileMenu: string = 'Профиль';
    OrdersMenu: string = 'Мои заказы';
    CardsMenu: string = 'Мои карты';

    FeedbackMenu: string = "Обратная связь";
    ChangePasswordMenu: string = 'Сменить пароль';
    LogOutMenu: string = 'Выйти';
    LogOutDialogTitle: string = 'Выход';
    LogOutDialogMessage: string = 'Вы действительно хотите выйти?'
}