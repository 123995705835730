import 'reflect-metadata';
import WpBaseComponent from './wp-base-component';

// Common components
import WpBarChart from './WpCommon/WpBarChart/wp-bar-chart';
import WpCopylink from './WpCommon/WpCopylink/wp-copylink.vue';
import WpLoadingOverlay from './WpCommon/WpLoadingOverlay/wp-loading-overlay';

import WpProfile from './WpProfile/wp-profile';
import WpChangePassword from './WpProfile/wp-change-password';

import WpNavigation from './WpNavigation/wp-navigation';

import WpNews from './WpNews/wp-news';
import WpNewsItem, { wpNewsItemProps } from './WpNews/wp-news-item';
import WpNewsArticle, { wpNewsArticleProps } from './WpNews/wp-news-article';

import WpOrders from './WpOrder/wp-orders';
import WpOrder from './WpOrder/wp-order';

import WpIsdLoyalty from './WpLoyalty/WpIsdLoyalty/wp-isd-loyalty';
import WpIsdLoyaltyRegister from './WpLoyalty/WpIsdLoyalty/WpIsdLoyaltyRegister/wp-isd-loyalty-register';
import WpIsdLoyaltyEdit from './WpLoyalty/WpIsdLoyalty/WpIsdLoyaltyEdit/wp-isd-loyalty-edit';
import WpIsdLoyaltyChildren from './WpLoyalty/WpIsdLoyalty/WpIsdLoyaltyChildren/wp-isd-loyalty-children';
import WpClientsCards from './WpClientsCards/wp-clients-cards';

import WpPhoneConfirm from './WpPhoneConfirm/wp-phone-confirm';

import WpMain from './WpMain/wp-main';
import WpMainNewsItem from './WpMain/WpMainNews/main-news-item';

import WpFeedback from './WpFeedback/wp-feedback';
import WpFormFeedback from './WpFeedback/wp-form-feedback';

import WpSkidataParking from './WpSale/WpSkidataParking/wp-skidata-parking';

import WpInstructors from './WpSale/WpInstructors/wp-instructors';
import WpInstructorsGroup from './WpSale/WpInstructors/wp-instructors-group';
import WpInstructorsLessons from './WpSale/WpInstructors/WpInstructorsLessons/wp-instructors-lessons';
import WpInstructorsGroupLessons from './WpSale/WpInstructors/WpInstructorsLessons/wp-instructors-group-lessons';
import WpInstructorsDatepicker from './WpSale/WpInstructors/WpInstructorsDatepicker/wp-instructors-datepicker.vue';
import WpInstructorsBundles from './WpSale/WpInstructors/wp-instructors-bundles';
import WpInstructorsBundlesLessons from './WpSale/WpInstructors/WpInstructorsLessons/wp-instructors-bundles-lessons';

import WpTariffs from './WpSale/WpTariffs/wp-tariffs';
import WpTariffsRewrite from './WpSale/WpTariffs/wp-tariffs-rewrite';
import WpTariffItem from './WpSale/WpTariffs/WpTariffItem/wp-tariff-item';
import WpTariffBundle from './WpSale/WpTariffs/WpTariffItem/wp-tariff-bundle';
import WpTariffRewriteItem from './WpSale/WpTariffs/WpTariffItem/wp-tariff-rewrite-item';
import WpTariffsDatepicker from './WpSale/WpTariffs/WpTariffsDatepicker/wp-tariffs-datepicker';

import WpArenaMap from './WpSale/WpArena/WpMap/wp-map';
import WpArenaMinimap from './WpSale/WpArena/WpMinimap/wp-minimap';
import WpArena from './WpSale/WpArena/wp-arena';
import WpArenaSeances from './WpSale/WpArena/WpArenaSeances/wp-arena-seances';
import WpArenaSeanceItem from './WpSale/WpArena/WpArenaSeances/wp-arena-seance-item';
import WpArenaSeats from './WpSale/WpArena/WpArenaSeats/wp-arena-seats';
import WpArenaSeatItem from './WpSale/WpArena/WpArenaSeats/wp-arena-seat-item';
import WpArenaSectorItem from './WpSale/WpArena/WpArenaSectors/wp-arena-sector-item';
import WpArenaSectors from './WpSale/WpArena/WpArenaSectors/wp-arena-sectors';
import WpArenaSeancesDatePicker from './WpSale/WpArena/WpArenaSeances/WpArenaSeancesDatepicker/wp-arena-seances-datepicker';

import WpCart from './WpSale/WpCart/wp-cart';
import WpCartItem from './WpSale/WpCart/WpCartItem/wp-cart-item';
import WpCartBundle from './WpSale/WpCart/WpCartBundle/wp-cart-bundle';

import WpPayConfirm from './WpSale/WpPayConfirm/wp-payconfirm';
import WpPayConfirmItem from './WpSale/WpPayConfirm/WpPayConfirmItem/wp-payconfirm-item';
import WpPayConfirmBundle from './WpSale/WpPayConfirm/WpPayConfirmItem/wp-payconfirm-bundle';
import WpPayConfirmPromo from './WpSale/WpPayConfirm/WpPayConfirmPromo/wp-payconfirm-promo';

import WpPersonification from './WpSale/WpPersonification/wp-personification';
import WpPersonificationItem from './WpSale/WpPersonification/WpPersonificationItem/wp-personification-item';
import WpPersonificationBundle from './WpSale/WpPersonification/WpPersonificationItem/wp-personification-bundle';
import WpPersonificationBundleForm from './WpSale/WpPersonification/WpPersonificationItem/wp-personification-bundle-form';

import WpMarketRules from './WpSale/WpMarket/wp-market-rules';
import WpMarketRuleItem from './WpSale/WpMarket/wp-market-rule-item';

import WpLoginDialog from './WpLogin/wp-login-dialog';
import WpLoginPage from './WpLogin/wp-login-page';
import WpLoginForm from './WpLogin/WpLoginForm/wp-login-form';
import WpPasswordReset from './WpLogin/WpPasswordReset/wp-password-reset';
import WpRegistrationForm from './WpLogin/WpRegistrationForm/wp-registration-form';

import WpSettings from './WpSettings/wp-settings';
import WpTopBar from './WpTopBar/wp-top-bar';

import WpOrderTimer from './WpSale/WpOrderTimer/wp-order-timer';

import WpRules from './WpSale/WpPayConfirm/WpRules/wp-rules';

import WpComplete from './WpSale/WpComplete/wp-complete';

import WpSale from './WpSale/wp-sale';

import WpUserAccountWireframe from './WpUserAccountWireframe/wp-user-account-wireframe';

import WpNotificationQueue from './WpNotification/wp-notification-queue';

import WpError from './WpError/wp-error';

import WpPageBaseComponent from './wp-page-base-component';

export default WpBaseComponent;

export { WpBarChart, WpCopylink, WpLoadingOverlay };
export { WpProfile };
export { WpChangePassword };
export { WpNews, wpNewsItemProps, WpNewsArticle, wpNewsArticleProps, WpNewsItem };
export { WpOrders, WpOrder };
export { WpIsdLoyalty, WpIsdLoyaltyRegister, WpClientsCards };
export { WpPhoneConfirm };
export { WpMain };
export { WpMainNewsItem };
export { WpNavigation };
export { WpFeedback };
export { WpFormFeedback };
export { WpInstructors, WpInstructorsGroup, WpInstructorsLessons, WpInstructorsGroupLessons, WpInstructorsDatepicker };
export { WpTariffs, WpTariffsRewrite, WpTariffItem, WpTariffBundle, WpTariffRewriteItem, WpTariffsDatepicker };
export {
	WpArenaMap,
	WpArenaMinimap,
	WpArena,
	WpArenaSeances,
	WpArenaSeanceItem,
	WpArenaSectors,
	WpArenaSectorItem,
	WpArenaSeats,
	WpArenaSeatItem,
	WpArenaSeancesDatePicker,
};
export { WpCart, WpCartItem, WpCartBundle };
export { WpPayConfirm, WpPayConfirmPromo };
export { WpPersonification, WpPersonificationItem, WpPersonificationBundle, WpPersonificationBundleForm };
export { WpSettings };
export { WpTopBar };
export { WpOrderTimer };
export { WpRules };
export { WpComplete };
export { WpSale };
export { WpNotificationQueue };
export { WpPageBaseComponent };
export { WpError };
export { WpLoginDialog, WpLoginPage, WpLoginForm, WpRegistrationForm, WpPasswordReset };
export { WpMarketRules, WpMarketRuleItem };
export { WpSkidataParking };
export { WpUserAccountWireframe };

export * as luzhniki from './Luzhniki';

const wpComponents = {
	'wp-bar-chart': WpBarChart,
	'wp-copylink': WpCopylink,
	'wp-loading-overlay': WpLoadingOverlay,
	'wp-profile': WpProfile,
	'wp-change-password': WpChangePassword,

	'wp-navigation': WpNavigation,

	'wp-news': WpNews,
	'wp-news-item': WpNewsItem,
	'wp-news-article': WpNewsArticle,

	'wp-orders': WpOrders,
	'wp-order': WpOrder,

	'wp-isd-loyalty': WpIsdLoyalty,
	'wp-isd-loyalty-register': WpIsdLoyaltyRegister,
	'wp-isd-loyalty-edit': WpIsdLoyaltyEdit,
	'wp-isd-loyalty-children': WpIsdLoyaltyChildren,
	'wp-cli-cards': WpClientsCards,

	'wp-phone-confirm': WpPhoneConfirm,

	'wp-main': WpMain,
	'wp-main-news-item': WpMainNewsItem,

	'wp-feedback': WpFeedback,
	'wp-form-feedback': WpFormFeedback,

	'wp-skidata-parking': WpSkidataParking,

	'wp-instructors': WpInstructors,
	'wp-instructors-group': WpInstructorsGroup,
	'wp-instructors-lessons': WpInstructorsLessons,
	'wp-instructors-group-lessons': WpInstructorsGroupLessons,
	'wp-instructors-datepicker': WpInstructorsDatepicker,
	'wp-instructors-bundles': WpInstructorsBundles,
	'wp-instructors-bundles-lessons': WpInstructorsBundlesLessons,

	'wp-cart': WpCart,
	'wp-cart-item': WpCartItem,
	'wp-cart-bundle': WpCartBundle,

	'wp-payconfirm': WpPayConfirm,
	'wp-payconfirm-item': WpPayConfirmItem,
	'wp-payconfirm-bundle': WpPayConfirmBundle,
	'wp-payconfirm-promo': WpPayConfirmPromo,

	'wp-personification': WpPersonification,
	'wp-personification-item': WpPersonificationItem,
	'wp-personification-bundle': WpPersonificationBundle,
	'wp-personification-bundle-form': WpPersonificationBundleForm,

	'wp-tariffs': WpTariffs,
	'wp-tariffs-rewrite': WpTariffsRewrite,
	'wp-tariff-item': WpTariffItem,
	'wp-tariff-bundle': WpTariffBundle,
	'wp-tariff-rewrite-item': WpTariffRewriteItem,
	'wp-tariffs-datepicker': WpTariffsDatepicker,

	'wp-map': WpArenaMap,
	'wp-minimap': WpArenaMinimap,
	'wp-arena-map': WpArenaMap,
	'wp-arena-minimap': WpArenaMinimap,
	'wp-arena': WpArena,
	'wp-arena-seances': WpArenaSeances,
	'wp-arena-seances-datepicker': WpArenaSeancesDatePicker,
	'wp-arena-seance-item': WpArenaSeanceItem,
	'wp-arena-sectors': WpArenaSectors,
	'wp-arena-sector-item': WpArenaSectorItem,
	'wp-arena-seats': WpArenaSeats,
	'wp-arena-seat-item': WpArenaSeatItem,

	'wp-market-rules': WpMarketRules,
	'wp-market-rule-item': WpMarketRuleItem,

	'wp-settings': WpSettings,
	'wp-top-bar': WpTopBar,

	'wp-order-timer': WpOrderTimer,

	'wp-rules': WpRules,

	'wp-complete': WpComplete,
	'wp-sale': WpSale,

	'wp-user-account-wireframe': WpUserAccountWireframe,

	'wp-notification': WpNotificationQueue,

	'wp-error': WpError,

	'wp-login-dialog': WpLoginDialog,
	'wp-login-page': WpLoginPage,
	'wp-login-form': WpLoginForm,
	'wp-password-reset': WpPasswordReset,
	'wp-registration-form': WpRegistrationForm,
};

const extend = function <First, Second>(first: First, second: Second): First & Second {
	const result: Partial<First & Second> = {};
	for (const prop in first) {
		if (Object.prototype.hasOwnProperty.call(first, prop)) {
			(result as First)[prop] = first[prop];
		}
	}
	for (const prop in second) {
		if (Object.prototype.hasOwnProperty.call(second, prop)) {
			(result as Second)[prop] = second[prop];
		}
	}
	return result as First & Second;
};

export { extend, wpComponents };
