import { injectable, inject } from 'inversify';
import IDateHandler from './i-date-handler';
const DateAndTime = require('date-and-time');
const ru = require('date-and-time/locale/ru');
// Switch to Spanish

@injectable()
export default class DateHandlerService implements IDateHandler {
    private months_ru: Array<string> = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
    private months_en: Array<string> = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    private days_ru: Array<string> = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
    private days_en: Array<string> = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    private days_ru_short: Array<string> = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    private days_en_short: Array<string> = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    _date!: Date;

    GetMonth(which: number = 0, lang: string = "ru", declension?: boolean): string {
        let monthdeclension = "я";
        let month = lang == "ru" ? this.months_ru[which] : this.months_en[which];;

        if (lang == "ru" && declension) {
            if (which == 2 || which == 7) {
                monthdeclension = "а";
                month = this.months_ru[which] + monthdeclension; // Just add "а"
            } else {
                month = this.months_ru[which].slice(0, -1) + monthdeclension; // Replace "ь" with "я"
            };
        };

        return month;
    };

    GetDay(which: number = 1, lang: string = "ru"): string {
        return lang == "ru" ? this.days_ru[which] : this.days_en[which];
    };

    GetShortDays(lang: string = "ru"): Array<string> {
        return lang == "ru" ? this.days_ru_short : this.days_en_short;
    };

    GetMonthNames(lang: string = "ru"): Array<string> {
        return lang == "ru" ? this.months_ru : this.months_en;
    };

    Parse(date: string | Date, format?: string) {
        if (date instanceof Date) {
            this._date = date;
        } else if (format !== undefined) {
            this._date = DateAndTime.parse(date, format);
        };
        return this;
    };

    ParseToDate(date: string | Date, format?: string) {
        if (date instanceof Date) {
            this._date = date;
        } else if (format !== undefined) {
            this._date = DateAndTime.parse(date, format);
        };
        return this._date;
    };

    Locale(locale: string): void {
        DateAndTime.locale(ru);
    };

    public Format(format?: string): string {
        return DateAndTime.format(this._date, format);
    };

    public Add(type: string, amount: number): this {
        switch (type) {
            case "years":
                this._date = DateAndTime.addYears(this._date, amount);
                break;
            case "months":
                this._date = DateAndTime.addMonths(this._date, amount);
                break;
            case "days":
                this._date = DateAndTime.addDays(this._date, amount);
                break;
            case "hours":
                this._date = DateAndTime.addHours(this._date, amount);
                break;
            case "minutes":
                this._date = DateAndTime.addMinutes(this._date, amount);
                break;
            case "seconds":
                this._date = DateAndTime.addSeconds(this._date, amount);
                break;
            default:
                this._date = DateAndTime.addMilliseconds(this._date, amount);
                break;
        }
        return this;
    }

    public Subtract(type: string, amount: number): this {
        return this.Add(type, amount * -1);
    };

    /**
     * Convert and sort an array of dates from string to date format.
     * Mostly used to convert v-date-picker dates since it returns an Array<string>
     * Example: ['2022-04-22', '2022-04-15', '2022-04-17'] => [ new Date('2022-04-15'), new Date('2022-04-17'), new Date('2022-04-22') ]
     * @param {array} dates - Array of strings in date format like '2022-04-22'
     */
    public DatesConvertAndSort(dates: Array<string>): Array<Date> {
        return dates.map(d => new Date(d).getTime()).sort().map(d => new Date(d));
    };

    /**
     * Get an array of dates
     * Accepts an array of two or more dates, sorts them and returns an array of all dates in between earliest and latest
     * @param {array} dates - Array of dates
     */
    public DatesBetween(dates: Array<Date>, removeHours?: false, includeLast?: boolean): Array<Date> {
        if (Object.prototype.toString.call(dates) !== '[object Array]') {
            console.error('Method only accepts an ARRAY');
            return [];
        };

        dates.forEach(function (d) {
            if (Object.prototype.toString.call(d) !== '[object Date]') {
                console.error('Method only accepts an array of DATES');
                return [];
            };
        });

        if (dates.length <= 1) {
            console.error('Method only accepts an array of TWO OR MORE values');
            return [];
        };

        // Sort the array of dates in an ascending order
        let sortedDates: Array<Date> = dates.map(d => d.getTime()).sort().map(d => new Date(d));

        // Store last date in a variable
        const lastDate = sortedDates[sortedDates.length - 1];

        // Generate an array of dates between first and last date
        let DatesBetween: Array<Date> = [];
        // Friendly reminder: relational operators like < or >= are FINE to compare two DATE objects.
        for (var d = new Date(sortedDates[0]);
            d < lastDate;
            d.setDate(d.getDate() + 1)) {
            // Push new date to array
            DatesBetween.push(
                new Date(
                    removeHours ? d.setHours(0, 0, 0, 0) : d
                )
            );
        };
        // Include last date if such option is specified
        if (includeLast) DatesBetween.push(lastDate);

        return DatesBetween;
    };

    /**
     * Get a date string in a special format ("DD Month, ShortDay" by default)
     * @param {Date} date - Date object
     * @param {String} format - Desired format
     * @param {Boolean} declension - Choose whether to decline month name like from "Январь" to "Января"
     * Supported formats:
     * "DD Month, ShortDay" (default) - 17 Август, Ср
     * "ShortDay, DD Month" - Пт, 22 октября
     * "DD Month YYYY" - 17 Август 2022
     */
    public FormatSpecial(date: Date, format?: string, declension: boolean = false, lowercase: boolean = false): string {
        let ddate = date.getDate();
        let day = date.getDay();
        let dayShort = this.days_ru_short[day];
        let monthNumber = date.getMonth();
        let month = this.GetMonth(monthNumber, undefined, declension);
        if (lowercase) month = month.toLowerCase();
        let year = date.getFullYear();
        if (format === "ShortDay, DD Month") return `${dayShort}, ${ddate} ${month}`;
        if (format === "DD Month YYYY") return `${ddate} ${month} ${year}`;
        if (format === "DD Month") return `${ddate} ${month}`;
        return `${ddate} ${month}, ${dayShort}`;
    };

    /**
     * Check if arguement is a date and valid
     * @param {any} date - Variable to check
     */
    public IsValid(date: any): boolean {
        if (Object.prototype.toString.call(date) === "[object Date]") {
            return isNaN(date) ? false : true;
        } else {
            console.error("Not a date object.")
            return false;
        }
    };

    /**
     * Convert time string like "03:00:00" to milliseconds
     * @param {string} time - Time string like "03:00:00"
     */
    public ConvertToMilliseconds(time: string): number {
        if (time != '') {
            let a = time.split(':');
            let hours = parseInt(a[0]);
            let minutes = parseInt(a[1]);
            let seconds = parseInt(a[2]);
            let fulltime = seconds + (minutes * 60) + (Math.abs(hours) * 60 * 60) * 1000;
            return hours >= 0 ? fulltime : -fulltime;
        };
        return 0;
    };
    /**
     * Returns the day of currently stored date
     */
    public Weekday(): number {
        return this._date.getDay();
    };
    /**
     * Returns the time of currently stored date in milliseconds
     * @param {boolean} timeIrelevant - Specify if only date time is needed and not the entire date. If true will return date's time from 00:00:00 ignoring H:M:s of the date
     */
    public Time(timeIrrelevant?: boolean): number {
        // If time is irrelevant
        return timeIrrelevant ? new Date(this._date).setHours(0, 0, 0, 0) : this._date.getTime();
    };
    /**
     * Returns an array of dates that spans 7 days starting today + offset
     * @param {number} offset - Specify how many days to skip before calculating a week.
     */
    GetWeek(offset: number = 0): Array<Date> {
        let today = new Date();
        today = new Date(today.setDate(today.getDate() + offset));
        let seven = new Date(new Date().setDate(today.getDate() + 6));
        return this.DatesBetween([today, seven]);
    };
    /**
     * Calculates the time difference in minutes between two time strings like "15:00" and "17:30"
     * @param {string} timeA - Time string to start counting FROM
     * @param {string} timeB - Time string to start counting TO
     */
    MinutesBetween(timeA: string, timeB: string): number {
        let timeStart = new Date();
        let timeEnd = new Date();

        let StartHours = timeA.slice(0, 5).split(":");
        let EndHours = timeB.slice(0, 5).split(":");

        let StartMs = timeStart.setHours(parseInt(StartHours[0]), parseInt(StartHours[1]));
        let EndMs = timeEnd.setHours(parseInt(EndHours[0]), parseInt(EndHours[1]));

        let result = Math.floor((EndMs - StartMs) / 1000 / 60);
        return result;
    };
    /**
     * Get monday date of this week
     * Example: If today is 2023-03-04 (Saturday) this will return 2023-02-27 (Monday)
     */
    GetStartOfTheWeek(): Date {
        const d = new Date();
        const date = d.getDate()
        const day = d.getDay();
        return new Date(new Date().setDate(date - (day || 7) + 1));
    };
};