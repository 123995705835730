import ClubCard from './club-card';
import { ContractActualPeriod, FixedPriceType, ContractPaymentType, ContractPeriod, ContractProcedure, ContractServicePeriod, PPSContractStatus, OwnerType } from '.';
import SoldClubCard from './sold-club-card';
import ClubContract from './club-contract';
import { PersonIsdLoyaltyData } from '../Loyalty';

export default class SoldClubContract {
    /** xs:boolean */
    ACCOUNT_YES?: boolean;
    /** xs:long */
    ACC_DISCOUNT?: number;
    /** xs:string */
    ACC_DISCOUNT_NAME?: string;
    /** xs:dateTime */
    ACTUAL_DATA?: string;
    /** ContractActualPeriod|xs:string|capAny,capDay,capWeek,capMonth,capQuarter,capSixMonth,capYear,capAllPeriod */
    ACTUAL_PERIOD?: ContractActualPeriod;
    /** xs:long */
    ACTUAL_QUANTITY?: number;
    /** xs:long */
    CARD?: number;
    /** CARD_LIST */
    CARD_LIST?: Array<ClubCard>;
    /** xs:string */
    CARD_LIST_STR?: string;
    /** xs:string */
    CARD_NAME?: string;
    /** xs:double */
    CHECK_ACCOUNT?: number;
    /** xs:long */
    CURRENCY?: number;
    /** xs:long */
    CURRENCY_TYPE?: number;
    /** xs:long */
    CURRENT_NUMBER?: number;
    /*
     * Indicates whether the contract is for children or not.
     * This is new... beware. It DOES seem to come from backend
     */
    Child!: boolean;    
    /** xs:double */
    ENSUR_AMOUNT?: number;
    /** xs:double */
    ENSUR_SUMMA?: number;
    /** xs:double */
    ENTRY_FEE?: number;
    /** xs:double */
    EXTEND_AMOUNT?: number;
    /** xs:double */
    EXTEND_DAYS_AMOUNT?: number;
    /** xs:long */
    EXTEND_DAYS_COUNT?: number;
    /** xs:long */
    EXTEND_DISCOUNT?: number;
    /** xs:string */
    EXTEND_DISCOUNT_NAME?: string;
    /** xs:double */
    EXTEND_PERCENT?: number;
    /** xs:boolean */
    EXTEND_YES?: boolean;
    /** xs:long */
    FAMILY_YES?: number;
    /** FixedPriceType|xs:string|ftpNo,ftpCard,ftpContract */
    FIXED_PRICE_YES?: FixedPriceType;
    /** xs:boolean */
    FIXED_YES?: boolean;
    /** xs:long */
    FREEZE?: number;
    FREEZE_DAYS_AMOUNT?: number;    // 90
    FREEZE_DAYS_EXTRA?: number;     // 0
    FREEZE_DAYS_REST?: number;      // 90
    FREEZE_END_DATE?: string;       // xs:string "2023-01-23T00:00:00"
    FREEZE_NAME?: string;           // "��������� 90 ���� (������� 7)"
    FREEZE_YES?: boolean;           // xs:boolean
    /** xs:long */
    ID?: number;
    /** ��������� �� ��������? */
    IS_FREEZED?: boolean;
    /** xs:long */
    KIND?: number;
    /** xs:string */
    KIND_DESC?: string;
    /** xs:string */
    NAME?: string;
    /** xs:string */
    NUMBER?: string;
    /** ContractPaymentType|xs:string|cptNow,cptCredit */
    PAYMENT_TYPE?: ContractPaymentType;
    /** ContractPeriod|xs:string|cpAny,cpDay,cpWeek,cpMonth,cpQuarter,cpSixMonth,cpYear,cpFixed */
    PERIOD?: ContractPeriod;
    /** xs:long */
    PERIOD_QUANTITY?: number;
    /** xs:double */
    PRICE?: number;
    /** ContractProcedure|xs:string|cprCard,cprVisit,cprPayment,cprFreeze,cprProcedure,cprSheduke */
    PROCEDURE?: ContractProcedure;
    /** xs:long */
    REPORTFILE?: number;
    /** xs:long */
    SERVICE?: number;
    /** xs:long */
    SERVICE_DISCOUNT?: number;
    /** xs:string */
    SERVICE_DISCOUNT_NAME?: string;
    /** xs:string */
    SERVICE_NAME?: string;
    /** ContractServicePeriod|xs:string|spAny,spWeek,spMonth1,spMonth2,spMonth3,spMonth6,spYear,spDays */
    SERVICE_PERIOD?: ContractServicePeriod;
    /** xs:long */
    SERVICE_PERIOD_DAYS?: number;
    /** xs:long */
    SERVICE_QUANTITY?: number;
    /** xs:boolean */
    SHEDULED?: boolean;
    /** xs:long */
    SYS_STATUS?: number;
    /** xs:string */
    BONUS?: string;
    /** xs:long */
    CSHI_ID?: number;
    /** xs:double */
    ENSUR_PERCENT?: number;
    /** xs:long */
    EXTEND_ID?: string;
    /** xs:long */
    OWNER_ORGANISATION?: number;
    /** xs:long */
    OWNER_PERSON?: number;
    /** OwnerType|xs:string|owtPerson,owtOrganization */
    OWNER_TYPE?: OwnerType;
    /** xs:long */
    PERSONAL_DISCOUNT?: number;
    /** xs:long */
    SHABLON?: number;
    /** xs:long */
    SHABLON_CLUB_CONTRACT_ID?: number;
    /** SOLD_CARD_LIST */
    SOLD_CARD_LIST?: Array<SoldClubCard>;
    /** ContractStatus|xs:string|csPrepare,csActual,csFreeze,csLock,csClose,csInWork,csDelete */
    STATUS?: PPSContractStatus;
    /** xs:long */
    USER?: number;
    /** xs:long */
    USER_CURRENT?: number;
    /** xs:dateTime */
    VALID_FROM?: string;
    /** xs:dateTime */
    VALID_TO?: string;
    /** xs:long */
    XP_CSHI_ID?: number;

    Template: ClubContract = new ClubContract(); 
    Person: PersonIsdLoyaltyData = new PersonIsdLoyaltyData();
}
