export const MetricsEvents = {
    IncartPlus: Symbol.for("incart_plus"), // Добавление услуг в корзине нажатием на кнопку «+», params id- идентификатор тарифа для услуг, занятий и дозаписи; идентификатор сеанса для арены; id - связки тарифов услуги
    IncartMinus: Symbol.for("incart_minus"),// Уменьшение количества услуг в корзине нажатием на кнопку «-», params id- идентификатор тарифа для услуг, занятий и дозаписи; идентификатор сеанса для арены; id - связки тарифов услуги
    IncartRemove: Symbol.for("incart_remove"), // Удаление отдельной услуги в интерфейсе корзины, params id- идентификатор тарифа для услуг, занятий и дозаписи; идентификатор сеанса для арены; id - связки тарифов услуги
    IncartCancel: Symbol.for("incart_cancel"), // Очистка всей корзины 
    IncartToPurchase: Symbol.for("incart_to_purchase"), // Переход к оформлению заказа из интерфейса корзины
    WidgetOpenCart: Symbol.for("widget_open_cart"),  // Происходит когда пользователь открывает интерфейс корзины 
    WidgeCloseCart: Symbol.for("widget_close_cart"),  // Происходит когда пользователь закрывает интерфейс корзины 
    
    WidgetToArenaSeances: Symbol.for("widget_to_arena_seances"), // Происходит при переходе на список ареновских сеансов,
    WidgetArenaChangeDate: Symbol.for("widget_arena_change_date"), // Происходит при смене даты на странице списка ареновских сеансов,
    WidgetToArenaSectors: Symbol.for("widget_to_arena_sectors"), // Происходит при переходе на список сектров, params id - идентификатор сеанса
    WidgetArenaSectorsBack: Symbol.for("widget_arena_sectors_back"), // Происходит при нажати на кнопку назад на этапе сектров, params id - идентификатор сеанса
    WidgetToArenaSeats: Symbol.for("widget_to_arena_seats"), // Происходит при переходе на список мест, params id - код сектора ППС
    WidgetArenaSeatsBack: Symbol.for("widget_arena_sectors_back"), // Происходит при нажати на кнопку назад на этапе выбора мест, params id - код сектора ППС
    ArenaSeatReservate: Symbol.for("arena_seat_reservate"), // Сработает при добавлении в корзину места (Арена), params id - идентификатор сеанса
    ArenaSeatUnreservate: Symbol.for("arena_seat_unreservate"), // Сработает при удалении из корзины места нажатием на само место (Арена), params id - идентификатор сеанса  

    WidgetToServices: Symbol.for("widget_to_services"), // Происходит при переходе на вкладку со списком услуг 
    ServiceChangeDate: Symbol.for("service_change_date"), // Это событие срабатывает при изменении даты в календаре виджета, на вкладке продажи услуг, params id-выбранная дата
    ServiceCartAdd: Symbol.for("service_cart_add"), // Сработает при добавлении в корзину услуг на вкладке услуг, params id- идентификатор тарифа или связки
    ServiceSeanceSelected: Symbol.for("service_seance_selected"), // Сработает при выборе сеанса на вкладке услуг, params id- идентификатор сеанса
    ServiceSeanceUnselected: Symbol.for("service_seance_selected"), // Сработает при снятии выбора с сеанса на вкладке услуг, params id - идентификатор сеанса

    WidgetToRewrite: Symbol.for("widget_to_rewrite"),  // Происходит при переходе на вкладку пополнения услуг на имеющийся носитель 
    RewriteCartAdd: Symbol.for("rewrite_cart_add"), // Сработает при добавлении в корзину услуг на вкладке пополнения услуг, params id- идентификатор тарифа или связки
    RewriteCheckCard: Symbol.for("rewrite_check_card"), // Событие происходит при проверке номера карты на вкладке пополнения услуг 
    RewriteClearCard: Symbol.for("rewrite_clear_card"), // Событие происходит при нажатии на кнопку очистить на вкладке пополнения услуг 
    RewriteChangeCard: Symbol.for("rewrite_change_card"), // Событие происходит при нажатии на кнопку изменить на вкладке пополнения услуг 
    RewriteChangeDate: Symbol.for("rewrite_change_date"), // Происходит при изменении даты в календаре виджета на вкладке пополнения услуг, params id-выбранная дата

    WidgetToPurchase: Symbol.for("widget_to_purchase"), // Срабатывает при переходе к оформлению заказа из начального окна виджета, кнопка «Купить»
    OrderRemoveItem: Symbol.for("order_remove_item"), // Это событие фиксируется при удалении позиции из заказа на этапе оформления заказа, id-идентификатор заказа
    OrderApplyLoyalty: Symbol.for("order_apply_loyalty"), // Это событие фиксируется при применении программы лояльности к заказу, id-идентификатор заказа
    OrderClickSignIn: Symbol.for("order_click_sign_in"), // Это событие при нажатии на ссылку войти при оформлении заказа, id-идентификатор заказа
    OrderSetUser: Symbol.for("order_set_user"), // Это событие срабатывает если пользователь авторизовался на оформлении заказа, id-идентификатор заказа
    OrderSetDiscount: Symbol.for("order_set_discount"), // Это событие срабатывает если пользователь выбрал скидку, id-идентификатор скидки
    OrderResetDiscount: Symbol.for("order_reset_discount"), // Это событие срабатывает если пользователь сбросил скидку сам или выбрал скидку программы лояльности, id-идентификатор заказа
    OrderSetPromo: Symbol.for("order_set_promo"),  // Это событие срабатывает если пользователь применил промокод, id-идентификатор заказа
    OrderRemovePromo: Symbol.for("order_remove_promo"),  // Это событие срабатывает если пользователь удалил промокод, id-идентификатор заказа
    OrderClickAgreements: Symbol.for("order_click_agreements"),  // Это событие срабатывает если пользователь нажал на ссылку соглашений, id-идентификатор заказа
    WidgetToPay: Symbol.for("widget_to_pay"), // Это событие срабатывает если пользователь перешел на оплату в банк, id-идентификатор заказа
    WidgetToPayAndChargeBonus: Symbol.for("order_click_agreements"), // Это событие срабатывает если пользователь перешел на оплату и спасал баллы в банк, id-идентификатор заказа

    WidgetReturnFromPayment: Symbol.for("widget_return_from_payment"),  // Срабатывает при переходе финальному шагу, params id-идентификатор заказа
    WidgetCompleteSuccess: Symbol.for("widget_complete_success"),  // Срабатывает когда пользователь увидел сообщение об успешной покупке, id-идентификатор заказа
    WidgetCompleteCancel: Symbol.for("widget_complete_cancel"),  // Срабатывает когда пользователь увидел сообщение об отмененном заказе, id-идентификатор заказа
    WidgetCompleteWaitingCancel: Symbol.for("widget_complete_waiting_cancel"),  // Срабатывает когда пользователь отказался ожидать результат платежа, id-идентификатор заказа
    WidgetRestart: Symbol.for("widget_restart"),  // Данное событие говорит о том что пользователь оплатив заказ на сайте банка вернулся на страницу сайта с виджетом и нажал кнопку начать сначала, id-идентификатор заказа

    FeedbackEmail: Symbol.for("feedback_email"), // Нажатие на кнопку написать на email на странице feedback
    FeedbackAppleStore: Symbol.for("feedback_apple_store"), // Нажатие на кнопку оценить в Аpple Stroe на странице feedback
    FeedbackPlayStore: Symbol.for("feedback_play_store"), // Нажатие на кнопку оценить в Play Store на странице feedback

    IsdLoyaltyEditProfile: Symbol.for("isd_loyalty_edit_profile"), // Редактирование профиля в программе лояльности ISD
    IsdLoyaltyRegistration: Symbol.for("isd_loyalty_registration"), // Регистрация в программе лояльности ISD

    NavigationPage: Symbol.for("navigation_page"), // Страница с навигацией 
    NavigationGoTo: Symbol.for("navigation_go_to"), // Страница с навигацией, нажатие на кнопку построить маршрут

    NewsList: Symbol.for("news_list"), // Переход на страницу новостей
    NewsItem: Symbol.for("news_item"), // Переход на страницу конкретной новости, params id - идентификатор новости

    OrdersList: Symbol.for("orders_list"), // Переход на страницу заказов
    OrderDetalization: Symbol.for("order_detalization"), // Переход на страницу конкретного заказа, params id - идентификатор заказа
    OrderTickets: Symbol.for("order_tickets"), // Нажатие на кнопку билеты в детализации заказа, params id - идентификатор заказа

    ChangePasswordPage: Symbol.for("change_password_page"), // Переход на страницу смены пароль
    PasswordChanged: Symbol.for("password_changed"), // Нажатие на кнопку изменить пароль на странице "Сменить пароль"

}