import './wp-sale.scss';

import Vue, { PropOptions } from 'vue';
import WpPageBaseComponent from './../wp-page-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	ISaleService,
	SaleSymbols,
	IContext,
	IdentitySymbols,
	IdentityChangedEventArgs,
	OrderSale,
	ICartService,
	CartSymbols,
	Cart,
	OrderStatus,
	INotificationService,
	NotificationSymbols,
	AppSettings,
	PersonificationApiOrderItem,
	ConnectionType,
	Order,
	ErrorTypes,
	ITariffCapacityService,
	TariffSymbols,
	ITariffService,
	LockOrderInfo,
	OrderItemType,
	IMetricsService,
	MetricsSymbols,
	IsdUserLoyalty,
	CartItemTypes,
	ILoyaltyProvider,
	LoyaltySymbols,
	PaymentSystemType,
	PaymentSystem,
	NotificationEventArgs,
	NotificationTypes,
	PayAccountPayment,
	DiscountType,
} from 'web-platform-core-ui';
import { Watch, Ref } from 'vue-property-decorator';
import { MetricsEvents } from '../metrics-events-symbols';
import { DefaultProps } from 'vue/types/options';
import { VueConstructor } from 'vue/types/umd';
import BaseUILocalizationDictionary from './../Localization/base-ui-localization-terms';

export const wpSaleProps = Vue.extend({
	props: {
		IframePurchase: <PropOptions>{
			type: Boolean,
			default: false,
		},
	},
});

@Component({
	template: require('./wp-sale.html'),
})
export default class WpSale extends mixins<WpPageBaseComponent<WpSaleDictionary>, DefaultProps, VueConstructor>(
	WpPageBaseComponent,
	wpSaleProps,
	Vue
) {
	protected _context!: IContext;
	protected _saleService!: ISaleService;
	protected _cartService!: ICartService;
	protected _notificationService!: INotificationService;
	protected _metricsService!: IMetricsService;

	protected _redirectToAuth: boolean = false;

	@Ref('WpPayConfirmComponent')
	protected WpPayConfirmComponent?: any;

	Type: ConnectionType = ConnectionType.Tariff;

	Settings!: AppSettings;
	IsdUserLoyalty?: IsdUserLoyalty;

	OnlyTab: Array<string> = [];
	ForceTab: string = '';
	SalesTab: string = 'service';
	CurrentSalesTab: string = 'service';

	ShowSteps: boolean = true;
	TimeLeftLoading: boolean = false;
	BackLoading: boolean = false;
	NextLoading: boolean = false;
	ScrollFab: boolean = false;
	SbpDialog: boolean = false;
	SbpLink: string = 'https://sbp.nspk.ru/api/img/QR_with_logo.png';
	SbpQRLink: string = '';

	SelectedAccountPayment: boolean = false;
	IsConfirmPhoneForAccountPayment: boolean = false;
	ConfirmPhoneForAccountPaymentId: number = 0;
	AccountPaymentSecurityToken: string = '';
	AccountPaymentUserPhone: string = '';
	DataForPayAccountPayment?: PayAccountPayment;

	LoyaltyDialog: boolean = false;
	HasPersonification: boolean = false;
	LoadingOverlay: boolean = false;
	OrderId: number = 0;
	Bank: boolean = false;
	PersonificationItems: Array<PersonificationApiOrderItem> = [];
	LeftTime: number = 0;
	IsMobile: boolean = false;

	Cart: Cart = Cart.Empty;

	Step: WpSaleSteps = WpSaleSteps.Uninitialized;

	SetLoadingOverlay(loadingOverlay: boolean): void {
		this.LoadingOverlay = loadingOverlay;
	}

	@Watch('Step')
	StepChangedEvent(step: number) {
		this.$emit('StepChanged', step);
	}

	OnTabChange(): void {
		if (this.SalesTab && this.CurrentSalesTab != this.SalesTab) {
			this.$nextTick(() => (this.CurrentSalesTab = this.SalesTab));
			this.CallMetricsEventForSalesTab();
		}
	}

	get NextAllowed(): boolean {
		if (this.Step == 1) {
			const TOTALCOUNT = this.Cart.Items.map((i) => i.Count).reduce((a, b) => a + b, 0);
			const MINCART = Math.max(this.Cart.Items.length, TOTALCOUNT) >= this.Settings.CartMinItems;
			return MINCART && TOTALCOUNT != 0;
		}
		if (this.Step == 2 && this.LeftTime > 0) return !this.PersonificationItems.some((x) => !x.IsValid);
		if (this.Step == 3 && this.LeftTime > 0) return true;
		return false;
	}

	created() {
		let _this = this;

		this.Settings = GlobalService.GetSettings<AppSettings>();
		this.IsMobile = this.Settings.IsMobile;

		if (this.Settings.CurrentSite != null) this.Type = this.Settings.CurrentSite.ConnectionType;

		this.OnlyTab = this.Settings.OnlyTab;
		if (this.Settings.ForceTab !== '') {
			this.CurrentSalesTab = this.Settings.ForceTab;
			this.SalesTab = this.Settings.ForceTab;
		}

		window.addEventListener('message', function (e) {
			if (e.data.status == 'return') {
				_this.Step = WpSaleSteps.Complete;
				_this.Bank = false;
			}
		});

		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
		this._saleService = this.GlobalService.Get<ISaleService>(SaleSymbols.SaleService);
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
		this._cartService = this.GlobalService.Get<ICartService>(CartSymbols.CartService);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
	}

	async mounted(): Promise<void> {
		parent.postMessage({ status: 'starting' }, '*');

		this.ShowSteps = false;
		this.PageLoading = true;
		this.Step = WpSaleSteps.Start;

		if (this.Type == ConnectionType.Tariff) this.CallMetricsEventForSalesTab();

		this._context.IdentityChanged.Subscribe(this.IdentityChangedEventHandler);

		try {
			this.Cart = await this._cartService.GetCurrentOrCreateCartAsync();

			let currentOrder = await this.RestoreStateAsync();
			if (currentOrder == null || currentOrder == undefined) {
				var cartItems = this.Cart.SimpleCart();
				parent.postMessage({ status: 'started', data: cartItems }, '*');
			} else {
				parent.postMessage({ status: 'started', data: currentOrder }, '*');
			}
		} catch (e) {
			this._notificationService.Error('Error', 'Не удалось получить информацию о корзине');
		}

		this.PageLoading = false;
		this._notificationService.NotificationSended.Subscribe(this.notification);
		this.ShowSteps = true;
	}

	async ConfirmedPhoneForAccountPayment(code: any): Promise<void> {
		this.ConfirmPhoneForAccountPaymentId = code.CodeId;

		if (this.DataForPayAccountPayment != null && this.ConfirmPhoneForAccountPaymentId != 0) {
			this.DataForPayAccountPayment.PhoneConfirmationCodeId = code.CodeId;
			var payResult = await this._saleService.PayForAccountPaymentAsync(this.DataForPayAccountPayment);

			if (!payResult.Success) {
				this._notificationService.Error('', payResult.ErrorMessage ? payResult.ErrorMessage : this.Terms.InternalError);
				this.LoadingOverlay = false;
				this.SelectedAccountPayment = false;
				return;
			}

			this.SelectedAccountPayment = false;
		}

		if (code.Error) {
			this.LoadingOverlay = false;
			this.SelectedAccountPayment = false;
			this.Step = WpSaleSteps.PayConfirm;
			return;
		}
	}

	ExternalBack(): void {
		this.Back();
	}

	ExternalForceStep(step: number): void {
		this.Step = step;
	}

	SetLeftTime(leftTime: number): void {
		this.LeftTime = leftTime;
	}

	get GetLeftTime(): string {
		return this.LeftTime + ' ' + this.Terms.GenericShorthandMinutes + '.';
	}

	async Back(): Promise<void> {
		this.BackLoading = true;
		this.LoadingOverlay = true;
		if (this.Step == WpSaleSteps.PayConfirm || this.Step == WpSaleSteps.Personification) {
			if (this.LeftTime > 0) {
				await this._saleService.CancelOrderAsync(this.OrderId);
				parent.postMessage({ status: 'cancelled' }, '*');
			}
			this.OrderId = 0;
		}

		await this._saleService.ClearOrderSaleStateAsync();

		this.Step = WpSaleSteps.Start;
		this.BackLoading = false;
		this.LoadingOverlay = false;
	}

	async Next(): Promise<void> {
		this.NextLoading = true;
		this.LoadingOverlay = true;
		if (this.Step == WpSaleSteps.Start) {
			await this.ReservateCartAsync();
			this.CallMetricsEvent();
		} else if (this.Step == WpSaleSteps.Personification) {
			await this.PersonificateOrder();
			this._metricsService.RegistrateEvent(MetricsEvents.WidgetToPurchase);
		} else if (this.Step == WpSaleSteps.PayConfirm) {
			if (this.WpPayConfirmComponent != null) this.WpPayConfirmComponent.LoginDrawer = true;
		}
		this.NextLoading = false;
		this.LoadingOverlay = false;
	}

	async PersonificationRemoveAllItems(existNotPersonificationItems: boolean) {
		if (existNotPersonificationItems) {
			this.Step = WpSaleSteps.PayConfirm;
		} else {
			await this.Back();
		}
	}

	public async ReservateCartAsync(): Promise<void> {
		let personId: number | null | undefined;

		if (
			this.Settings.RequiredLoyalty ||
			this.Cart.Items.some(
				(x) =>
					x.Type == CartItemTypes.InstructorsBundleLessons ||
					x.Type == CartItemTypes.InstructorsGroupLesson ||
					x.Type == CartItemTypes.InstructorsPersonalLesson ||
					x.Type == CartItemTypes.NewClubContract ||
					x.Type == CartItemTypes.ContractAdditionalService ||
					x.Type == CartItemTypes.ContractAdditionalPackage ||
					x.Type == CartItemTypes.AccountReplenishment
			)
		) {
			if (!this._context.CurrentIdentity.IsAuthentificated) {
				this._redirectToAuth = true;
				this._context.SignIn();
				return;
			}

			this.NextLoading = true;
			this.LoadingOverlay = true;

			let loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
			let userLoyalty = await loyaltyProvider.GetIsdUserLoyaltyAsync();

			if (userLoyalty.Success) {
				let loyaltyList = await this._saleService.GetLoyaltyListAsync();

				if (
					loyaltyList.Success &&
					loyaltyList.Data != null &&
					loyaltyList.Data.length > 0 &&
					(this.Settings.LoyaltyId == null || this.Settings.LoyaltyId == 0)
				) {
					this.Settings.LoyaltyId = loyaltyList.Data[0].Id;
				}

				if (userLoyalty.Data == null) {
					this.LoyaltyDialog = true;
					this.NextLoading = false;
					this.LoadingOverlay = false;
					return;
				} else {
					personId = userLoyalty.Data.PersId;
				}
			} else {
				this.NextLoading = false;
				this.LoadingOverlay = false;
				this._notificationService.Error('', userLoyalty?.ErrorMessage ?? this.Terms.InternalError);
				return;
			}
		}

		this.HasPersonification = false;
		this.NextLoading = true;
		this.LoadingOverlay = true;
		
		let reservationResult = await this._saleService.ReservateAsync(this.Cart, personId);

		if (!reservationResult.Success || reservationResult.Data == null) {
			if (reservationResult.ErrorType == ErrorTypes.FreeTicketLimit) {
				this._notificationService.Error('', `${this.Terms.FreeTicketLimitError} ${reservationResult.InvalidCartItem?.Info ?? ''}`);
			} else if (reservationResult.ErrorType == ErrorTypes.QuotaExceededForTariff) {
				this._notificationService.Error('', `${this.Terms.CartItemCountError} ${reservationResult.InvalidCartItem?.Info  ?? ''}`);
			} else if (reservationResult.ErrorType == ErrorTypes.TariffNotAvailableForSale) {
				this._notificationService.Error('', `${this.Terms.CartItemNotAvailableError} ${reservationResult.InvalidCartItem?.Info ?? ''}`);
			} else if (reservationResult.ErrorType == ErrorTypes.CardWithSoTypeTariff) {
				this._notificationService.Error('', `${this.Terms.CardHaveTariffWithSoType} ${reservationResult.InvalidCartItem?.Info ?? ''}`);
			} else if (reservationResult.ErrorType == ErrorTypes.LessonNotAvailable) {
				this._notificationService.Error('', this.Terms.LessonNotAvailable);
			} else if (reservationResult.ErrorType == ErrorTypes.RewriteTariffNotAvailable) {
				this._notificationService.Error('', this.Terms.CardHaveNotUsedTariff);
			} else {
				this._notificationService.Error('', reservationResult.ErrorMessage ? reservationResult.ErrorMessage : this.Terms.InternalError);
			}

			this.ShowSteps = false;

			this.$nextTick(() => {
				this.$emit('LoadingFinished');
				this.NextLoading = false;
				this.ShowSteps = true;
				this.LoadingOverlay = false;
			});

			return;
		}

		if (
			reservationResult.Data.Items.some(
				(x) =>
					(x.Type == OrderItemType.TariffPersonification ||
						x.Type == OrderItemType.TariffBarcodePesonification ||
						x.Type == OrderItemType.TariffRewritablePesonification) &&
					x.AdditionalInfo != undefined &&
					x.AdditionalInfo.Info != undefined &&
					x.AdditionalInfo.Info != null &&
					x.AdditionalInfo.Info.includes('_unknow')
			)
		) {
			this.HasPersonification = true;
		}

		this.Cart.Reset();
		this.NextLoading = false;

		this.OrderId = reservationResult.Data.Id;
		parent.postMessage({ status: 'reserved', data: { order_id: this.OrderId, order: reservationResult.Data } }, '*');
		if (this.HasPersonification) {
			this.Step = WpSaleSteps.Personification;
		} else {
			this.Step = WpSaleSteps.PayConfirm;
		}
		this.$emit('LoadingFinished');
		this.LoadingOverlay = false;
	}

	protected async PersonificateOrder(): Promise<void> {
		let result = true;
		for (let i = 0; i < this.PersonificationItems.length; i++) {
			let res = await this._saleService.SetPersonificationAsync(this.PersonificationItems[i]);
			if (!res.Success) {
				this._notificationService.Error('', res.ErrorMessage ? res.ErrorMessage : this.Terms.InternalError);
				await this._saleService.CancelOrderAsync(this.OrderId);
				await this._saleService.ClearOrderSaleStateAsync();
				result = false;
				return;
			}
		}
		if (result) this.Step = WpSaleSteps.PayConfirm;
		else this.Step = WpSaleSteps.Start;
	}

	protected async LockOrderAsync(info: LockOrderInfo): Promise<void> {
		this.LoadingOverlay = true;
		parent.postMessage({ status: 'wp-order-state', state: 'open' }, '*');

		let lockResult = await this._saleService.LockOrderWithDataAsync(info);

		if (!lockResult.Success) {
			if (lockResult.ErrorType == ErrorTypes.InvalidData) {
				this._notificationService.Error('', this.Terms.InvalidEmail);
			} else {
				this._notificationService.Error('', lockResult.ErrorMessage ? lockResult.ErrorMessage : this.Terms.InternalError);
			}

			if (this.WpPayConfirmComponent != null) this.WpPayConfirmComponent.PayButtonLoading = false;

			this.LoadingOverlay = false;
			return;
		}

		await this.PayOrderAsync(info.PaymentId, info.PaymentType);
	}

	protected async PayOrderAsync(paymentId: number, paymentType: PaymentSystemType): Promise<void> {
		this.LoadingOverlay = true;

		let paymentForm = await this._saleService.GetSpecificPaymentFormAsync(this.OrderId, paymentId);
		if (!paymentForm.Success || paymentForm.Data == null) {
			this._notificationService.Error('', paymentForm.ErrorMessage ? paymentForm.ErrorMessage : this.Terms.InternalError);
			this.LoadingOverlay = false;
			return;
		}

		if (paymentType == PaymentSystemType.SbpSberbank || paymentType == PaymentSystemType.Sbp) {
			this.SbpQRLink = paymentForm.Data.Address;
			this.Step = WpSaleSteps.Complete;
			if (this.Settings.IsDevVersion) {
				this.SbpLink = `https://mailer-test.moipass.ru/Barcode/QrcodeImg/?v=${paymentForm.Data.Address}`;
			} else {
				this.SbpLink = `https://mailer.moipass.ru/Barcode/QrcodeImg/?v=${paymentForm.Data.Address}`;
			}

			this.LoadingOverlay = false;
			if (this.Settings.IsMobile)
				try {
					this.IsMobile = this.Settings.IsMobile;
					// @ts-ignore
					openInBrowser(paymentForm.Data.Address);
				} catch {
					window.open(paymentForm.Data.Address, '_blank');
					this.SbpDialog = true;
				}
			else this.SbpDialog = true;
			return;
		}

		if (paymentType == PaymentSystemType.Account) {
			let accountPaymentSecurityToken = paymentForm.Data.Items.find((x) => x.Id == 'security_token')!.Value;
			if (accountPaymentSecurityToken == null || accountPaymentSecurityToken == '') {
				this._notificationService.Error('', paymentForm.ErrorMessage ? paymentForm.ErrorMessage : this.Terms.InternalError);
				this.LoadingOverlay = false;
				return;
			}

			this.DataForPayAccountPayment = new PayAccountPayment();
			this.DataForPayAccountPayment.OrderId = this.OrderId;
			this.DataForPayAccountPayment.PaymentId = paymentId;
			this.DataForPayAccountPayment.SecurityToken = accountPaymentSecurityToken;

			this.AccountPaymentUserPhone = this._context.CurrentIdentity.Phone!;
			this.SelectedAccountPayment = true;
			this.Step = WpSaleSteps.Complete;

			this.LoadingOverlay = false;

			return;
		}

		if (this.IframePurchase) {
			this.Bank = true;
		}

		this.Step = WpSaleSteps.Bank;

		// WARNING: sometimes submitPaymentForm() is missing from utility.js
		try {
			// @ts-ignore
			submitPaymentForm(paymentForm.Data, this.IframePurchase);
		} catch (e) {
			console.log('We tried to submit payment form but got this error instead: ');
			console.error(e);
		}

		if (this.IframePurchase) {
			this.LoadingOverlay = false;
		}
	}

	protected async IdentityChangedEventHandler(sender: any, e: IdentityChangedEventArgs): Promise<void> {
		if (this._redirectToAuth) {
			this._redirectToAuth = false;
			await this.ReservateCartAsync();
		}
	}

	protected async RestoreStateAsync(): Promise<OrderSale | undefined> {
		let state = await this._saleService.GetOrderSaleStateAsync();

		if (state.Success && state.Data != null) {
			let order = await this._saleService.GetOrderAsync(state.Data.OrderId);

			this.OrderId = state.Data.OrderId;

			if (order.Success && order.Data != null) {
				if (state.Data.Status == OrderStatus.Reserved) {
					if (this.Settings.ResetCart) {
						await this._saleService.CancelOrderAsync(state.Data.OrderId);
						await this._saleService.ClearOrderSaleStateAsync();
						return undefined;
					}

					if (
						order.Data.Items.some(
							(x) =>
								(x.Type == OrderItemType.TariffPersonification ||
									x.Type == OrderItemType.TariffBarcodePesonification ||
									x.Type == OrderItemType.TariffRewritablePesonification) &&
								x.AdditionalInfo != undefined &&
								x.AdditionalInfo.Info != undefined &&
								x.AdditionalInfo.Info != null &&
								x.AdditionalInfo.Info.includes('_unknow')
						)
					) {
						this.HasPersonification = true;
					}

					if (this.HasPersonification) {
						this.Step = WpSaleSteps.Personification;
					} else {
						this.Step = WpSaleSteps.PayConfirm;
					}
				} else if (state.Data.Status == OrderStatus.Locked) {
					this.Step = WpSaleSteps.Complete;
				} else if (state.Data.Status == OrderStatus.Canceled) {
					if (this.Cart.OrderId == state.Data.OrderId) {
						this.Cart.Reset();
						await this._saleService.ClearOrderSaleStateAsync();
						return undefined;
					}
				}

				return order.Data;
			} else {
				this._notificationService.Error('', order.ErrorMessage ? order.ErrorMessage : this.Terms.InternalError);
			}
		}

		return undefined;
	}

	protected AppGoBack() {
		if (this.Step == WpSaleSteps.Complete || this.Step == WpSaleSteps.Start) {
			if (this.$route.meta?.routeback != null && this.$route.meta.routeback != '') {
				this.$router.push({
					name: this.$route.meta.routeback as string,
				});
			} else {
				if (this.$route.name != WpPageBaseComponent.MainRoute) this.$router.push({ name: WpPageBaseComponent.MainRoute });
				else
					try {
						// @ts-ignore
						closeApplication('close');
					} catch (e) {
						console.error(e);
					}
			}
		} else {
			this.Back();
		}
	}

	OnSaleScroll(e: any): void {
		if (typeof window === 'undefined') return;
		const top = window.pageYOffset || e.target.scrollTop || 0;
		this.ScrollFab = top > 20;
	}

	ToTop(): void {
		this.$vuetify.goTo(0);
	}

	async notification(sender: any, e: NotificationEventArgs): Promise<void> {
		if (e.Data.Type == NotificationTypes.System) {
			if (e.Data.SystemProcessKey == 'SaleComplete') {
				this.SbpDialog = false;
			}
		}
	}

	async RegisteredLoyalty(success: boolean): Promise<void> {
		if (success) {
			this.LoyaltyDialog = false;
			await this.ReservateCartAsync();
		}
	}

	private CallMetricsEvent(): void {
		if (this.Step != WpSaleSteps.Personification) this._metricsService.RegistrateEvent(MetricsEvents.WidgetToPurchase);
	}

	private CallMetricsEventForSalesTab(): void {
		if (this.SalesTab == 'service') {
			this._metricsService.RegistrateEvent(MetricsEvents.WidgetToServices);
		} else if (this.SalesTab == 'rewrite') {
			this._metricsService.RegistrateEvent(MetricsEvents.WidgetToRewrite);
		} //else if (this.SalesTab == 'personal_lessons') {
		//    this._metricsService.RegistrateEvent(MetricsEvents.Per)
		//} else if (this.SalesTab == 'group_lessons') {
		//    this._metricsService.RegistrateEvent(MetricsEvents.WidgetToServices)
		//} else if (this.SalesTab == 'bundles_lessons') {
		//    this._metricsService.RegistrateEvent(MetricsEvents.WidgetToServices)
		//}
	}
}

export enum WpSaleSteps {
	Uninitialized = 0,
	Start = 1,
	Personification = 2,
	PayConfirm = 3,
	Complete = 4,
	Bank = 5,
}

export class WpSaleDictionary extends BaseUILocalizationDictionary {
	TabService: string = 'Продажа услуг';
	TabRewrite: string = 'Пополнение услуг';
	TabPersonalLessons: string = 'Индивидуальные занятия';
	TabGroupLessons: string = 'Групповые занятия';
	TabBundlesLessons: string = 'Многодневные занятия';

	GenericPlaceOrder: string = 'Оформить';
	GenericPay: string = 'Оплатить';
	GenericBuy: string = 'Купить';
	GenericProcessPayment: string = 'Оплата';
	GenericShorthandMinutes: string = 'мин';

	CartItemNotAvailableError = 'Одна из позиций в корзине уже недоступна';
	CartItemCountError = 'Закончились билеты на одну из позиций в корзине';

	CardHaveTariffWithSoType = 'Билет уже с прокатным договором';
	CardHaveNotUsedTariff = 'На карте есть неизрасходованная услуга';
	LessonNotAvailable = 'Занятие недоступно';

	InvalidEmail = 'Неверный формат email-а';

	DataRequired: string = 'Необходимо заполнить данные';
	SbpTitle: string = 'Отсканируйте qr код и перейдите по ссылке для оплаты заказа';
	SbpMobileTitle: string = 'Если приложение банка не открылось, перейдите по ';
	SbpMobileTitleLink: string = 'ссылке';

	FreeTicketLimitError: string = 'Вы уже приобретали бесплатный билет';
}
