import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
    AppSettings, ITariffService, TariffSymbols, TariffCartItem, Cart, TariffsBundle, BundleCartItem, Tariff,
    TariffSale, TariffSeance, ITariffCapacityService, TariffCapacity, ICartService, CartSymbols, CartItemTypes
} from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { Watch } from 'vue-property-decorator';
import { VueConstructor } from 'vue/types/umd';
import BaseUILocalizationDictionary from './../../../Localization/base-ui-localization-terms';
import { TariffBundleItem } from '../tariff-item';

export const wpTariffBundleProp = Vue.extend({
    props: {
        Id: String,
        Bundle: Object as Prop<TariffsBundle>,
        Date: String,
        Cart: Object as Prop<Cart>,
        Seance: Object as Prop<TariffSeance>,
        UIActive: Boolean
    }
})

@Component({
    template: require("./wp-tariff-bundle.html")
})
export default class WpTariffBundle extends mixins<WpBaseComponent<WpTariffBundleDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpTariffBundleProp, Vue) {
    private _tariffCapacityService!: ITariffCapacityService;
    private _cartService!: ICartService;
    private _tariffService!: ITariffService;
    Settings!: AppSettings;

    Items: Array<TariffBundleItem> = [];
    TariffsSales: Array<any> = [];
    TariffsCapacity: Array<any> = [];

    BundleAvailable: boolean = false;
    BundleLoading: boolean = false;
    CartMaxItems: number = 6;

    HaveParkingTariff: boolean = false;
    CarNumberDialog: boolean = false;
    CarNumberFormValid: boolean = false;
    CarNumbersItems: Array<string> = [];
    CarTickets: Array<any> = [];
    radioGroupModel = [];
    tabsRentalOptions = 0;

    dialogRental = false;

    created(): void {
        this.Settings = GlobalService.GetSettings<AppSettings>();
        this.CartMaxItems = this.Settings.CartMaxItems;

        this._tariffService = this.GlobalService.Get<ITariffService>(TariffSymbols.TariffService);
        this._tariffCapacityService = this.GlobalService.Get<ITariffCapacityService>(TariffSymbols.TariffCapacityService);
        this._cartService = this.GlobalService.Get<ICartService>(CartSymbols.CartService);
    };

    async mounted(): Promise<void> {
        await this.GetTariffsPrice();
        this.LoadCarNumbers();
    };


    @Watch('Date')
    DateChanged(_val: string, _oldVal: string) {
        this.GetTariffsPrice();
    };

    get CarNumberRules() {
        return [
            (v: string) => !!v || this.Terms.CarNumberRequiredError,
            (v: string) => /^[a-zA-Z0-9авекмнкстоухАВЕКМНКСТОУХ]{6,15}$/.test(v) || this.Terms.CarNumberNotValid
        ]
    }

    get Amount(): number {
        return this.Bundle.Tariffs.length;
    };

    get SeanceTime(): string | undefined {
        if (this.Seance != null && this.Seance.SeanceValue != null && this.Seance.SeanceValue != '')
            return this.Seance.SeanceValue.split(' ', 1)[0];
    };

    get rentalOptionsTariffs() {
        return this.Items.filter(item => item.tariff.RentalOptions.length)
    }

    get hasRentalOptions() {
        return this.rentalOptionsTariffs.length ? true : false;
    }

    Select() {
        if (this.hasRentalOptions)
            this.dialogRental = true;
        else if (this.HaveParkingTariff)
            this.CarNumberDialog = true;
        else
            this.AddToCart();
    };

    AddToCart(): void | boolean {
        let sales: Array<TariffSale> = [];
        let capacity: Array<TariffCapacity | undefined> = [];
        this.Items.forEach(x => {
            if (x.sale) sales.push(x.sale);
            capacity.push(x.capacity);
        });
        let cartItem = new BundleCartItem(
            this.Bundle,
            this.Date,
            this.Price,
            sales,
            capacity,
            this.Seance,
            undefined,
            this.radioGroupModel
        );
        this.$emit('addtocart', cartItem);
        this.dialogRental = false;
        this.radioGroupModel = [];
    };

    addRentalOptionsToCart() {
        console.log(this.Items)
        console.log(this.radioGroupModel)
        this.AddToCart()
    }

    AddToCartWithCarNumbers(): void {
        if (!(this.$refs.CarNumberForm as HTMLFormElement).validate()) return;

        let carNumbers: Array<string> = [];
        this.CarTickets.forEach(t => carNumbers.push(this._tariffService.HandlingCarNumber(t.Value)));

        let sales: Array<any> = [];
        let capacity: Array<any> = [];
        this.Items.forEach(x => {
            sales.push(x.sale);
            capacity.push(x.capacity);
        });
        let cartItem = new BundleCartItem(this.Bundle, this.Date, this.Price, sales, capacity, this.Seance, carNumbers);
        this.$emit('addtocart', cartItem);

        this._tariffService.SaveCarNumberInStorage(this.CarNumbersItems);
        (this.$refs.CarNumberForm as HTMLFormElement).reset();
        this.CarNumberDialog = false;
    };

    get Price() {
        // Пересчитаем цену
        return this.Items.reduce((a, item) => { return item.tariffPrice! + a }, 0);
    };

    async GetTariffsPrice(): Promise<void> {
        this.BundleLoading = true;
        this.BundleAvailable = false;
        this.TariffsSales = [];
        this.TariffsCapacity = [];
        this.Items = [];

        let currentCartItem: BundleCartItem | undefined;
        let cart = await this._cartService.GetCurrentOrCreateCartAsync();

        cart.Items.forEach(x => {
            if (x.Type == CartItemTypes.Bundle) {
                let cartItem = x as BundleCartItem;
                if (this.Seance != null && cartItem.Seance != null &&
                    cartItem.Bundle.Id == this.Bundle.Id &&
                    cartItem.Date == this.Date &&
                    cartItem.Seance.SeanceId == this.Seance.SeanceId) {
                    currentCartItem == cartItem;
                } else {
                    if (cartItem.Seance == null && cartItem.Bundle.Id == this.Bundle.Id && cartItem.Date == this.Date)
                        currentCartItem == cartItem;
                };
            };
        });

        for (let i = 0; i < this.Bundle.Tariffs.length; i++) {
            let tariffSale = new TariffSale();
            let tariffCapacity: TariffCapacity | undefined;
            let maxCount = 0;
            let useCapacity = false;
            let tariffPrice;

            let tariff: Tariff = this.Bundle.Tariffs[i];

            let result = await this._tariffService.GetTariffPrice(this.Date, tariff.Id, tariff.HaveSeance ? this.Seance?.SeanceId : "0");

            // Если удалось получить цену, и она не undefined (не найдена) и не null (не доступна)
            if (result.Success && result.Data !== undefined && result.Data !== null) {
                tariffPrice = result.Data;
                this.BundleAvailable = true;
            } else {
                this.BundleAvailable = false;
                break;
            };

            if (tariff.Quota != null) {
                let sales = await this._tariffService.GetSingleTariffSalesAsync(this.Date, tariff, this.Seance?.SeanceId);
                if (sales.Success && sales.Data != null) {
                    let sale = this.TariffsSales.find(x => x.Id == `${tariff.Id}_${this.Date}_${this.Seance?.SeanceId}`);

                    tariffSale = sales.Data;

                    if (sale != null) {
                        sale.Coefficient++;
                    } else {
                        sale = { Id: `${tariff.Id}_${this.Date}_${this.Seance?.SeanceId}`, Sale: tariffSale, Coefficient: 1 };
                        this.TariffsSales.push(sale);
                    };
                };
            };

            if (tariff.ShowLeftTicketCount) {
                if (tariff.HaveSeance === true && this.Seance != null && tariff.Quota == null) {
                    let capacity = await this._tariffCapacityService.GetCapacityAsync(tariff.Id, this.Date, this.Seance.SeanceId);

                    if (capacity.Success && capacity.Data != null) {
                        let existCapacity = this.TariffsCapacity.find(x => x.Id == `${capacity.Data?.ZoneId}_${this.Date}_${this.Seance?.SeanceId}`);

                        if (existCapacity != null) {
                            existCapacity.Coefficient++;
                        } else {
                            existCapacity = { Id: `${capacity.Data?.ZoneId}_${this.Date}_${this.Seance?.SeanceId}`, Capacity: capacity.Data, Coefficient: 1 };
                            this.TariffsCapacity.push(existCapacity);
                        };

                        tariffCapacity = capacity.Data;
                        maxCount = tariffCapacity.Capacity;
                        useCapacity = true;
                    };
                };

                if (tariffCapacity == null && tariff.Quota == null) {
                    this.BundleAvailable = false;
                    break;
                };

                if (tariffSale.Quota != null)
                    maxCount = tariffSale.Quota;
            };

            this.Items.push({ tariff: tariff, sale: tariffSale, capacity: tariffCapacity, maxCount: maxCount, useCapacity: useCapacity, tariffPrice: tariffPrice });
        };

        // Посчитаем сколько осталось тарифов в связке
        let itemsLeft = 0;
        // Count - кол-во уже скупленных билетов. Отнимем его от максимально возможного - получим сколько осталось
        this.TariffsCapacity.forEach(function (e) {
            itemsLeft += (e.Capacity.Capacity - e.Capacity.Count);
        });

        this.BundleLoading = false;
        this.$emit('LoadingChange', this.Id, this.BundleLoading);
        this.$emit('AvailableChange', this.Id, this.BundleAvailable, itemsLeft, this.Seance?.SeanceId, this.Price, this.Items);
    };

    async CarNumberFormSubmit(e: any) {
        e.preventDefault();
        this.AddToCartWithCarNumbers();
    }

    CarNumberChange(value: string) {
        if (value == null || !/^[a-zA-Z0-9авекмнорстухАВЕКМНОРСТУХ]{6,15}$/.test(value)) {
            return;
        }

        let autoNumber = value.toUpperCase();

        if (!this.CarNumbersItems.some(x => x == autoNumber)) {
            this.CarNumbersItems.push(autoNumber);
        }
    }

    protected LoadCarNumbers() {
        let parkingTariffs = this.Bundle.Tariffs.filter((t: Tariff) => t.IsParking) as Array<Tariff>;
        if (parkingTariffs.length == 0) return;

        this.HaveParkingTariff = true;
        for (let i = 0; i < parkingTariffs.length; i++) {
            let tariff = parkingTariffs[i];
            this.CarTickets.push({
                Id: `${tariff.Id}_${i + 1}`,
                Value: '',
                Name: tariff.Name
            });
        }

        this.CarNumbersItems = this._tariffService.GetCarNumbersFromStorage();
    }
};

export class WpTariffBundleDictionary extends BaseUILocalizationDictionary {
    GenericItemsLeft: string = 'осталось';
    GenericItemsOf: string = 'из';
    GenericToCart: string = 'В корзину';
    CartIsFull: string = 'Максимальное количество элементов в корзине';
    NoMoreTickets: string = 'Закончились билеты';
    CustomService: string = 'Абонемент на 1 день для проезда и провоза велосипеда на подъемнике Олимпия (однократно) и Волчья Скала (многократно).';

    CarNumberDialogTitle: string = 'Введите номер(а) автомобиля';
    CarTicketTitle: string = 'Билет ';
    CarNumberTextLabel: string = 'Введите номер';
    CarNumberRequiredError: string = 'Поле обязательно к заполнению';
    CarNumberNotValid: string = 'Неверный формат';
};