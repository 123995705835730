export default class WidgetStyle {
    Id: number = 0;
    Font?: string;
    Primary?: string;
    Secondary?: string;
    Accent?: string;
    Error?: string;
    Info?: string;
    Success?: string;
    Warning?: string;
}