import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	AppSettings,
	ITariffService,
	TariffSymbols,
	Tariff,
	TariffCartItem,
	Cart,
	CartItemTypes,
	TariffSeance,
	TariffSale,
	ITariffCapacityService,
	TariffCapacity,
	ICartService,
	CartSymbols,
} from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { Watch } from 'vue-property-decorator';
import { VueConstructor } from 'vue/types/umd';
import BaseUILocalizationDictionary from './../../../Localization/base-ui-localization-terms';
import { months } from 'moment';
import { RentalOption } from 'web-platform-core-ui/src/Tariffs/tariff';

export const wpTariffItemProp = Vue.extend({
	props: {
		Id: String,
		tariff: Object as Prop<Tariff>,
		Date: String,
		Cart: Object as Prop<Cart>,
		Seance: Object as Prop<TariffSeance>,
		UIActive: Boolean,
	},
});

@Component({
	template: require('./wp-tariff-item.html'),
})
export default class WpTariffItem extends mixins<WpBaseComponent<WpTariffItemDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpTariffItemProp,
	Vue
) {
	static CarNumbersStorage: Array<string> = [];

	protected _tariffService!: ITariffService;
	protected _tariffCapacityService!: ITariffCapacityService;
	protected _cartService!: ICartService;
	protected TariffSale: TariffSale = new TariffSale();
	protected Settings!: AppSettings;
	protected _currentCartItem?: TariffCartItem;
	protected _price: number = 0;
	protected DialogRental = false;

	Capacity?: TariffCapacity;
	UseCapacity: boolean = false;

	TariffAvailable: boolean = false;
	Amount: number = 1;
	CartMaxItems: number = 6;
	TariffLoading: boolean = true;
	MaxCount: number = 0;
	Price: number = 0;
	CarNumberDialog: boolean = false;
	CarNumberFormValid: boolean = false;
	CarNumbersItems: Array<string> = [];
	CarTickets: Array<any> = [];

	rentalOption: RentalOption | null = null;
	rentalOptionPrices: Map<number, number> = new Map();

	get CarNumberRules() {
		return [
			(v: string) => !!v || this.Terms.CarNumberRequiredError,
			(v: string) => /^[a-zA-Z0-9авекмнкстоухАВЕКМНКСТОУХ]{6,15}$/.test(v) || this.Terms.CarNumberNotValid,
		];
	}

	created(): void {
		this.Settings = GlobalService.GetSettings<AppSettings>();
		this.CartMaxItems = this.Settings.CartMaxItems;

		this._tariffService = this.GlobalService.Get<ITariffService>(TariffSymbols.TariffService);
		this._tariffCapacityService = this.GlobalService.Get<ITariffCapacityService>(TariffSymbols.TariffCapacityService);
		this._cartService = this.GlobalService.Get<ICartService>(CartSymbols.CartService);

		this.LoadCarNumbers();
	}

	async mounted(): Promise<void> {
		await this.GetTariffPrice();

		this.Cart.CartChanged.Subscribe((sender: any, e: any) => {
			this.Amount = 1;
			this.Price = this._price * this.Amount;
		});
	}

	SelectItem() {
		if (this.hasRentalOptions) this.DialogRental = true;
		else this.AddToCart();
	}

	@Watch('Date')
	DateChanged(_val: string, _oldVal: string) {
		this.GetTariffPrice();
	}

	get CountInCart(): number {
		return this._currentCartItem !== undefined ? this._currentCartItem.Count : 0;
	}

	get SeanceTime(): string | undefined {
		return this.Seance != null ? TariffSeance.SeanceTime(this.Seance) : '';
	}

	AmountDecrease(): void {
		if (this.Amount >= 2) {
			this.Amount--;
			this.Price = this._price * this.Amount;
		}
	}

	AmountIncrease(): void {
		this.Amount++;
		this.Price = this._price * this.Amount;
	}

	get CanAmountIncrease(): boolean {
		if (this.TariffSale.Quota != null && this.TariffSale.LeftCount) {
			return this.Amount < this.CartMaxItems - this.Cart.Count && this.Amount < this.TariffSale.LeftCount;
		} else {
			if (this.Capacity != null) {
				return this.Amount < this.CartMaxItems - this.Cart.Count && this.Amount < this.Capacity.LeftCount;
			} else return this.Amount < this.CartMaxItems - this.Cart.Count;
		}
	}

	get rentalOptions(): Array<any> {
		if (this.tariff.RentalOptions && this.tariff.RentalOptions.length != 0) return this.tariff.RentalOptions;
		return [];
	}

	get hasRentalOptions(): boolean {
		return this.rentalOptions.length ? true : false;
	}

	/* Добавить в корзину */
	AddToCart(): void | boolean {
		if (this.tariff.IsParking) {
			this.CarTickets = [];
			for (let i = 1; i <= this.Amount; i++) this.CarTickets.push({ Id: i, Value: '' });
			return (this.CarNumberDialog = true);
		}

		let cartItem = new TariffCartItem(
			this.tariff,
			this.Amount,
			this.Date,
			this._price,
			this.Cart,
			this.TariffSale,
			this.Seance,
			this.Capacity,
			undefined,
			this.rentalOption || undefined
		);

		this.$emit('addtocart', cartItem);
		this.Ammount = 1;

		this.rentalOption = null;
		this.DialogRental = false;
	}

	AddToCartWithCarNumbers() {
		if (!(this.$refs.CarNumberForm as HTMLFormElement).validate()) return;

		this.CarTickets.forEach((x) => {
			let cartItem = new TariffCartItem(
				this.tariff,
				1,
				this.Date,
				this._price,
				this.Cart,
				this.TariffSale,
				this.Seance,
				this.Capacity,
				this._tariffService.HandlingCarNumber(x.Value)
			);
			this.$emit('addtocart', cartItem);
		});

		(this.$refs.CarNumberForm as HTMLFormElement).reset();

		this._tariffService.SaveCarNumberInStorage(this.CarNumbersItems);

		this.CarNumberDialog = false;
		this.Amount = 1;
	}

	CarNumberChange(value: string) {
		if (value == null || !/^[a-zA-Z0-9авекмнорстухАВЕКМНОРСТУХ]{6,15}$/.test(value)) {
			return;
		}

		let autoNumber = value.toUpperCase();

		if (!this.CarNumbersItems.some((x) => x == autoNumber)) {
			this.CarNumbersItems.push(autoNumber);
		}
	}

	async CarNumberFormSubmit(e: any) {
		e.preventDefault();
		this.AddToCartWithCarNumbers();
	}

	async GetTariffPrice(): Promise<any> {
		this.TariffLoading = true;
		this.TariffAvailable = false;
		this.$emit('LoadingChange', this.Id, this.TariffLoading);
		this.$emit('AvailableChange', this.Id, this.TariffAvailable);

		this.UseCapacity = false;
		this.Capacity = undefined;

		let result = await this._tariffService.GetTariffPrice(this.Date, this.tariff.Id, this.Seance?.SeanceId);
		if (result.Success && result.Data !== undefined && result.Data !== null) {
			this._price = result.Data;
			this.Price = this._price * this.Amount;
			this.TariffAvailable = true;
		} else {
			this.TariffAvailable = false;
		}

		if (this.tariff.Quota != null) {
			let sales = await this._tariffService.GetSingleTariffSalesAsync(this.Date, this.tariff, this.Seance?.SeanceId);
			if (sales.Success && sales.Data != null) this.TariffSale = sales.Data;
		}

		// Get rental option prices (if there are any)
		// if (this.hasRentalOptions) {
		//     for (const option of this.rentalOptions) {
		//         const ID = option.BindedTariffId
		//         let p = this.rentalOptionPrices.get(ID)
		//         if (p === undefined) {
		//             let price = await this._tariffService.GetTariffPrice(this.Date, ID); // Do we need SessionId here?
		//             if (price.Success)
		//                 this.rentalOptionPrices.set(ID, price.Data);
		//         }
		//     }
		// }

		if (this.tariff.ShowLeftTicketCount) {
			if (this.tariff.HaveSeance === true && this.Seance != null && this.tariff.Quota == null) {
				// Find tariff price in cache to see if it's available by null check
				// Form a string identifier like '7572_30.12.2022_5116900`
				let stringid = `${this.tariff.Id}_${this.Date}_${this.Seance.SeanceId}`;
				// Get price from cache.
				let price = this._tariffService.Cache[`${stringid}` as any];
				// Null - means it's been cached but tariff is not available for that date
				// number - means it's price been cached but somehow not the capacity
				// undefined - means it's price not been cached

				// If price is null - no need to request capacity
				if (price !== null) {
					// This will request capacity either from cash of capacity service or from server AS LAST RESORT
					let capacity = await this._tariffCapacityService.GetCapacityAsync(this.tariff.Id, this.Date, this.Seance.SeanceId);

					if (capacity.Success && capacity.Data != null) {
						this.Capacity = capacity.Data;
						this.MaxCount = this.Capacity.Capacity;
						this.UseCapacity = true;
					}
				}
			}

			if (this.Capacity == null && this.tariff.Quota == null) this.TariffAvailable = false;

			if (this.TariffSale.Quota != null) this.MaxCount = this.TariffSale.Quota;
		}

		let cart = await this._cartService.GetCurrentOrCreateCartAsync();
		cart.Items.forEach((x) => {
			if (x.Type == CartItemTypes.Tariff) {
				let cartItem = x as TariffCartItem;
				if (this.Seance == null) {
					if (cartItem.Tariff.Id == this.tariff.Id && cartItem.Date == this.Date) {
						this._currentCartItem = cartItem;
					} else {
						this._currentCartItem = undefined;
					}
				} else {
					if (
						cartItem.Tariff.Id == this.tariff.Id &&
						cartItem.Date == this.Date &&
						cartItem.Seance != null &&
						cartItem.Seance.SeanceId == this.Seance.SeanceId
					) {
						this._currentCartItem = cartItem;
					} else {
						this._currentCartItem = undefined;
					}
				}
			}
		});

		let availableCount: number | undefined;
		if (this.UseCapacity) {
			if (this.Capacity != null) availableCount = this.Capacity.Capacity - this.Capacity.Count;
		} else {
			if (this.TariffSale.Quota != null) availableCount = this.TariffSale.Quota - this.TariffSale.Count;
		}

		this.TariffLoading = false;
		this.$emit('LoadingChange', this.Id, this.TariffLoading);
		this.$emit('AvailableChange', this.Id, this.TariffAvailable, availableCount, this.Seance?.SeanceId, this.Price);
	}

	protected LoadCarNumbers() {
		if (!this.tariff.IsParking) return;
		this.CarNumbersItems = this._tariffService.GetCarNumbersFromStorage();
	}

	get cartIsFull() {
		return this.Cart.Count === this.CartMaxItems;
	}
}

export class WpTariffItemDictionary extends BaseUILocalizationDictionary {
	GenericItemsLeft: string = 'осталось';
	GenericItemsOf: string = 'из';
	GenericToCart: string = 'В корзину';
	CartIsFull: string = 'Максимальное количество элементов в корзине';
	NoMoreTickets: string = 'Закончились билеты';
	TariffUnavailableForThisDate: string = 'Тариф недоступен на эту дату';

	CarNumberDialogTitle: string = 'Введите номер(а) автомобиля';
	CarTicketTitle: string = 'Билет ';
	CarNumberTextLabel: string = 'Введите номер';
	CarNumberRequiredError: string = 'Поле обязательно к заполнению';
	CarNumberNotValid: string = 'Неверный формат';
}
