import './wp-tariffs-datepicker.scss';
import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, { AppSettings, TariffsCalendarType } from 'web-platform-core-ui';
import { PropSync, Watch } from 'vue-property-decorator';
import BaseUILocalizationDictionary from './../../../Localization/base-ui-localization-terms';

@Component({
	template: require('./wp-tariffs-datepicker.html'),
})
export default class WpTariffsDatepicker extends mixins<WpBaseComponent<WpTariffsDatepickerDictionary>>(WpBaseComponent, Vue) {
	static DateSelected: boolean = false;

	SessionDateRangeModal: boolean = false;
	TariffDatePicker: any;
	SessionDateRange: string = '';
	SessionDateRangeSub: string = '';
	SessionDatesVisible: boolean = true;
	CloseAvailable: boolean = false;
	CalendarType: TariffsCalendarType = TariffsCalendarType.Field;

	ShowButtonVisible: boolean = true;

	DateRangeSub: string = '';
	DateRangeTitle: string = '';

	TimeOffset: string = '';

	MinDate: string = '';
	MaxDate: string = '';
	ForceDate?: Date;

	Today: Date = new Date();

	// This array stores AVAILABLE DATES
	allowedDatesArray: Array<any> = [];

	@PropSync('settings', { type: Object }) Settings!: AppSettings;

	@Watch('Settings', { immediate: true })
	OnSettingsChange(val: AppSettings, _oldVal: AppSettings) {
		if (val !== undefined) this.ChangeSettings();
	}

	created(): void {
		let settings = GlobalService.GetSettings<AppSettings>();
		this.Settings = settings;
		this.TariffDatePicker = this.SessionDateRange = this.DateHandler.Parse(new Date()).Format('YYYY-MM-DD');
		this.CalendarType = settings != null ? settings.TariffsCalendar : TariffsCalendarType.Field;

		// Lets set default min/max dates as now and now+30 days temporaryly
		this.MinDate = this.DateHandler.Parse(new Date()).Format('YYYY-MM-DD');
		this.MaxDate = this.DateHandler.Parse(new Date()).Add('days', 30).Format('YYYY-MM-DD');

		if (this.CalendarType == TariffsCalendarType.Page && !WpTariffsDatepicker.DateSelected) this.SessionDateRangeModal = true;
	}

	get DatesChoosen(): string {
		//let date = moment(this.TariffDatePicker, 'YYYY-MM-DD').format('DD.MM.YYYY');
		let date = this.DateHandler.Parse(new Date(this.TariffDatePicker)).Format('DD.MM.YYYY');
		return date;
	}

	AllowedDates(val: string): boolean {
		let date = this.DateHandler.Parse(new Date(val)).Format('DD.MM.YYYY');
		//let date = moment(val, 'YYYY-MM-DD');
		if (this.Settings.Disableddates?.length > 0) {
			return this.Settings.Disableddates.indexOf(date) === -1;
		} else {
			return true;
		}
	}

	async ChangeSettings() {
		this.SessionDatesVisible = !this.Settings.Nocalendar;
		// Берем Offset из настроек сайта (Например "03:00:00")
		this.TimeOffset = this.Settings.CurrentSite?.Offset || '';
		// Конвертируем в милисекунды
		let msOffset = this.DateHandler.ConvertToMilliseconds(this.TimeOffset);
		// Создаём новую дату, с учетом сдвига (Offset)
		let nowDate = new Date();
		var currentDateOffset = Date.UTC(
			nowDate.getUTCFullYear(),
			nowDate.getUTCMonth(),
			nowDate.getUTCDate(),
			nowDate.getUTCHours(),
			nowDate.getUTCMinutes() + nowDate.getTimezoneOffset() + msOffset / 1000 / 60,
			nowDate.getUTCSeconds()
		);
		this.Today = new Date(currentDateOffset);
		/* Логика:
		 * -Создаем новую дату (new Date() )
		 * -getUTCHours например возвращает UTC+0000 часы. Т.е. если в Москве 10 (UTC+03:00), то UTCHours вернет 7
		 *
		 * В итоге:
		 * В Москве 11.01.2022 - 10:50
		 * В Аляске 10.01.2022 - 22:50
		 *
		 * Мы в Аляске. let d = new Date();
		 * console.log( d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds() );
		 * вернет 2022 0 11 7 45 7
		 *
		 * Date.UTC( d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds() )
		 * вернет 1641887799000
		 *
		 * new Date(1641887799000) создаст дату и отнимет у неё 9 часов, что нормально, т.к. мы их отняли ранее
		 * вуаля, мы получили new Date() с таймзоной this.TimeOffset
		 */

		/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- */

		// This may be unnecessary
		if (this.Settings.Forcedate == undefined && this.Settings.Nocalendar != undefined) {
			this.TariffDatePicker = this.DateHandler.Parse(new Date()).Format('YYYY-MM-DD');
			this.SessionDateRange = this.DateHandler.Parse(new Date()).Format('DD.MM.YYYY');
		}

		// Новые мин/макс даты с учётом сдвига
		let currentDate = new Date(currentDateOffset);
		this.MinDate = this.DateHandler.Parse(currentDate).Format('YYYY-MM-DD');
		this.MaxDate = this.DateHandler.Parse(currentDate).Add('days', 30).Format('YYYY-MM-DD');

		if (this.Settings.Maxdate) {
			let maxDateString = this.Settings.Maxdate.split('.').reverse().join('-');
			let maxdate = new Date(maxDateString);
			this.MaxDate = this.DateHandler.Parse(new Date(maxDateString)).Format('YYYY-MM-DD');

			if (maxdate.getTime() < currentDate.getTime()) {
				this.TariffDatePicker = this.DateHandler.Parse(maxdate).Format('YYYY-MM-DD');
				this.SessionDateRange = this.DateHandler.Parse(maxdate).Format('DD.MM.YYYY');
			}
		}

		if (this.Settings.Mindate) {
			let mindate = new Date(this.Settings.Mindate.split('.').reverse().join('-'));
			this.MinDate = this.DateHandler.Parse(new Date(mindate)).Format('YYYY-MM-DD');

			if (mindate.getTime() > currentDate.getTime()) {
				this.TariffDatePicker = this.DateHandler.Parse(mindate).Format('YYYY-MM-DD');
				this.SessionDateRange = this.DateHandler.Parse(mindate).Format('DD.MM.YYYY');
			}
		}

		// <- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -> \\
		// { "forcedate":"21.02.2022" }
		if (this.Settings.Forcedate !== undefined) {
			let ForcedDate = new Date(this.Settings.Forcedate.split('.').reverse().join('/'));

			if (this.DateHandler.IsValid(ForcedDate)) {
				let min = new Date(this.MinDate).getTime();
				let max = new Date(this.MaxDate).getTime();
				let fdt = ForcedDate.getTime();

				if (fdt < min) {
					this.TariffDatePicker = this.DateHandler.Parse(new Date(this.MinDate)).Format('YYYY-MM-DD');
					this.SessionDateRange = this.DateHandler.Parse(new Date(this.MinDate)).Format('DD.MM.YYYY');
				} else if (fdt > max) {
					this.TariffDatePicker = this.DateHandler.Parse(new Date(this.MaxDate)).Format('YYYY-MM-DD');
					this.SessionDateRange = this.DateHandler.Parse(new Date(this.MaxDate)).Format('DD.MM.YYYY');
				} else {
					this.TariffDatePicker = this.DateHandler.Parse(ForcedDate).Format('YYYY-MM-DD');
					this.SessionDateRange = this.DateHandler.Parse(ForcedDate).Format('DD.MM.YYYY');
				}
				//if (moment(this.MinDate, 'YYYY-MM-DD') > forcedate) forcedate = moment(this.MinDate, 'YYYY-MM-DD');
			} else {
				console.error('Force date is invalid');
			}
		}

		this.HandleMinDate();
		this.HandleForceDate();
		this.HandleDisabledDates();

		// <- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -> \\
		// Scan ALL AVAILABLE dates from MinDate to MaxDate and add allowed dates to an array
		// Regenerate the array. This works even in older versions of ECMA
		this.allowedDatesArray.length = 0;
		let that = this;

		let between = this.DateHandler.DatesBetween([new Date(this.MinDate), new Date(this.MaxDate)], false, true);
		between.forEach(function (d: Date) {
			let date = that.DateHandler.Parse(d);
			if (that.AllowedDates(date.Format('YYYY-MM-DD'))) that.allowedDatesArray.push(date.Format('DD-MM-YYYY'));
		});
		// <- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -> \\

		if (this.allowedDatesArray.length === 0) this.$emit('NotAvailable');
		this.HandleMaxDate();
		this.$emit('dateSet', this.SessionDateRange);
	}

	// If TODAY > MINDATE then MINDATE IS TODAY
	HandleMinDate(): void {
		// If MINDATE exists
		if (this.Settings.Mindate) {
			// Create a Date object from it
			let minDate = new Date(this.Settings.Mindate.split('.').reverse().join('/'));
			// If created Date is valid
			if (this.DateHandler.IsValid(minDate)) {
				// And if today > mindate
				if (this.Today.getTime() > minDate.getTime()) {
					// mindate = today
					this.MinDate = this.DateHandler.Parse(this.Today).Format('YYYY-MM-DD');
				}
			}
		}
	}
	HandleMaxDate(): void {
		if (this.Settings.Maxdate) {
			let maxDate = new Date(this.Settings.Maxdate.split('.').reverse().join('/'));
			if (this.DateHandler.IsValid(maxDate)) {
				// Если сегодня перешло за максимальную дату - отключить календарь
				if (
					this.Today.getDate() > maxDate.getDate() &&
					this.Today.getMonth() >= maxDate.getMonth() &&
					this.Today.getFullYear() >= maxDate.getFullYear()
				) {
					this.allowedDatesArray.length = 0;
					this.$emit('NotAvailable');
				}
			}
			if (this.Settings.Mindate) {
				let minDate = new Date(this.Settings.Mindate.split('.').reverse().join('/'));
				if (minDate.getTime() > maxDate.getTime()) {
					this.allowedDatesArray.length = 0;
					this.$emit('NotAvailable');
				}
			}
		}
	}
	// Set DatePicker date to TODAY or FORCEDATE, depending on which is bigger
	HandleForceDate(): void {
		// If we have forcedate setting in a format of "12.09.2019"
		if (this.Settings.Forcedate) {
			this.ForceDate = new Date(this.Settings.Forcedate.split('.').reverse().join('/'));

			// If "mindate" setting is present
			if (this.Settings.Mindate) {
				// Create a date from it
				let minDate = new Date(this.Settings.Mindate.split('.').reverse().join('/'));
				// If created date is valid
				if (this.DateHandler.IsValid(minDate)) {
					// and mindate is above forcedate - make forcedate equal to mindate
					if (minDate.getTime() > this.ForceDate.getTime()) this.ForceDate = minDate;
				} else {
					console.error('mindate invalid');
				}
			}

			// If "maxdate" setting is present
			if (this.Settings.Maxdate) {
				// Create a date from it
				let maxDate = new Date(this.Settings.Maxdate.split('.').reverse().join('/'));
				// If created date is valid
				if (this.DateHandler.IsValid(maxDate)) {
					// and mindate is above forcedate - make forcedate equal to mindate
					if (maxDate.getTime() < this.ForceDate.getTime()) this.ForceDate = maxDate;
				} else {
					console.error('maxdate invalid');
				}
			}

			if (this.DateHandler.IsValid(this.ForceDate)) {
				// If today is further than forcedate and forcedate is valid
				if (this.Today.getTime() > this.ForceDate.getTime()) {
					// Set current date in date picker to TODAY
					this.TariffDatePicker = this.DateHandler.Parse(this.Today).Format('YYYY-MM-DD');
					this.SessionDateRange = this.DateHandler.Parse(this.Today).Format('DD.MM.YYYY');
				} else {
					// Set current date in date picker to FORCEDATE
					this.TariffDatePicker = this.DateHandler.Parse(this.ForceDate).Format('YYYY-MM-DD');
					this.SessionDateRange = this.DateHandler.Parse(this.ForceDate).Format('DD.MM.YYYY');
				}
			}
		}
	}
	// Hande disabled dates
	HandleDisabledDates(): void {
		if (this.Settings.Disableddates) {
			let disableddates;

			if (!Array.isArray(this.Settings.Disableddates)) {
				disableddates = new Array(this.Settings.Disableddates);
			} else {
				disableddates = this.Settings.Disableddates;
			}

			disableddates.forEach((date: string) => {
				let d = this.DateHandler.Parse(date, 'DD.MM.YYYY').Format('YYYY-MM-DD');
				if (d == this.TariffDatePicker) {
					this.TariffDatePicker = this.DateHandler.Parse(date, 'DD.MM.YYYY').Add('days', 1).Format('YYYY-MM-DD');
					this.SessionDateRange = this.DateHandler.Parse(date, 'DD.MM.YYYY').Add('days', 1).Format('DD.MM.YYYY');
				}
			});
		}
	}

	ClearDates(): void {
		this.TariffDatePicker = this.DateHandler.Parse(new Date(this.MinDate)).Format('YYYY-MM-DD');
		this.SessionDateRange = this.DateHandler.Parse(new Date(this.MinDate)).Format('DD.MM.YYYY');
	}

	SetDateRange(e: any): void {
		this.CloseAvailable = true;
		WpTariffsDatepicker.DateSelected = true;
		this.SessionDateRangeModal = false;
		this.SessionDateRange = this.FormatDate(this.TariffDatePicker);
		this.$emit('dateSet', this.SessionDateRange);
	}

	FormatDate(date: string): string {
		return this.DateHandler.Parse(new Date(date)).Format('DD.MM.YYYY');
	}

	FormatTime(dateString: string): string {
		return this.DateHandler.Parse(new Date(dateString)).Format('HH:mm');
	}
}

export class WpTariffsDatepickerDictionary extends BaseUILocalizationDictionary {
	TextBoxLabel: string = '';
	TitleText: string = '';

	DialogBtnClean: string = '';
	DialogBtnOk: string = '';
	DialogBtnCancel: string = '';
}
