import { injectable, inject } from 'inversify';

import { RestClient } from 'typed-rest-client';
import { ActionResult, ErrorTypes } from './../Common';
import { IdentitySymbols } from './../Identity/symbols';
import GlobalService from './..';
import IPhoneConfirmationProvider from './i-phone-confirmation-provider';
import { UserPhoneConfirmBan } from './';
import PhoneConfirmCode from './phone-confirm-code';
import { AppSettings } from '../Global/app-settings';
import { IContext } from './../Identity';
import INewTelProvider from './i-newtel-provider';

@injectable()
export default class DefaultPhoneConfirmationProvider implements IPhoneConfirmationProvider, INewTelProvider {
    private _settings: AppSettings;
    private _context: IContext;

    constructor(@inject(IdentitySymbols.Context) context: IContext) {
        this._context = context;
        this._settings = GlobalService.GetSettings();
    }

    async GetCurrentBanAsync(): Promise<ActionResult<UserPhoneConfirmBan>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<UserPhoneConfirmBan>>(`/account/api/phoneconfirmation/currentban`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData<UserPhoneConfirmBan>(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<UserPhoneConfirmBan>(response.result?.Data);
    }

    async GetCurrentCodeAsync(): Promise<ActionResult<PhoneConfirmCode>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<PhoneConfirmCode>>(`/account/api/phoneconfirmation/currentcode/?orgId=${this._settings.CurrentSite?.OrganizationId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<PhoneConfirmCode>(response.result?.Data);
    }

    async SendCodeAsync(phone: string): Promise<ActionResult<PhoneConfirmCode>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.create<AjaxResult<PhoneConfirmCode>>(`/account/api/phoneconfirmation/SendCode`, { phone: phone, orgId: this._settings.CurrentSite?.OrganizationId });

        if (response.result != null && response.result.Result != 0) {
            if (response.result.Result == 8) {
                return ActionResult.Failed(ErrorTypes.InvalidData, response.result.Message);
            }

            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);
        }
        return ActionResult.SuccessData<PhoneConfirmCode>(response.result?.Data);
    }

    async ConfirmCodeAsync(code: string): Promise<ActionResult<PhoneConfirmCode>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.create<AjaxResult<PhoneConfirmCode>>(`/account/api/phoneconfirmation/confirmcode`, { code: code });

        if (response.result != null && response.result.Result != 0)
            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

        return ActionResult.SuccessData<PhoneConfirmCode>(response.result?.Data);
    }

    async CodeLeftTimeAsync(codeId: number): Promise<ActionResult<number>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<number>>(`/account/api/phoneconfirmation/codelefttime/${codeId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

        return ActionResult.SuccessData<number>(response.result?.Data);
    }

    async CancelCodeAsync(codeId: number): Promise<ActionResult> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.create <AjaxResult<any>>(`/account/api/phoneconfirmation/cancelcode`, { Id: codeId });

        if (response.result != null && response.result.Result != 0)
            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

        return ActionResult.SuccessData<number>(response.result?.Data);
    }

    async GetStateAsync(): Promise<ActionResult> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "session-id": this._settings.SessionId,
                "moduleId": this._settings.SiteId,
                "wp.language": this._settings.Locale
            }
        });

        let response = await client.create<AjaxResult<any>>(`/account/api/phoneconfirmation/newtel/state`, {});

        if (response.result != null && response.result.Result != 0)
            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

        return ActionResult.SuccessData();
    }
}


class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}