import Vue from 'vue'; 
import Router from 'vue-router'; 

import { WpNewsArticle, WpNews, WpOrders, WpOrder, WpClientsCards } from 'web-platform-vuetify';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: []
});
