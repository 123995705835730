import { injectable, inject } from 'inversify';

import { Order, OrderDetails, OrderDetailItem, OrdersFilter } from './../Order';
import { RestClient } from 'typed-rest-client';
import { FiltringResult, ActionResult, ErrorTypes } from './../Common';
import { IdentitySymbols, IContext } from './../Identity';
import GlobalService, { IDateHandler, AppSettings } from './../';
import ILuzhOrderProvider from './i-luzh-order-provider';
import { DateHandlerSymbols } from '../Utility';
import { OrderStatus } from '../Sale';

@injectable()
export default class LuzhnikiOrderProvider implements ILuzhOrderProvider {
    /* Friendly reminder
     * .get() = GET
     * .create() = POST
     * .replace() = PUT
     */
    protected _settings: AppSettings;
    protected _dateHandler: IDateHandler;
    protected _context: IContext;
    constructor(@inject(IdentitySymbols.Context) context: IContext,
        @inject(DateHandlerSymbols.DateHandlerService) dateHandler: IDateHandler) {
        this._context = context;
        this._dateHandler = dateHandler;
        this._settings = GlobalService.GetSettings();
    }

    async GetOrderListAsync(filter: OrdersFilter): Promise<ActionResult<FiltringResult<Order>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<FiltringResult<Order>>>(`/account/api/orders/`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData<FiltringResult<Order>>(ErrorTypes.InternalError, new FiltringResult<Order>());

        return ActionResult.SuccessData<FiltringResult<Order>>(response.result?.Data);
    }

    async GetOrderListByTNodAsync(tnodId: number): Promise<ActionResult<FiltringResult<Order>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<Array<any>>(`/luzhniki/orders/?tnodid=${tnodId}`);

        if (response.result == null)
            return ActionResult.FailedData<FiltringResult<Order>>(ErrorTypes.InternalError);

        let result = new FiltringResult<Order>();

        if (response.result != null) {
            response.result.forEach((x: any) => {
                let order = new Order();

                order.Id = x.Id;
                order.Date = this._dateHandler.ParseToDate(x.SaleDate, 'DD.MM.YYYY');
                order.Status = x.Status == "Refunded" ? OrderStatus.Refunded : OrderStatus.Paid;
                order.Sum = x.Sum != null ? Number.parseFloat(x.Sum) : 0;
                order.RegistredDate = this._dateHandler.Parse(x.SaleDate, 'DD.MM.YYYY').Format("YYYY-MM-DD") + "T" + x.SaleTime;

                result.Entities.push(order);
            });
        }

        return ActionResult.SuccessData<FiltringResult<Order>>(result);
    }
    async GetOrderAsync(id: number): Promise<ActionResult<OrderDetails>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<any>(`/luzhniki/orders/details/?id=${id}`);

        if (response.result == null)
            return ActionResult.FailedData<OrderDetails>(ErrorTypes.InternalError);

        let bonusSum = 0;
        let result = new OrderDetails();
        let items = new Array<OrderDetailItem>();
        if (response.result.Content != null) {
            result.Id = response.result.Content.Id;
            result.Code = response.result.Content.Id;
            var d = response.result.Content.SaleDatesplit('.');
            result.RegistredDate = `${d[2]}-${d[1]}-${d[0]}T${response.result.Content.SaleTime || ""}`;
            result.Transactions = [];
            result.History = [];
            result.Status = response.result.Content.Status == "Refunded" ? OrderStatus.Refunded : OrderStatus.Paid;
            result.Sum = response.result.Content.Sum;
            result.Discount = response.result.Content.Discount;
            result.SaleDate = response.result.Content.SaleDate;
            result.SaleTime = response.result.Content.SaleTime || "";

            if (response.result.Content.Items != null && response.result.Content.Items.Item != null) {
                response.result.Content.Items.Item.forEach((x: any) => {
                    let item = new OrderDetailItem();

                    item.Id = x.Id;
                    item.Code = x.Id;
                    item.Info = x.ServiceName;
                    item.Price = x.Price;
                    item.Barcode = x.Barcode;
                    item.Discount = x.Discount;
                    item.Sum = x.Price;
                    item.BonusSum = Number.parseFloat(x.BonusSum);
                    item.HaveTicket = x.Barcode && x.Barcode != "";
                    
                    bonusSum += item.BonusSum;
                    items.push(item);
                });
            }

            result.BonusSum = bonusSum;
            result.Items = items;
        }

        return ActionResult.SuccessData<OrderDetails>(result);
    }

    protected RefreshClient(): RestClient {
        return new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId": this._settings.SiteId
            }
        });
    }
    // ����� ������ - http://redmine.isd.su/issues/6922
    async UpdateHeight(height: number): Promise<ActionResult> {
        let client: RestClient = this.RefreshClient();
        let response = await client.replace<AjaxResult<any>>('/luzhniki/person', { height: height });
        if (response != null && response.statusCode == 200) return ActionResult.SuccessData(response.result);
        return ActionResult.Failed(ErrorTypes.InternalError);
    };
    async GetData(): Promise<ActionResult> {
        try {
            let client: RestClient = this.RefreshClient();
            let response = await client.get<AjaxResult<any>>('/luzhniki/person');
            if (response != null && response.statusCode == 200) return ActionResult.SuccessData(response.result);
        } catch (e) {
            console.error(e);
        }
        return ActionResult.Failed(ErrorTypes.InternalError, 'Couldn\'t fetch personal data. Probably a server error...');
    }
    async GetBio(activityType: number, dateStart: string, dateEnd: string): Promise<ActionResult> {
        let client: RestClient = this.RefreshClient();
        let response = await client.get<any>(`/luzhniki/person/bio?type=${activityType}&datestart=${dateStart}&dateEnd=${dateEnd}`);
        if (response != null && response.statusCode == 200) return ActionResult.SuccessData(response.result);
        return ActionResult.Failed(ErrorTypes.InternalError);
    }

    async PublishVK(title: string, value: string, type: string, fileType: string): Promise<ActionResult> {
        try {
            let client: RestClient = this.RefreshClient();
            let response = await client.replace<AjaxResult<any>>('/luzhniki/person/vk/publish', { title: title, type: type, value: value, fileType: fileType });
            if (response != null && response.statusCode == 200) return ActionResult.SuccessData(response.result);
        } catch (e) {
            console.error(e);
            return ActionResult.Failed(ErrorTypes.InternalError);
        }

        return ActionResult.Success();
    }
}

class AjaxResult<T> {
    Result: string | number = '';
    Message: string = '';
    Content?: T;
    Data?: T;
}