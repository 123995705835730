export enum PaymentSystemType {
    Unknow = 0,
    Payu = 1,
    Yandex = 2,
    Sberbank = 3,
    RfiBank = 4,
    RosEuroBank = 5,
    Gazprombank = 6,
    SovkomBank = 7,
    Kazkombank = 8,
    Test = 9,
    AlfaBank = 10,
    PayOnline = 11,
    ApiPayment = 12,
    SbpSberbank = 13,
    Sbp = 14,
    Account = 15
}