import './wp-payconfirm.scss';

import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	OrderSale,
	ISaleService,
	SaleSymbols,
	OrderItem,
	DiscountType,
	OrderDiscount,
	ILoyaltyProvider,
	LoyaltySymbols,
	AppSettings,
	IContext,
	IdentitySymbols,
	IdentityChangedEventArgs,
	LockOrderInfo,
	LoyaltyProgram,
	DiscountProgram,
	IsdUserLoyalty,
	IMetricsService,
	MetricsSymbols,
	WpCorePrefixes,
	PaymentSystemType,
	SaleTermsType,
	BaseFilter,
	ICmsService,
	CmsSymbols,
	SiteAddtionalSettings,
	TariffSeance,
} from 'web-platform-core-ui';
import { Watch, Ref } from 'vue-property-decorator';
import { MetricsEvents } from '../../metrics-events-symbols';
import { DefaultProps } from 'vue/types/options';
import BaseUILocalizationDictionary from './../../Localization/base-ui-localization-terms';
import { EcomPurchase, Brand, EcomProduct, EcomOrder } from 'web-platform-core-ui/src/Metrics/i-ecom-data';
import { EcomActionField } from 'web-platform-core-ui/src/Metrics/i-ecom-data';

export const wpPayConfirmProps = Vue.extend({
	props: {
		OrderId: Number,
	},
});

@Component({
	template: require('./wp-payconfirm.html'),
})
export default class WpPayConfirm extends mixins<WpBaseComponent<WpPayConfirmDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpPayConfirmProps,
	Vue
) {
	protected _context!: IContext;
	protected _service!: ISaleService;
	protected _loyaltyProvider!: ILoyaltyProvider;
	protected _metricsService!: IMetricsService;
	protected _redirectToAuth: boolean = false;
	protected _serviceCMS!: ICmsService;

	static WpEmailConfirmKey: string = 'wp-confirm-email-key';
	static WpPhoneConfirmKey: string = 'wp-confirm-phone-key';

	SelectedSbpPayment: boolean = false;
	SelectedWorldDiscount: boolean = false;
	RulesTrigger: boolean = false;
	RulesConfirmed: boolean = false;
	RulesAgreed: boolean = true;
	HaveAdditionalRules: boolean = false;
	AdditionalRulesConfirmed: boolean = false;
	AdditionalRulesRequired: boolean = false;
	AdditionalRulesText: string = '';
	PromoSheet: boolean = false;
	LoginDrawer: boolean = false;
	OrderLoading: boolean = true;
	OpenModalTrigger: boolean = false;
	Order: OrderSale = new OrderSale();
	PromoCode: string = '';
	EmailIsReadonly: boolean = false;
	PhoneIsReadonly: boolean = false;
	CancelCodeButtonLoading: boolean = false;
	BonusesLoading: boolean = false;
	LoyaltyProgramApplied: boolean = false;
	LoyaltyDialog: boolean = false;
	IsdUserLoyalty?: IsdUserLoyalty;
	SelectedDiscountProgram: number = -1;
	PayButtonLoading: boolean = false;
	NoPromo: boolean = false;
	Email: string = '';
	Phone: string = '+7';
	FormPromocodeDisabled: boolean = false;
	BonusesCharge: number = 0;
	BonusesMax: number = 0;
	HaveLoyaltyProgram: boolean = false;
	LoyaltyProgram?: LoyaltyProgram | null = null;
	Settings!: AppSettings;
	ItemsDiscounts: Array<any> = [];
	LockOrderFormValid: boolean = false;
	ItemsPayments: Array<any> = [];
	SelectedPaymentId: number = 0;
	RulesType: SaleTermsType = SaleTermsType.Default;

	@Watch('CancelCodeButtonLoading')
	@Watch('BonusesLoading')
	SetLoadingOverlay(loadingValue: boolean) {
		this.$emit('SetLoadingOverlay', loadingValue);
	}

	@Ref('LockOrderForm') LockOrderForm!: HTMLFormElement;
	@Ref('LockOrderMobileForm') LockOrderMobileForm!: HTMLFormElement;

	get EmailRules() {
		return [
			(v: string) => !!v || 'Введите e-mail',
			(v: string) =>
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
					v
				) || this.Terms.EnterEmail,
		];
	}

	get PhoneRules() {
		return [(v: string) => /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/.test(v) || this.Terms.EnterPhone];
	}

	PhoneChange(phone: string): void {
		if (phone.length < 2) {
			this.$nextTick(() => {
				this.Phone = '+7' + phone;
			});
		}
	}

	PhoneFocus() {
		if (this.Phone === '') {
			this.Phone = '+7(';
		}
	}
	LockOrderFormSubmit(e: any) {
		e.preventDefault();
	}

	get TotalSum(): number {
		let sum = 0;
		this.Order.Items.forEach((x) => (sum += x.TotalSum));
		return sum;
	}

	get TotalDiscount(): number {
		let sum = 0;
		this.Order.Items.forEach((x) => (sum += x.Discount));
		return sum;
	}

	get OrderSum(): number {
		let sum = 0;
		this.Order.Items.forEach((x) => (sum += x.Price));
		return sum;
	}

	get Bundles(): Array<any> {
		let allBundles = this.Order.Items.filter((x) => x.BundleId !== null);
		let newBundles: any = {};
		allBundles.forEach((element) => {
			let bundleId = element.BundleId;

			if (bundleId in newBundles) {
				newBundles[bundleId].push(element);
			} else {
				newBundles[bundleId] = [];
				newBundles[bundleId].push(element);
			}
		});
		return newBundles;
	}

	get Items(): Array<OrderItem> {
		return this.Order.Items.filter((x) => x.BundleId == null);
	}

	get PromoDiscount(): OrderDiscount | undefined {
		return this.Order.DiscountsList.find((x) => x.DiscountType == DiscountType.Code);
	}

	created() {
		this._service = this.GlobalService.Get<ISaleService>(SaleSymbols.SaleService);
		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
		this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
		this._serviceCMS = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);
	}

	async mounted() {
		this.OrderLoading = true;
		if (this.$vuetify.breakpoint.smAndUp) this.LoginDrawer = true;

		this._context.IdentityChanged.Subscribe(this.IdentityChangedEventHandler);
		this.Settings = GlobalService.GetSettings();
		this.NoPromo = this.Settings.NoPromo;
		this.RulesType = this.Settings.SaleTermsType;

		if (this.Settings.SaleTermsType == SaleTermsType.Default) this.RulesConfirmed = true;

		let filter = new BaseFilter();
		filter.PlatformId = this.Settings.SiteId;

		let siteSettings = await this._serviceCMS.GetEntitiesAsync<SiteAddtionalSettings>(WpCorePrefixes.SiteAdditionalSettings, filter, '');

		if (siteSettings.Success && siteSettings.Data != null && siteSettings.Data.Entities != null && siteSettings.Data.Entities.length > 0) {
			let additionalSiteSettings = siteSettings.Data.Entities[0];
			this.HaveAdditionalRules = additionalSiteSettings.AddtionalTerms != null && additionalSiteSettings.AddtionalTerms !== '';
			this.AdditionalRulesText = additionalSiteSettings.AddtionalTerms ?? '';
			this.AdditionalRulesRequired =
				this.HaveAdditionalRules &&
				additionalSiteSettings.AddtionalTermsRequired != null &&
				additionalSiteSettings.AddtionalTermsRequired == 1;
		}

		try {
			let email = localStorage.getItem(WpPayConfirm.WpEmailConfirmKey);
			if (email != null) this.Email = email;
			let phone = localStorage.getItem(WpPayConfirm.WpPhoneConfirmKey);
			if (phone != null) this.Phone = phone;
		} catch (error) {
			console.error(error);
		}

		await this.GetOrder();

		this.OrderLoading = false;

		window.addEventListener('resize', this.DialogAndBottomSheetFix, { passive: true });
	}

	get canRemove() {
		return this.Order.Items.length > this.Settings.CartMinItems;
	}

	beforeDestroy() {
		if (typeof window === 'undefined') return;
		window.removeEventListener('resize', this.DialogAndBottomSheetFix);
	}

	DecrementBonuses(): void {
		this.BonusesCharge--;
	}

	IncrementBonuses(): void {
		this.BonusesCharge++;
	}

	public RulesConfirm(): void {		
		this.RulesConfirmed = true;
		if (this.$vuetify.breakpoint.xs) {
			this.PayOrderMobileButton();
		} else {
			this.PayOrderDefaultButton();
		}
	}

	public RulesDecline(): void {
		if (this.Settings.SaleTermsType != SaleTermsType.Default) this.RulesConfirmed = false;
		this.RulesTrigger = false;
	}

	EmailChanged(d: any): void {
		this.$nextTick(() => {
			this.Email = this.Email.split(' ').join('').trim();
		});
	}

	protected async IdentityChangedEventHandler(sender: any, e: IdentityChangedEventArgs): Promise<void> {
		await this._service.SetAuthorizedUserToOrderAsync(this.OrderId);

		this._metricsService.RegistrateEvent(MetricsEvents.OrderSetUser, this.OrderId);

		if (this._redirectToAuth) {
			this._redirectToAuth = false;
			await this.LoyaltyProgramRegistration();
		}
	}

	async PayOrderDefaultButton() {		
		if (!this.LockOrderForm.validate()) {
			let container = this.$el.querySelector('.v-navigation-drawer__content');
			if (container != null) container.scrollTop = 0;
			return;
		}

		await this.LockOrder();
	}

	async PayOrderMobileButton() {
		if (!this.LockOrderMobileForm.validate()) {
			let container = document.getElementById('mobile-bottom-sheet');
			if (container != null) container.scrollTop = 0;
			return;
		}

		await this.LockOrder();
	}

	async LockOrder(): Promise<void> {
		this.PayButtonLoading = true;
		this.SetLoadingOverlay(true);
		let lockOrderInfo = new LockOrderInfo();

		lockOrderInfo.Id = this.OrderId;
		lockOrderInfo.Email = this.Email.trim();
		lockOrderInfo.UserPhone = this.Phone.trim();
		lockOrderInfo.Comments =
			this.Settings.OrderComment == null || this.Settings.OrderComment == '' ? this.Email.trim() : this.Settings.OrderComment;
		lockOrderInfo.PaymentId = this.SelectedPaymentId;
		let paymentSystem = this.ItemsPayments.find((x) => x.id == this.SelectedPaymentId);
		lockOrderInfo.PaymentType = paymentSystem != null && paymentSystem != undefined ? paymentSystem.type : PaymentSystemType.Unknow;

		let result: EcomOrder = new EcomOrder();      
        result.purchase = new EcomPurchase();
		result.purchase.actionField = new EcomActionField();
        result.purchase.actionField.id = this.Order.Code.toString();

        let coupon = '';
        if (this.Order.DiscountsList.length !== 0) {
            let promocode = this.Order.DiscountsList.find((x) => x.DiscountType == DiscountType.Code);
            if (promocode != null) coupon = promocode.Name;
        }

        this.GetOrderList(result.purchase, coupon);
		let tmpEcomData: any = {};
		tmpEcomData.GetEcomData = (eventId: Symbol): EcomOrder | null =>{
			return result.purchase!.products!.length > 0 ? result : null;
		}

		if (this.LoyaltyProgramApplied) {
			lockOrderInfo.UserFirstName = this.IsdUserLoyalty!.FirstName;
			lockOrderInfo.UserLastName = this.IsdUserLoyalty!.LastName;
			lockOrderInfo.UserSecondName = this.IsdUserLoyalty!.SecondName;

			if (this.BonusesCharge != 0) {
				this._metricsService.RegistrateEvent(MetricsEvents.WidgetToPayAndChargeBonus, this.OrderId, tmpEcomData);
				let chargeBonusesResult = await this._service.ChargeBonusesAsync(this.OrderId, this.BonusesCharge);
				if (!chargeBonusesResult.Success) {
					this._notificationService.Error(
						'Ошибка',
						chargeBonusesResult.ErrorMessage ? chargeBonusesResult.ErrorMessage : this.Terms.InternalError
					);
					return;
				}
			}
		}

		try {
			localStorage.setItem(WpPayConfirm.WpEmailConfirmKey, this.Email);
			localStorage.setItem(WpPayConfirm.WpPhoneConfirmKey, this.Phone);
		} catch (error) {
			console.error(error);
		}
		
		
		this._metricsService.RegistrateEvent(MetricsEvents.WidgetToPay, this.OrderId, tmpEcomData);
		this.$emit('LockOrder', lockOrderInfo);
	}	

    GetOrderList(tmpList: EcomPurchase, coupon: string) {
		this.Order.Items.filter(x=>x.AdditionalInfo != null && 
            (x.AdditionalInfo.Type == 3 ||
            x.AdditionalInfo.Type == 4 ||
            x.AdditionalInfo.Type == 5 ||
            x.AdditionalInfo.Type == 6 ||
            x.AdditionalInfo.Type == 11 ||
            x.AdditionalInfo.Type == 1))
            .forEach(item => {
                let senace = item.AdditionalInfo.TariffSeance != null ? 
                {
                    Disabled: false,   
                    SeanceId: item.AdditionalInfo.TariffSeanceId,
                    SeanceValue: item.AdditionalInfo.TariffSeance 
                } as TariffSeance : undefined;

                tmpList.products.push({
                    id: item.AdditionalInfo.TariffCode,
                    name: item.ProductName,
                    list: item.AdditionalInfo.GroupName,
                    brand: Brand.ISDS,
                    coupon: coupon,
                    discount: item.Discount,
                    quantity: 1,
                    variant: EcomProduct.GetProductVariant(senace, item.AdditionalInfo.OptionName)
                })
		});
	}

	async ChargeBonuses(): Promise<void> {
		this.BonusesLoading = true;

		let ChargeBonusesResult = await this._service.ChargeBonusesAsync(this.OrderId, this.BonusesCharge);

		if (ChargeBonusesResult.Success) {
			this.Order = ChargeBonusesResult.Data;
			if (this.Order.DiscountsList.length !== 0) {
				let BonusesByDiscountType = this.Order.DiscountsList.find((x) => x.DiscountType == 2);
				if (BonusesByDiscountType !== undefined) {
					this.BonusesCharge = BonusesByDiscountType?.Sum;
				}
			}
		}

		this.BonusesLoading = false;
	}

	async ItemRemove(item: OrderItem) {
		let removeResult = await this._service.RemoveItemAsync(item.Id);

		this._metricsService.RegistrateEvent(MetricsEvents.OrderRemoveItem, this.OrderId);

		if (removeResult.Success) {
			if (item.BundleId != null) {
				this.Order.Items = this.Order.Items.filter((x) => x.BundleId != item.BundleId);
			} else {
				this.Order.Items = this.Order.Items.filter((x) => x.Id != item.Id);
			}

			if (this.LoyaltyProgramApplied && this.Order.Items.length != 0) {
				this.BonusesCharge = 0;
				let BonusesResult = await this._service.GetBalanceAsync(this.OrderId);
				if (BonusesResult.Success && BonusesResult.Data.AvailableBonuses > 0) {
					this.BonusesMax = BonusesResult.Data.AvailableBonuses;
				}
			}
		} else {
			this._notificationService.Error('Ошибка', removeResult.ErrorMessage ? removeResult.ErrorMessage : this.Terms.InternalError);
		}

		if (this.Order.Items.length == 0) {
			this.Order = new OrderSale();
			this.$emit('RemovedAllItems');
		}
	}

	async ApplyCode() {
		// Disable promocode form
		this.FormPromocodeDisabled = true;

		try {
			let result = await this._service.ApplyCodeToOrderAsync(this.Order.Id, this.PromoCode.toUpperCase());
			if (!result.Success || result.Data == null) {
				this._notificationService.Error('', this.Terms.CodeIncorrect);
			} else {
				if (this.LoyaltyProgramApplied) {
					this.BonusesCharge = 0;
					let BonusesResult = await this._service.GetBalanceAsync(this.OrderId);
					if (BonusesResult.Success && BonusesResult.Data.AvailableBonuses > 0) {
						this.BonusesMax = BonusesResult.Data.AvailableBonuses;
					}
				}

				this.Order = result.Data;
				this.SelectedDiscountProgram = -1;

				this._metricsService.RegistrateEvent(MetricsEvents.OrderSetPromo, this.OrderId);
			}
		} catch (error) {
			console.error(error);
		} finally {
			this.FormPromocodeDisabled = false;
		}
	}

	async ChangeDiscount() {
		this.SetLoadingOverlay(true);
		let result = await this._service.ChangeDiscountAsync(this.Order.Id, this.SelectedDiscountProgram);

		if (!result.Success || result.Data == null) {
			this._notificationService.Error('', result.ErrorMessage ? result.ErrorMessage : this.Terms.InternalError);
		} else {
			if (this.SelectedDiscountProgram == 0) {
				this._metricsService.RegistrateEvent(MetricsEvents.OrderResetDiscount, this.OrderId);

				let clearResult = await this._service.ClearOrderCodesAsync(this.Order.Id);
				if (clearResult.Success && clearResult.Data != null) {
					result.Data = clearResult.Data;
				}
			} else {
				this._metricsService.RegistrateEvent(MetricsEvents.OrderSetDiscount, this.SelectedDiscountProgram);
			}

			this.Order = result.Data;
			if (this.LoyaltyProgramApplied) {
				this.BonusesCharge = 0;
				let BonusesResult = await this._service.GetBalanceAsync(this.OrderId);
				if (BonusesResult.Success && BonusesResult.Data.AvailableBonuses > 0) {
					this.BonusesMax = BonusesResult.Data.AvailableBonuses;
				}
			}
			this.PromoCode = '';

			let discount = this.Order.DiscountsList.find((x) => x.DiscountType == DiscountType.DiscountWithPaymentCondition);
			if (discount != null) {
				this.SelectedWorldDiscount = true;
				if (this.SelectedSbpPayment) {
					this._notificationService.Error('Ошибка', 'К платёжной системе СБП неприменима данная скидка');
				}
			} else {
				this.SelectedWorldDiscount = false;
			}
		}
		this.SetLoadingOverlay(false);
	}

	async ClearCodes() {
		this.CancelCodeButtonLoading = true;
		let result = await this._service.ClearOrderCodesAsync(this.Order.Id);

		if (!result.Success || result.Data == null) {
			this._notificationService.Error('', result.ErrorMessage ? result.ErrorMessage : this.Terms.InternalError);
		} else {
			this.Order = result.Data;
			this.PromoCode = '';

			this._metricsService.RegistrateEvent(MetricsEvents.OrderRemovePromo, this.OrderId);

			if (this.LoyaltyProgramApplied) {
				this.BonusesCharge = 0;
				let BonusesResult = await this._service.GetBalanceAsync(this.OrderId);
				if (BonusesResult.Success && BonusesResult.Data.AvailableBonuses > 0) {
					this.BonusesMax = BonusesResult.Data.AvailableBonuses;
				}
			}

			if (this.Order.DiscountProgramId != null) {
				this.SelectedDiscountProgram = this.Order.DiscountProgramId;
			} else if (!this.LoyaltyProgramApplied) {
				let defaultDiscount = this.ItemsDiscounts.find((x) => x.id == 0);
				if (defaultDiscount != null) {
					this.SelectedDiscountProgram = 0;
					await this.ChangeDiscount();
				}
			}
		}
		this.CancelCodeButtonLoading = false;
	}

	CodeSendResult(orderSale: OrderSale) {
		this.Order = orderSale;
	}

	protected async GetOrder() {
		this.SetLoadingOverlay(true);
		this.PromoCode = '';

		let order = await this._service.GetOrderAsync(this.OrderId);

		if (!order.Success || order.Data === undefined || order.Data === null) {
			this._notificationService.Error('Ошибка', order.ErrorMessage ? order.ErrorMessage : this.Terms.InternalError);
		} else {
			this.Order = order.Data;
			if (this.Order.DiscountsList.length !== 0) {
				let BonusesByDiscountType = this.Order.DiscountsList.find((x) => x.DiscountType == 2);
				if (BonusesByDiscountType !== undefined) this.BonusesCharge = BonusesByDiscountType?.Sum;
			}
		}

		this.Settings = GlobalService.GetSettings<AppSettings>();

		let LoyaltyList = await this._service.GetLoyaltyListAsync();

		if (LoyaltyList.Success && LoyaltyList.Data != undefined && LoyaltyList.Data.length > 0) {
			this.HaveLoyaltyProgram = true;
			await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.LoyaltyProgram, LoyaltyList.Data);

			if (this.Settings.LoyaltyId !== 0) {
				this.LoyaltyProgram = LoyaltyList.Data.find((x) => x.Id == this.Settings.LoyaltyId);
			}

			if (this.LoyaltyProgram == null) {
				this.LoyaltyProgram = LoyaltyList.Data[0];
				this.Settings.LoyaltyId = this.LoyaltyProgram.Id;
			}

			if (this._context.CurrentIdentity.IsAuthentificated) {
				let loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
				let result = await loyaltyProvider.GetIsdUserLoyaltyAsync();

				if (result.Success && result.Data != null) {
					this.IsdUserLoyalty = result.Data;
					await this.ApplyLoyalty(this.LoyaltyProgram.Id);
				}
			}
		}

		if (this._context.CurrentIdentity.IsAuthentificated) {
			if (this._context.CurrentIdentity.Username != null && this._context.CurrentIdentity.Username != '') {
				this.EmailIsReadonly = true;
				this.Email = this._context.CurrentIdentity.Username;
			}
			if (this._context.CurrentIdentity.Phone != null && this._context.CurrentIdentity.Phone != '') {
				this.PhoneIsReadonly = true;
				this.Phone = this._context.CurrentIdentity.Phone;
			}
		}

		await this.SetDiscountsList();
		await this.SetPaymentsList();
		this.SetLoadingOverlay(false);
	}

	async RegisteredLoyalty(success: boolean) {
		if (success) {
			this.LoyaltyDialog = false;
			await this.LoyaltyProgramRegistration();
		}
	}

	async LoyaltyProgramRegistration(e?: any) {
		if (e != null) e.preventDefault();

		this._metricsService.RegistrateEvent(MetricsEvents.OrderClickSignIn, this.OrderId);

		if (this.LoyaltyProgram == null) return;

		if (!this._context.CurrentIdentity.IsAuthentificated) {
			this._redirectToAuth = true;
			this._context.SignIn();
			return;
		}

		this.SetLoadingOverlay(true);
		let loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
		let result = await loyaltyProvider.GetIsdUserLoyaltyAsync();

		if (result.Success) {
			if (result.Data == null) {
				this.LoyaltyDialog = true;
				this.SetLoadingOverlay(false);
				return;
			}

			this.IsdUserLoyalty = result.Data;
			this.ApplyLoyalty(this.LoyaltyProgram.Id);
			await this.SetDiscountsList();
		} else {
			this._notificationService.Error('', result?.ErrorMessage ?? this.Terms.InternalError);
		}

		if (this._context.CurrentIdentity.Username != null && this._context.CurrentIdentity.Username != '') {
			this.EmailIsReadonly = true;
			this.Email = this._context.CurrentIdentity.Username;
		}

		if (this._context.CurrentIdentity.Phone != null && this._context.CurrentIdentity.Phone != '') {
			this.PhoneIsReadonly = true;
			this.Phone = this._context.CurrentIdentity.Phone;
		}

		this.SetLoadingOverlay(false);
	}

	async ApplyLoyalty(loyaltyId: number): Promise<void> {
		let ApplyLoyaltyResult = await this._service.ApplyLoyaltyAsync(this.OrderId, loyaltyId);
		if (ApplyLoyaltyResult.Success) {
			this._metricsService.RegistrateEvent(MetricsEvents.OrderApplyLoyalty, this.OrderId);

			let BonusesResult = await this._service.GetBalanceAsync(this.OrderId);
			if (BonusesResult.Success && BonusesResult.Data.AvailableBonuses > 0) this.BonusesMax = BonusesResult.Data.AvailableBonuses;

			this.Order = ApplyLoyaltyResult.Data;

			if (this.Order.DiscountsList.length !== 0) {
				let BonusesByDiscountType = this.Order.DiscountsList.find((x) => x.DiscountType == 2);
				if (BonusesByDiscountType !== undefined) this.BonusesCharge = BonusesByDiscountType?.Sum;
			}

			if (this._context.CurrentIdentity.IsAuthentificated) {
				if (this._context.CurrentIdentity.Username != null && this._context.CurrentIdentity.Username != '') {
					this.EmailIsReadonly = true;
					this.Email = this._context.CurrentIdentity.Username;
				}
				if (this._context.CurrentIdentity.Phone != null && this._context.CurrentIdentity.Phone != '') {
					this.PhoneIsReadonly = true;
					this.Phone = this._context.CurrentIdentity.Phone;
				}
			}
			this.LoyaltyProgramApplied = true;
		} else {
			this.LoyaltyProgramApplied = false;
		}
	}

	async SetDiscountsList() {
		this.ItemsDiscounts = [];
		let noConditionsDiscount: DiscountProgram | undefined;
		let discounts = await this._service.GetDiscountProgramsAsync();

		if (discounts.Success && discounts.Data != null && discounts.Data.length > 0) {
			// No discounts available
			await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.DiscountProgram, discounts.Data);

			noConditionsDiscount = discounts.Data.find((x) => x.Conditions == null || x.Conditions.length == 0);

			if (this.LoyaltyProgramApplied == false && noConditionsDiscount != null) {
				let applyDiscountResult = await this._service.ChangeDiscountAsync(this.OrderId, noConditionsDiscount.Id);
				if (applyDiscountResult.Success && applyDiscountResult.Data != null) this.Order = applyDiscountResult.Data;
			}

			if (this.IsdUserLoyalty != null && this.LoyaltyProgram != null) {
				this.ItemsDiscounts.push({
					id: 0,
					icons: [{ type: 'icon', src: 'mdi-gift-outline' }],
					text: this.LoyaltyProgram.Name,
					tooltip: '',
					link: '',
					show: false,
				});
			}

			if (noConditionsDiscount != null) {
				this.ItemsDiscounts.push({
					id: noConditionsDiscount.Id,
					icons: [{ type: 'icon', src: 'mdi-gift-outline' }],
					text: noConditionsDiscount.Name,
					tooltip: noConditionsDiscount.Description,
					link: noConditionsDiscount.RuleLink,
					show: false,
				});
			}

			if (noConditionsDiscount == null && this.IsdUserLoyalty == null) {
				this.ItemsDiscounts.push({
					id: 0,
					icons: [{ type: 'icon', src: 'mdi-cancel' }],
					text: this.Terms.NoDiscount,
					tooltip: '',
					link: '',
					show: false,
				});
			}

			for (let i = 0; i < discounts.Data.length; i++) {
				let x = discounts.Data[i];
				if (noConditionsDiscount != null && noConditionsDiscount.Id == x.Id) continue;

				let icons = [];

				x.Conditions.forEach((condition) => {
					if (condition.DiscountConditionType == 1) {
						if (condition.AvailableCards.some((card: any) => card == '2'))
							icons.push({ type: 'img', src: 'https://static.moipass.ru/img/mir.png' });

						if (condition.AvailableCards.some((card: any) => card == '4'))
							icons.push({ type: 'img', src: 'https://static.moipass.ru/img/visa.png' });

						if (condition.AvailableCards.some((card: any) => card == '5'))
							icons.push({ type: 'img', src: 'https://static.moipass.ru/img/mastercard.png' });
					}
				});

				if (icons.length == 0) icons.push({ type: 'icon', src: 'mdi-sale' });

				this.ItemsDiscounts.push({
					id: x.Id,
					icons: icons,
					text: x.Name,
					tooltip: x.Description,
					link: x.RuleLink,
					show: false,
				});
			}

			if (this.Order.DiscountProgramId != null) {
				this.SelectedDiscountProgram = this.Order.DiscountProgramId;
			} else {
				let defaultDiscount = this.ItemsDiscounts.find((x) => x.id == 0);
				if (defaultDiscount != null) {
					this.SelectedDiscountProgram = 0;
				}
			}
		}
	}

	async SetPaymentsList(): Promise<void> {
		this.ItemsPayments = [];
		let payments = await this._service.GetPaymentsAsync(this.Order.Id);

		if (payments.Success && payments.Data != null && payments.Data.length > 0) {
			for (let i = 0; i < payments.Data.length; i++) {
				let paymentData = {};
				if (payments.Data[i].PaymentType == PaymentSystemType.SbpSberbank) {
					paymentData = {
						id: payments.Data[i].Id,
						icons: { type: 'img', src: 'https://static.moipass.ru/img/sfp.png' },
						text: this.Terms.SbpPayment,
						type: payments.Data[i].PaymentType,
					};
				} else if (payments.Data[i].PaymentType == PaymentSystemType.Sbp) {
					paymentData = {
						id: payments.Data[i].Id,
						icons: { type: 'img', src: 'https://static.moipass.ru/img/sfp.png' },
						text: this.Terms.SbpPayment,
						type: payments.Data[i].PaymentType,
					};
				} else if (payments.Data[i].PaymentType == PaymentSystemType.Account) {
					paymentData = {
						id: payments.Data[i].Id,
						icons: { type: 'img', src: 'https://static.moipass.ru/img/sfp.png' },
						text: this.Terms.Account,
						type: payments.Data[i].PaymentType,
					};
				} else {
					paymentData = {
						id: payments.Data[i].Id,
						icons: { type: 'icon', src: 'mdi-credit-card-outline' },
						text: `${this.Terms.CardPayment} ${this.GetPaymentName(payments.Data[i].PaymentType)}`,
						type: payments.Data[i].PaymentType,
					};
				}

				this.ItemsPayments.push(paymentData);

				if (payments.Data[i].IsBasePayment) this.SelectedPaymentId = payments.Data[i].Id;
			}
		}
	}

	ChangePayment() {
		let payment = this.ItemsPayments.find((x) => x.id == this.SelectedPaymentId);
		if (payment != null && payment != undefined) {
			if (payment.type == PaymentSystemType.Sbp || payment.type == PaymentSystemType.SbpSberbank) {
				this.SelectedSbpPayment = true;
				if (this.SelectedWorldDiscount) {
					this._notificationService.Error('Ошибка', 'К платёжной системе СБП неприменима данная скидка');
				}
			} else {
				this.SelectedSbpPayment = false;
			}
		}
	}

	GetPaymentName(paymentType: PaymentSystemType) {
		switch (paymentType) {
			case PaymentSystemType.AlfaBank:
				return this.Terms.AlfaBank;
			case PaymentSystemType.Gazprombank:
				return this.Terms.Gazprombank;
			case PaymentSystemType.Kazkombank:
				return this.Terms.Kazkombank;
			case PaymentSystemType.PayOnline:
				return this.Terms.PayOnline;
			case PaymentSystemType.Payu:
				return this.Terms.Payu;
			case PaymentSystemType.RfiBank:
				return this.Terms.RfiBank;
			case PaymentSystemType.RosEuroBank:
				return this.Terms.RosEuroBank;
			case PaymentSystemType.Sberbank:
				return this.Terms.Sberbank;
			case PaymentSystemType.SovkomBank:
				return this.Terms.SovkomBank;
			case PaymentSystemType.Test:
				return this.Terms.Test;
			case PaymentSystemType.Yandex:
				return this.Terms.Yandex;
			case PaymentSystemType.Account:
				return this.Terms.Account;
		}

		return '';
	}

	RulesOpen() {
		this.RulesTrigger = true;
		this._metricsService.RegistrateEvent(MetricsEvents.OrderClickAgreements, this.OrderId);
	}

	DialogAndBottomSheetFix() {
		try {
			if (this.$vuetify.breakpoint.smOnly && this.LoginDrawer) {
				if (this.RulesTrigger) {
					setTimeout(() => {
						let bottomSheet = document.getElementById('mobile-bottom-sheet')?.parentElement?.parentElement?.parentElement;
						let rulesDialog = document.getElementsByClassName('wp-rules').item(0)?.parentElement?.parentElement;
						let overlay = document.getElementsByClassName('v-overlay--active').item(0) as HTMLElement;

						if (bottomSheet != null && rulesDialog != null) {
							let bottomSheetZIndex = Number.parseInt(bottomSheet.style.zIndex);
							let rulesDialogZIndex = Number.parseInt(rulesDialog.style.zIndex);

							if (rulesDialogZIndex <= bottomSheetZIndex) {
								rulesDialog.style.zIndex = `${bottomSheetZIndex + 2}`;

								if (overlay != null) overlay.style.zIndex = `${bottomSheetZIndex + 1}`;
							}
						}
					}, 1000);
				}

				if (this.LoyaltyDialog) {
					setTimeout(() => {
						let bottomSheet = document.getElementById('loyalty-dialog')?.parentElement?.parentElement?.parentElement;
						let rulesDialog = document.getElementsByClassName('wp-rules').item(0)?.parentElement?.parentElement;
						let overlay = document.getElementsByClassName('v-overlay--active').item(0) as HTMLElement;

						if (bottomSheet != null && rulesDialog != null) {
							let bottomSheetZIndex = Number.parseInt(bottomSheet.style.zIndex);
							let rulesDialogZIndex = Number.parseInt(rulesDialog.style.zIndex);

							if (rulesDialogZIndex <= bottomSheetZIndex) {
								rulesDialog.style.zIndex = `${bottomSheetZIndex + 2}`;

								if (overlay != null) overlay.style.zIndex = `${bottomSheetZIndex + 1}`;
							}
						}
					}, 1000);
				}
			}
		} catch (e) {
			console.error(e);
		}
	}

	get btnConfirmDisabled(): boolean {
		if (this.RulesType == 'dialog') {
			if (this.RulesConfirmed) return false;
		}
		return (
			!(this.RulesConfirmed && ((this.AdditionalRulesRequired && this.AdditionalRulesConfirmed) || !this.AdditionalRulesRequired)) ||
			(this.SelectedWorldDiscount && this.SelectedSbpPayment)
		);
	}
}

export class WpPayConfirmDictionary extends BaseUILocalizationDictionary {
	UsedPromoCode: string = 'Применен промокод';
	DataRequired: string = 'Необходимо заполнить данные';
	PlacingOrder: string = 'Оформление заказа';
	ToGetDeals: string = 'Чтобы накопить баллы и получить скидку необходимо';
	YouAreMemberOfLoyaltyProgram: string = 'Вы являетесь участником программы лояльности';
	LabelEmail: string = 'Email';
	LabelPhone: string = 'Телефон';
	LabelPromocode: string = 'Промокод';
	EnterEmail: string = 'Введите e-mail';
	EnterPhone: string = 'Введите телефон';
	ChooseDiscount: string = 'Выберите скидку';
	CodeIncorrect: string = 'Неверный код';
	GenericDiscount: string = 'Скидка';
	GenericTotal: string = 'Итого';
	GenericPay: string = 'Оплатить';
	GenericByPressingPay: string = 'Нажимая "Оплатить", Вы соглашаетесь с';
	GenericToRules: string = 'условиями';
	HowManyPointsToSpend: string = 'Сколько баллов вы хотите списать?';
	NoDiscount: string = 'Без скидки';
	GenericPurchaseComment: string = 'Комментарий к заказу';
	ChoosePayment: string = 'Выберите способ оплаты';
	CardPayment: string = 'Оплата картой';
	SbpPayment: string = 'Оплата через СБП';
	NoPointsToSpend: string = 'У вас нет баллов, которые можно использовать в заказе';

	Payu = 'Payu';
	Yandex = 'Яндекс';
	Sberbank = 'Сбербанк';
	RfiBank = 'РФИ банк';
	RosEuroBank = 'РосЕвроБанк';
	Gazprombank = 'Газпромбанк';
	SovkomBank = 'Совкомбанк';
	Kazkombank = 'Казкомерцбанк';
	Test = 'Тестовая';
	AlfaBank = 'Альфа-банк';
	PayOnline = 'Pay онлайн';
	Account = 'Оплата с лицевого счёта';
}
