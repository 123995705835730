





































































import Vue from 'vue';
import Component, { mixins } from "vue-class-component";
import WpBaseComponent from 'web-platform-vuetify';
import GlobalService, {
    IContext, IdentitySymbols, IdentityChangedEventArgs,
    INotificationService, NotificationSymbols, Initializer,
    ILoyaltyProvider, LoyaltySymbols, AppSettingsInitializedEventArgs,
    NotificationEventArgs, BaseFilter, AppSettings, WpCorePrefixes,
    ICmsService, CmsSymbols, WidgetStyle, ISaleService, SaleSymbols
} from 'web-platform-core-ui';



@Component
export default class LKApp extends mixins(Vue, WpBaseComponent) {
    protected _notificationService!: INotificationService;
    protected _loyaltyProvider!: ILoyaltyProvider;
    protected _saleService!: ISaleService;

    IsHaveLoyalty: boolean = false; 
    IsInitializedError: boolean = true;
    IsLoaded: boolean = false;
    NeedAuth: boolean = true;
  
    Dialog: boolean = false;
    UserName: string = '';
    snackbar: boolean = true;
    Context!: IContext;
    Settings!: AppSettings;
    IsOffline: boolean = false;
    IsAuthentificated: boolean = false;
    Widget: boolean = false;
    Font?: string;

    OnlineStatusChange(isOnline: boolean): void {
        this.IsOffline = !isOnline;
    }

    created(): void {
        GlobalService.GetSettings<AppSettings>().OnlyTab = ['service'];

        this._saleService = this.GlobalService.Get<ISaleService>(SaleSymbols.SaleService);
        this.Context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
        this._loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);

        this.Settings = GlobalService.GetSettings();
    };

    async mounted(): Promise<void> {
        this._notificationService.NotificationSended.Subscribe(this.OpenWidgetHandler);

        this.Context.IdentityChanged.Subscribe(async (sender: any, e: IdentityChangedEventArgs) => {
            if (!this.IsLoaded) return;            

            if (this.Context.CurrentIdentity.IsAuthentificated) {
                this.GetLoyalty();
            }else{
                this.NeedAuth = true;
                this.IsAuthentificated = false;
                this.Context.SignIn();
            }
        });

        let initResult = await Initializer.InitializeAsync(this.GlobalService);

        if (!initResult.Success) {
            this.IsInitializedError = true;
            Error(`Initilization error: ${initResult.ErrorMessage}`);
        };

        if (this.Context.CurrentIdentity.IsAuthentificated) {
            this.NeedAuth = false;
            this.IsAuthentificated = true;
            await this.GetLoyalty();               
        } else {
            this.Context.SignIn();
        }

        await this.LoadStylesSettings();
        this.IsInitializedError = false;        
        this.IsLoaded = true;
        removeMainLoadingWrapper();
    };

    SignOut() {
        let context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
        context.SignOut();
        this.NeedAuth = true;
        this.IsHaveLoyalty = false;
    };

    async GetLoyalty(): Promise<void> {
        try {
            let result = await this._loyaltyProvider.GetIsdUserLoyaltyAsync();

            if (result.Success) {
                if (result.Data == null) {
                    this.Dialog = true;
                } else {
                    let open = localStorage.getItem("open_widget");
                    this.Settings.OnlyTab = ['rewrite'];    
                    this.Widget = open != null;
                    localStorage.removeItem("open_widget");

                    this.IsHaveLoyalty = true;
                    this.IsAuthentificated = true;
                };
            } else {
                this._notificationService.Error('', result?.ErrorMessage ?? 'Произошла непредвиденная ошибка');
            };
        } catch {
        };
    };

    async RegisteredLoyalty(success: boolean) {
        if (success) {
            this.Dialog = false;
            this.IsHaveLoyalty = true;

            let loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
            let result = await loyaltyProvider.GetIsdUserLoyaltyAsync();

            if (result.Success && result.Data != null) {
                this.NeedAuth = false;
                this.IsAuthentificated = true;
            }
        };
    };

    OpenWidgetHandler(obj: any, e: NotificationEventArgs) {
        if (e.Data.SystemProcessKey == "OpenWidget") {
            this.Settings.ForceMedia = e.Data.AdditionalData as string;
            this.Widget = true;
        }
    }

    CancelRegistration() {
        let context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
        context.SignOut();
        this.Dialog = false;
        this.NeedAuth = true;
    };

    StepChanged(step: number) {
        if (step == 5) {
            localStorage.setItem("open_widget", "open");
        }
    }

    async LoadStylesSettings() {
        let cmsService = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);
        let filter = new BaseFilter();
        filter.PlatformId = this.Settings.SiteId;
        let widgetStyle = await cmsService.GetEntitiesAsync<WidgetStyle>(WpCorePrefixes.WidgetStyle, filter, '');

        if (widgetStyle.Success && widgetStyle.Data != null && widgetStyle.Data.Entities != null &&
            widgetStyle.Data.Entities.length > 0) {
            if (widgetStyle.Data.Entities[0].Font != null && widgetStyle.Data.Entities[0].Font != '') {
                this.Font = widgetStyle.Data.Entities[0].Font;
                let htmlLink = document.createElement('link')
                htmlLink.rel = 'stylesheet';
                htmlLink.href = `https://fonts.googleapis.com/css?family=${this.Font}`;
                document.head.appendChild(htmlLink);
            };

            if (widgetStyle.Data.Entities[0].Primary != null && widgetStyle.Data.Entities[0].Primary != '')
                this.$vuetify.theme.currentTheme.primary = `#${widgetStyle.Data.Entities[0].Primary}`;
            if (widgetStyle.Data.Entities[0].Secondary != null && widgetStyle.Data.Entities[0].Secondary != '')
                this.$vuetify.theme.currentTheme.secondary = `#${widgetStyle.Data.Entities[0].Secondary}`;
            if (widgetStyle.Data.Entities[0].Accent != null && widgetStyle.Data.Entities[0].Accent != '')
                this.$vuetify.theme.currentTheme.accent = `#${widgetStyle.Data.Entities[0].Accent}`;
            if (widgetStyle.Data.Entities[0].Error != null && widgetStyle.Data.Entities[0].Error != '')
                this.$vuetify.theme.currentTheme.error = `#${widgetStyle.Data.Entities[0].Error}`;
            if (widgetStyle.Data.Entities[0].Info != null && widgetStyle.Data.Entities[0].Info != '')
                this.$vuetify.theme.currentTheme.info = `#${widgetStyle.Data.Entities[0].Info}`;
            if (widgetStyle.Data.Entities[0].Success != null && widgetStyle.Data.Entities[0].Success != '')
                this.$vuetify.theme.currentTheme.success = `#${widgetStyle.Data.Entities[0].Success}`;
            if (widgetStyle.Data.Entities[0].Warning != null && widgetStyle.Data.Entities[0].Warning != '')
                this.$vuetify.theme.currentTheme.warning = `#${widgetStyle.Data.Entities[0].Warning}`;
        };
    };
}
