import { EcomTariffsHelper, TariffItem } from './tariff-item';
import { EcomServicesList } from 'web-platform-core-ui/src/Metrics/i-ecom-data';
import './wp-tariffs.scss';

import Vue, { VueConstructor } from 'vue';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
    ITariffService, TariffSymbols, AppSettings, INotificationService, Cart, Tariff, TariffCartItem, CartItem,
    IMetricsService, MetricsSymbols, BaseSystemLocalizationDictionary, ErrorTypes, ActionResult, WpCorePrefixes,
    ILoyaltyProvider, IContext, IdentitySymbols, LoyaltySymbols
} from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { MetricsEvents } from '../../metrics-events-symbols';
import WpBaseComponent from '../../wp-base-component';
import BaseUILocalizationDictionary from './../../Localization/base-ui-localization-terms';
import { Watch } from 'vue-property-decorator';

export const wpTariffsRewriteProp = Vue.extend({
    props: {
        Cart: Object as Prop<Cart>,
    }
})

@Component({
    template: require("./wp-tariffs-rewrite.html")
})

export default class WpTariffsRewrite extends mixins<WpBaseComponent<WpTariffsRewriteDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpTariffsRewriteProp, Vue) {
    private _tariffService!: ITariffService;
    protected _notificationService!: INotificationService;
    private _price: number = 0;
    private _personName: string = '';
    private _metricsService!: IMetricsService;
    protected _loyaltyProvider!: ILoyaltyProvider;
    protected _context!: IContext;

    Settings?: AppSettings;
    Card: string = '';
    SelectedCard?: any;
    SearchTariffs = false;
    Tariffs: Array<Tariff> = [];
    TariffsStates: TariffItem[] = [];
    CardLoading: boolean = false;
    Message: string = '';
    Date: string = '';
    DatepickerSettings: any = {};
    Datepicker: boolean = false;
    CardItems: Array<any> = [];
    IsSelectedCardValid: boolean = false;
    IsSelectedCardLengthValid: boolean = false;
    SelectedCardInputState: boolean = false;


    StartDatepicker(): void {
        this.DatepickerSettings = this.Settings;
        this.Datepicker = true;
    };

    get PersonName(): string {
        if (this._personName !== null && this._personName !== undefined && this._personName !== '') return `${this.Terms.CardRegisteredOn} ${this._personName}`;
        return '';
    };

    get TariffsLoading(): boolean {
        let res = this.TariffsStates.some(x => x.ItemLoading);
        return res;
    };

    get Price(): number {
        return this._price;
    };

    get Rules(): any {
        return {
            required: this.IsCardValid || this.Terms.UnacceptableCharacters,
            minimum8: this.Card.length >= 8 || this.Terms.CardMinimum8,
        };
    };

    get ItemRules() {
        return {            
            minimum8: this.IsSelectedCardLengthValid || this.Terms.CardMinimum8,
            required: this.IsSelectedCardValid || this.Terms.UnacceptableCharacters
        };
    }

    SelectedCardDataChanged(d: any): void { 
        this.$nextTick(() => {
            this.ValidateSelectedCard();
            if(this.SelectedCard == null) {
                this.SelectedCardInputState = true;
                return;
            }
            
            if(this.SelectedCard?.cardNum != null) {
                this.SelectedCardInputState = false;
                return;
            } 

            this.SelectedCardInputState = true;
            this.SelectedCard = this.SelectedCard.toUpperCase().replace(" ", "");
        })
    };

    SelectedCardDataInput(d: any): void{
        this.$nextTick(() => {
            if(!this.SelectedCardInputState) return;
            this.SelectedCard = d;
            this.ValidateSelectedCard();
            (this.$refs.form as any).validate();
        }) 
    }

    CardDataChanged(d: any): void {
        this.$nextTick(() => {
            // Убираем русский алфавит и латиницу после буквы G, как и пробелы
            //this.Card = this.Card.toUpperCase().replace(/[А-ЯG-Z]/gi, " ").replace(" ", "");
            // Убираем просто пробелы
            this.Card = this.Card.toUpperCase().replace(" ", "");
        });
    };

    ValidateSelectedCard() {
        if(this.SelectedCard?.cardNum != null){
            this.IsSelectedCardValid = true;
            this.IsSelectedCardLengthValid = true;
            return;
        }

        this.IsSelectedCardLengthValid = this.SelectedCard != null && this.SelectedCard.length >= 8;
        this.IsSelectedCardValid = this.SelectedCard != null && this.SelectedCard !== '' && /^[a-fA-F0-9]+$/.test(this.SelectedCard) ;
    };

    get IsCardValid(): boolean {
        return this.Card !== '' && /^[a-fA-F0-9]+$/.test(this.Card);
    };

    AddToCart(cartItem: CartItem): void {
        let result = this.Cart.AddItem(cartItem) as ActionResult;

        this._metricsService.RegistrateEvent(MetricsEvents.RewriteCartAdd, cartItem.MetricsId, cartItem);

        if (!result.Success) {
            if (result.ErrorType == ErrorTypes.CartItemsConflict)
                this._notificationService.Error(this.Terms.GenericError, this.Terms.CardIsAlredyInCart);

            this._notificationService.Error(this.Terms.GenericError, result.ErrorMessage ? result.ErrorMessage : this.Terms.InternalError);
        };
    };

    ChangeCard() {
        this.Tariffs = [];
        this.SearchTariffs = false;
    };

    ClearCard() {
        this._metricsService.RegistrateEvent(MetricsEvents.RewriteClearCard);

        this.Tariffs = [];
        this.SearchTariffs = false;
        this.Card = '';
        this.SelectedCard = undefined;
        this.ValidateSelectedCard();
    };

    async CheckCard() {
        this._metricsService.RegistrateEvent(MetricsEvents.RewriteCheckCard);

        this.SearchTariffs = true;
        this.GetTariffsAsync();
    };

    async mounted() {
        this.Settings = GlobalService.GetSettings<AppSettings>();
        this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
        this._tariffService = this.GlobalService.Get<ITariffService>(TariffSymbols.TariffService);        
        this._loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
        this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);

        if(this._context.CurrentIdentity.IsAuthentificated){
            let cards = await this._loyaltyProvider.GetCards();

            if(cards != null && cards.length > 0){
                cards.forEach((x: any) => {
                    this.CardItems.push({ cardName: x.Name == null || x.Name == "" ? x.Data.MediaNum : x.Name , cardNum: x.Data.MediaNum, cardType: x.Data.MediaType })
                })

            }
        }

        this.SelectedCard =  this.CardItems.find(x=> x.cardNum.toUpperCase() == this.Settings?.ForceMedia?.toUpperCase());
        if(this.Card == null && this.Settings?.ForceMedia != null){
            this.SelectedCard = { cardName: this.Settings?.ForceMedia?.toUpperCase(), cardNum: this.Settings?.ForceMedia?.toUpperCase() }
            this.CardItems.push(this.SelectedCard);           
        }
        this.ValidateSelectedCard();
        this.StartDatepicker();
    };

    async GetTariffsAsync(): Promise<any> {
        this.CardLoading = true;
        if (this.CardItems.length > 0)
            this.Card = this.SelectedCard?.cardNum != null ? this.SelectedCard?.cardNum : this.SelectedCard;
        this.Message = '';
        let result = await this._tariffService.GetTariffsAsync();
        let acceptableTariffs = await this._tariffService.GetCardAcceptableTariffsAsync(this.Card, this.SelectedCard?.cardType);

        this.TariffsStates.splice(0);
        if (acceptableTariffs.Success) {

            /*if(this.SelectedCard?.cardNum == null){
                let r = await this._loyaltyProvider.BindCard(this.Card, "", "");
                if(r.Success) this._notificationService.SendSystemNotification("NewUserCardAdded");
            }*/

            if (result.Data != undefined && acceptableTariffs.Data != undefined && result.Data.length > 0 && acceptableTariffs.Data.Tariffs.length > 0) {
                await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.Tariff, result.Data);

                result.Data.filter(x => x.IsRewritable).forEach(x => {
                    if (acceptableTariffs.Data?.Tariffs.some(y => y.Id === x.Id)) {
                        this.Tariffs.push(x);
                        let tariffItem = new TariffItem();
                        tariffItem.Id = x.Id.toString();
                        tariffItem.ItemLoading = true;
                        this.TariffsStates.push(tariffItem);
                    }
                });
            } else {
                this.Message = this.Terms.NoAvailableServicesForThisCard;
            }
        } else {
            if (acceptableTariffs.ErrorType == ErrorTypes.MediaCardExpired)
                this.Message = this.Terms.CardExpired;
            else if (acceptableTariffs.ErrorType == ErrorTypes.MediaCardInProcess ||
                acceptableTariffs.ErrorType == ErrorTypes.MediaCardIsLocked)
                this.Message = this.Terms.CardInProcess;
            else if (acceptableTariffs.ErrorType == ErrorTypes.MediaCardNotFound)
                this.Message = this.Terms.CardNotFound;
            else
                this.Message = acceptableTariffs.ErrorMessage !== undefined ? acceptableTariffs.ErrorMessage : this.Terms.InternalError;
        };
        this.CardLoading = false;
    };

    DateSet(date: string): void {
        if (date == null && date == '') return;
        if (this.Date == date)
            return;

        this.Date = date;
        this._metricsService.RegistrateEvent(MetricsEvents.RewriteChangeDate, date, new EcomTariffsHelper(this.TariffsStates));
    };

    ItemLoadingChange(id: string, value: boolean) {
        let element = this.TariffsStates.find(x => x.Id == id);
        if (element != undefined) element.ItemLoading = value;
    };
};

export class WpTariffsRewriteDictionary extends BaseUILocalizationDictionary {
    CardRegisteredOn: string = 'Карта оформлена на';
    NoAvailableServicesForThisCard: string = "Нет доступных услуг для данной карты";
    UnacceptableCharacters: string = 'Номер карты должен содержать A-F и цифры';
    GenericCheck: string = 'Проверить';
    LabelCardNo: string = 'Номер карты';
    InstructionEnterCardNumber: string = 'Для пополнения услуг, введите номер карты:';
    InstructionCharacters: string = 'Номер карты представляет из себя 16 букв-цифр и находится на самой карте.';
    CardNotFound: string = 'Карта не найдена';
    CardExpired: string = 'Период действия карты истек';
    CardInProcess: string = 'Данная карты уже учавствует в другом заказе';
    CardIsAlredyInCart: string = 'За один раз можно дозаписать только одну услугу на одну карту';
    CardMinimum8: string = "Минимальная длина номера карты - 8 символов"
};